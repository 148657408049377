<template>
  <div>
    <h3 v-if="event.id">Event bearbeiten</h3>
    <h3 v-else>Event anlegen</h3>
    <h4>{{ contentObject.title }}</h4>

    <form v-on:submit.prevent>
      <div class="form-group">
        <label for="action">Aktion</label>
        <select class="form-control" id="action" v-model="event.action" required  :disabled="event.id > 0">
          <option v-for="o in $store.state.config.event_actions" :value="o.value">{{ o.label }}</option>
        </select>
      </div>
      <div v-show="event.action === 'status_set'" class="form-group">
        <label for="status">Status</label>
        <select class="form-control" id="status" v-model="event.args.status">
          <option v-for="o in $store.state.config.simple_status_options" :value="o.value">{{ o.label }}</option>
        </select>
      </div>
      <div v-show="event.action.startsWith('tag_')" class="form-group">
        <label for="tag">Tag</label>
          <span v-if="event.args.tag">
            {{ event.args.tag_display }}
            <button type="button" class="btn btn-default btn-xs" v-on:click="resetTag" :disabled="locked">x</button>
          </span>
          <span v-else>
            <oe1-autocomplete :datasource="'/api/autocomplete/tag?q='" v-on:selected.once="selectEventTag"></oe1-autocomplete>
          </span>
      </div>
      <div v-show="event.action === 'url_set'" class="form-group">
        <label for="url_set">URL</label>
         <input v-model="event.args.url" type="text" class="form-control" id="url_set">
      </div>
      <div v-show="event.action === 'ugc'" class="form-group">
        <label for="url_set">UGC-Aktion</label>
        <select class="form-control" id="action" v-model="event.args.action">
          <option v-for="o in $store.state.config.ugc_schedule_actions" :value="o.value">{{ o.label }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="Datetime">Datum & Uhrzeit</label>
        <div>
          <flat-pickr :config="$store.state.config.datetime_options" v-model="event.schedule.time" />
        </div>
      </div>

      <oe1-save-close :hide_save="event.id" :content_id="event.id" :submit_method="submit_event"></oe1-save-close>
    </form>
  </div>
</template>

<script>

export default {
  data: function () { return {
    contentObject: {
      title: '',
      id: '',
      class: '',
    },
    event: {
      action: '',
      schedule: {time:''},
      args: {
        tag:'',
        tag_display:'',
      },
    },
    };
  },
  computed: {
    locked: function(){ return this.$store.getters.locked },
    content_object_url: function() {
      var vm = this;
      if (vm.content_class) {
        return vm.content_class +'/' + vm.content_id;
      }
      else if (vm.contentObject.class) {
        return vm.contentObject.class +'/' + vm.contentObject.id;
      }
      else {
        return '';
      }
    }
  },
  created: function () {
    var vm = this;
    if (vm.$route.params.event_id) {
      vm.axios.get('/api/schedule/' + vm.$route.params.event_id)
        .then(function (response) {
          var d = response.data;
          vm.$root.reactiveCopy(d,vm,['event']);
          vm.contentObject = d.content_object;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    }
    else {
      vm.axios.get('/api/'+ vm.content_class +'/' + vm.content_id)
        .then(function (response) {
          vm.contentObject.title = response.data.title;
        })
        .catch(function (error) {
          vm.$log(error);
        });
      }
  },
  props: ['content_class','content_id'],
  methods: {
    'submit_event': function () {
      var vm = this;

      if (vm.event.action && vm.event.schedule.time) {
        var saveUrl = vm.event.id
                      ? '/api/schedule/' + vm.event.id
                      : '/api/schedule/link/' + vm.content_class + '/' + vm.content_id;
        var promise = vm.axios.post(saveUrl, vm.event);
        promise
          .then(function (response) {
            var d = response.data;
            vm.$root.reactiveCopy(d,vm,['event']);
            vm.contentObject = d.content_object;
            vm.$log('Event wurde gespeichert');
          })
          .catch(function (error) {
            vm.$log(error);
          });
        return promise;
      }
    },
    selectEventTag: function (hit) {
      var vm = this;
      vm.event.args.tag = hit.id;
      vm.event.args.tag_display = hit.value;
      return;
    },
    resetTag: function() {
      var vm = this;
      vm.event.args.tag = '';
      vm.event.args.tag_display = '';
    },
    setScheduleTime: function (e) {
      this.event.schedule.time = e.format('Y-MM-DD HH:mm');
    },
  }
};
</script>
