<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.username }}</td>
          <td>{{ row.item.firstname }} {{ row.item.lastname }}</td>
          <td>{{ row.item.is_active ? 'Ja':'Nein' }}</td>
          <td>
            <button type="button" class="pull-right btn btn-default btn-xs" v-on:click="editItem(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
          </td>
        </template>
      </oe1-table>
    </div>
    <template slot="actions">
      <router-link to="/user/create" class="btn btn-info">Benutzer anlegen</router-link>
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>
  
    <div slot="filter">
      <oe1-filter-element label="Benutzername" param="username">
        <input type="text" v-model="params.username" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="E-Mail" param="email">
        <input type="text" v-model="params.email" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Name" param="name">
        <input type="text" v-model="params.name" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Rolle" param="role">
        <select type="text" v-model="params.role" v-on:change="updateParams">
          <option value="all"></option>
          <option v-for="o in availableRoles" :value="o.role">{{ o.role }}</option>
        </select>
      </oe1-filter-element>

      <oe1-filter-element label="Aktiv" param="is_active">
        <select type="text" v-model="params.is_active" v-on:change="updateParams">
          <option v-for="o in isActiveOptions" :value="o.value">{{ o.label }}</option>
        </select>
      </oe1-filter-element>


    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'user',
      columns: [
        { label: "Benutzername" },
        { label: "Name", width:"40%" },
        { label: "Aktiv", width:"10%" },
        { label: "", width: "5%" }
      ],
      availableRoles: [],
      isActiveOptions: [
        { label: "Egal", value: 'egal'},
        { label: "Ja", value: 'ja'},
        { label: "Nein", value: 'nein'}
      ]
    };
  },
  components: {},
  methods: {},
  created: function() {
    var vm = this;
    vm.axios.get('/api/user/available_roles')
      .then(function (response) {
          vm.availableRoles = response.data;
      })
      .catch(function (error) {
          vm.$log(error);
      });
  }
};
</script>

