<template>
  <div>
    <h3>UGC Einreichung verwalten</h3>

    <form v-on:submit.prevent>
      <div class="form-group">
        <b>Info</b><br>
        UGC: <router-link :to="{ path: '/ugc/' + ugcsubmission.ugc_id }">{{ ugcsubmission.ugc_id }}: {{ ugcsubmission.ugc_title }}</router-link><br>
        Typ: {{ ugcsubmission.type }}<br>
        Angelegt: {{ ugcsubmission.created }}<br>
        Terms akzeptiert: <oe1-nice-bool :bool=ugcsubmission.accepted_terms></oe1-nice-bool><br>
        Consent: <oe1-nice-bool :bool=ugcsubmission.accepted_terms></oe1-nice-bool><br>
        Email-Optin: <oe1-nice-bool :bool=ugcsubmission.email_verified></oe1-nice-bool> <span v-if="ugcsubmission.email_verified">{{ ugcsubmission.email_verified_at }}</span><br>
        Eingereicht: <oe1-nice-bool :bool=ugcsubmission.is_submitted></oe1-nice-bool><br>
        UUID: {{ ugcsubmission.uuid  }}<br>
        Link für Einreichende/n: <span v-if="!ugcsubmission.email_verified">Achtung: Öffnen dieses Links bestätigt das Email-Optin!</span><br>
        http://oe1.orf.at/ugcsubmission/review/{{ ugcsubmission.uuid }}/{{ ugcsubmission.secret }}
        <br><b><a :href="ugcsubmission.editlink" target="_new">Einreichung bearbeiten</a></b><br/>
      </div>
      <div class="form-group">
        <b>Kontakt</b><br>
        {{ ugcsubmission.firstname }} {{ ugcsubmission.lastname }}<br>
        {{ ugcsubmission.email }}<br>
        {{ ugcsubmission.street }} {{ ugcsubmission.streetnumber }}<br>
        {{ ugcsubmission.zip }} {{ ugcsubmission.city }}<br>
      </div>
      <div v-if="ugcsubmission.account" class="form-group">
        <b>Account</b><br>
        {{ ugcsubmission.account }}
      </div>
      <div class="form-group">
        <b>Display-Name</b><br>
        {{ ugcsubmission.display_name }}
      </div>
      <div class="form-group">
        <b>Titel</b><br>
        {{ ugcsubmission.title }}
      </div>
      <div class="form-group">
        <b>Kategorie</b><br>
        {{ ugcsubmission.category }}
        <span v-if="ugcsubmission.secondary_category">, {{ ugcsubmission.secondary_category }}</span>
      </div>
      <div  class="form-group">
        <b>Teaser</b><br>
        {{ ugcsubmission.data.teaser }}
      </div>

      <div class="form-group">
        <b>Webseite</b><br/>
        {{ ugcsubmission.data.website }}
        <br/><br>
        <b>Social Media Seite</b><br/>
        {{ ugcsubmission.data.social_media }}
      </div>
      <div v-if="ugcsubmission.type === 'audio'" class="form-group">
        <b>Audio</b><br>
        {{ ugcsubmission.data.audio_url }}
      </div>
      <div v-else-if="ugcsubmission.type === 'text'" class="form-group">
        <b>Text</b><br>
        {{ ugcsubmission.data.text }}
      </div>
      <div v-else-if="ugcsubmission.type === 'image'" class="form-group">
        <b>Bild</b><br>
        <img :src="ugcsubmission.data.image_url" width="500"/><br>
        <b>ALT:</b> {{ ugcsubmission.data.image_alt }}<br/>
        <b>Copyright:</b> {{ ugcsubmission.data.image_copyright }}<br/>
      </div>
      <div v-else-if="ugcsubmission.type === 'video'" class="form-group">
        <b>Video</b><br>
        <iframe width="582" height="315" :src="ugcsubmission.data.video_url" frameborder="0" allowfullscreen></iframe>
        <br/>
        <b>Provider </b> {{ ugcsubmission.data.video_provider }}<br/>
        <b>Video-ID </b> {{ ugcsubmission.data.video_id }}<br/>
      </div>

      <div v-if="!ugcsubmission.is_submitted" class="form-group">
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="ugcsubmission.submitted_override" />einreichen anstelle von User
          </label>
        </div>
      </div>

      <div class="form-group">
        <label for="status">Status</label>
        <select class="form-control" id="status" v-model="ugcsubmission.status" required>
          <option v-for="o in $store.state.config.simple_status_options" :value="o.value">{{ o.label }}</option>
        </select>
      </div>

      <div v-if="ugcsubmission.ugc_with_geocode" class="form-group">
        <b>Geo-Daten</b><br>
        <div v-if="ugcsubmission.geo_zip">
          {{ ugcsubmission.geo_zip }} {{ ugcsubmission.geo_city }}, {{ ugcsubmission.geo_state }} {{ ugcsubmission.geo_country }}<br/>
          Koordinaten: {{ ugcsubmission.geo_latitude }} / {{ ugcsubmission.geo_longitude }}
        </div>
        <div v-else>
           Keine Geo-Daten vorhanden!
        </div>
        <div><a :href="ugcsubmission.geolink" target="_new">Geolocation anpassen</a></div>
      </div>

      <div class="form-group">
        <label for="content">Raw JSON - handle with care!</label>
        <textarea v-model="ugcsubmission.args" rows="10" class="form-control" id="teaser"></textarea>
      </div>

      <div>
        <h4>Audio-Paragraph</h4>
        <div class="form-group">
          <label>Überschrift</label>
          <input v-model="ugcsubmission.audio.title" type="text" class="form-control" id="inputAudioTitle" placeholder="Überschrift"/>
        </div>
        <div class="form-group">
          <label>Text</label>
          <textarea v-model="ugcsubmission.audio.text" class="form-control" id="inputAudioText"></textarea>
        </div>
        <div class="form-group">
          <oe1-audiobrowser :audio_url="ugcsubmission.audio.audio_url" :audio_info="ugcsubmission.audio.audio_info" v-on:oe1-audio-selected="linkAudio"/>
        </div>
      </div>

      <oe1-save-close :content_id="ugcsubmission.id" :submit_method="submit_ugcsubmission"></oe1-save-close>
    </form>
  </div>
</template>

<script>
export default {
  props: [ 'ugcsubmission' ],
  methods: {
    'submit_ugcsubmission': function () {
      var vm = this;
      var saveUrl = '/api/ugcsubmission/' + vm.ugcsubmission.uuid;
      var promise = vm.axios.post(saveUrl, vm.ugcsubmission);
      promise
        .then(function (response) {
          vm.$log('UGC wurde gespeichert');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    linkAudio(audio) {
      this.ugcsubmission.audio.audio_url = audio.value;
      this.ugcsubmission.audio.audio_info = audio.label;
    }
  }
};
</script>
