<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.status }}</td>
          <td>{{ row.item.topic }}</td>
          <td>{{ row.item.time_to_send }}</td>
          <td>{{ row.item.title }}</td>
          <td>
            <button type="button" class="btn btn-default btn-xs" v-on:click="editPushNotification(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
          </td>
        </template>
      </oe1-table>
    </div>

    <template slot="actions">
      <router-link to="/push_notification/create" class="btn btn-info">Push Notification anlegen</router-link>
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>

    <div slot="filter">
      <oe1-filter-content-object
        :show-status=false
        :show-title=true
        :show-page-id=false
        :show-created-at=false
        :show-tag=false
        :params="params"
        v-on:oe1-update-params="updateParams"
        :status-options="statusOptions"
      ></oe1-filter-content-object>

    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'push_notification',
      params: {
        title: '',
        status: '',
      },
      columns: [
        { label: "Status",},
        { label: "Topic",},
        { label: "Datum",},
        { label: "Titel",},
        { label: "", width: "5%" }
      ],
    };
  },
  methods: {
    editPushNotification: function(item) {
      var vm = this;
      vm.$router.push('/push_notification/' + item.id);
    }
  }
};
</script>
