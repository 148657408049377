<template>
  <div>
    <div class="row">
      <div class="col-md-6" role="main">
        <h3>Personendaten</h3>
        <table class="table table-striped table-list">
          <tbody>
            <tr><td>UserID:</td><td>{{ account.userid }}</td></tr>
            <tr><td>Name:</td><td>{{ account.anrede }} {{  account.title }} {{ account.vorname }} {{ account.nachname }}</td></tr>
            <tr><td>Email</td><td>{{ account.email }}</td></tr>
            <tr><td>Ort</td><td>{{ account.plz }} {{ account.ort }}</td></tr>
            <tr><td>Kundennummer</td><td>{{ account.kundennummer }}</td></tr>
            <tr><td>Kundengruppe</td><td>{{ account.kundengruppe }}</td></tr>
            <tr><td>berechtigt</td><td>{{ account.may_download }}</td></tr>
            <tr><td>Downloads übrig</td><td>{{ account.downloads }}</td></tr>
            <tr><td>Berechtigung endet am</td><td>{{ account.valid_until }}</td></tr>

          </tbody>
        </table>
      </div>
      <div class="col-md-6" role="main">
        <h3>Auf- / Abbuchen</h3>
        <p>Downloads: {{account.downloads }}</p>
        <form v-on:submit.prevent>
          <div class="form-group">
            <label for="num">Anzahl</label>
            <select name="num" v-model="bookval">
              <option>0</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
              <option>20</option>
              <option>50</option>
              <option>100</option>
              <option>340</option>
              <option>360</option>
              <option>1000</option>
            </select>
          </div>
          <button class="btn btn-default" v-on:click.prevent="bookDownload('inc')">Anzahl erhöhen</button>
          <button class="btn btn-default" v-on:click.prevent="bookDownload('dec')">Anzahl reduzieren</button>
        </form>
        <div style="padding-top:10px">
          <a :href="account.login_as_link" type="button" class="btn btn-default" target="_new" ><span class="glyphicon glyphicon-new-window" aria-hidden="true"> Als {{ account.userid }} einloggen</span></a>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-md-6" role="main">
        <h3>Downloads</h3>
        <table class="table table-striped table-list">
          <tbody>
            <tr v-for="dl in account.download_hist">
              <td>
                {{ dl.broadcast_title }} {{ dl.broadcast_airdate }} - {{ dl.created }} - dl_left: {{ dl.dl_left }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-6" role="main">
        <h3>Bestellungen</h3>
        <table class="table table-striped table-list">
        <tbody>
          <tr v-for="order in account.orders">
            <td><ul>
              <li>Bestellnummer: {{ order.sap_auftragsnummer}}</li>
              <li>Bestelldatum: {{ order.created }}</li>
              <li>Enddatum: {{ order.enddatum }}</li>
              <li>Materialwert: {{ order.materialwert }}</li>
              <li>Status: {{ order.status }}</li>
              <li>Downloads vor Aufbuchung: {{ order.downloads_vor }}</li>
              <li>Downloads nach Aufbuchung: {{ order.downloads_nach }}</li>
            </ul></td>
          </tr>
        </tbody></table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data: function () { return {
    account: {
    },
    bookval: 0,
    };
  },
  computed: {
    account_id: function() { return parseInt(this.$route.params.account_id) }
  },
  watch: {
    'account_id': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  methods: {
    fetchData: function () {
      var vm = this;
      if (vm.account_id) {
        vm.axios.get('/api/downloadaccount/' + vm.account_id)
          .then(function (response) {
            vm.account = response.data;
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
    },
    bookDownload: function(bookingAction) {
      var vm = this;
      if (vm.bookval === 0) {
        return;
      }

      vm.axios.post('/api/downloadbooking/' + vm.account_id, { action: bookingAction, val: vm.bookval } )
        .then(function (response) {
          vm.$log(vm.bookval + " Downloads wurden gebucht");
          vm.bookval=0;
          vm.account.downloads = response.data.downloads;
        })
        .catch(function (error) {
          vm.bookval=0;
          vm.$log(error);
        });
    },
  }
};
</script>

