module.exports = [
    { value: 'list',    label: 'Liste' },
    { value: 'homepage',   label: 'Homepage' },
    { value: 'broadcastlist',   label: 'Sendungen' },
    { value: 'taglist',   label: 'Schwerpunkt' },
    { value: 'azlist',   label: 'A-Z Liste' },
    { value: 'clubpartner',   label: 'Club Partner' },
    { value: 'slimlist', label: 'Schmale Liste' },
    { value: 'ugcsubmissions', label: 'UGC' },
];
