<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.id }}</td>
          <td>{{ $root.getStatusLabel(row.item.status) }}</td>
          <td>{{ row.item.title }}</td>
          <td>{{ row.item.target }}</td>
          <td>
            <button type="button" class="btn btn-default btn-xs" v-on:click="editLink(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
            <oe1-image-modal :image_id="row.item.image"></oe1-image-modal>
          </td>
        </template>
      </oe1-table>
    </div>

    <template slot="actions">
      <router-link to="/link/create" class="btn btn-info">Link anlegen</router-link>
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>

    <div slot="filter">
      <oe1-filter-content-object
        :show-created-at=false
        :params="params"
        v-on:oe1-update-params="updateParams"
        :status-options="statusOptions"
      ></oe1-filter-content-object>

      <b>Verweist auf:</b><br>
      <oe1-filter-element label="ID" param="content_id">
        <input type="text" v-model="params.content_id" v-on:change="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="target" param="target">
        <input type="text" v-model="params.target" v-on:change="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Objekt" param="content_class">
        <select class="form-control" v-model="params.content_class" v-on:change="updateParams">
          <option></option>
          <option v-for="o in $store.state.config.content_classes" :value="o.value">{{o.label}}</option>
        </select>
      </oe1-filter-element>

    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'link',
      params: {
        content_class: '',
        content_id: '',
        target:'',
      },
      columns: [
        { label: "ID",},
        { label: "Status",},
        { label: "Titel",},
        { label: "Verweist auf",},
        { label: "", width: "5%" }
      ],
    };
  },
  calculated: {
    filterContentClasses: function() {
      var vm = this;
      return vm.$store.state.config.content_classes;
    }
  },
  methods: {
    editLink: function(item) {
      var vm = this;
      vm.$router.push('/link/' + item.id);
    },
  }
};
</script>
