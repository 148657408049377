<template>
  <div>
    <div class="col-md-9" role="main">
      <ul class="nav nav-tabs" role="tablist">
        <li :class="{active: tab === 'bcdetails'}">
          <router-link :to="{path:'/broadcast/'+bc.id}">Broadcast</router-link>
        </li>
        <li v-if="bc.id" :class="{active: tab === 'listimage'}">
          <router-link :to="{path:'/broadcast/'+bc.id+'/listimage'}">Listenbild</router-link>
        </li>
        <li v-if="bc.id" :class="{active: tab === 'more'}">
          <router-link :to="{path:'/broadcast/'+bc.id+'/more'}">Tags, Events, AutorInnen</router-link>
        </li>
      </ul>
      <component :is="currentView" :bc="bc" :content_class="content_class" :content_id="bc_id"></component>
    </div>
    <div class="col-md-3 sidebar" role="complementary">
      <oe1-previewlink :link="bc.previewlink"></oe1-previewlink>
      <oe1-memo :content_class="content_class" :content_id="bc_id"></oe1-memo>
      <oe1-changelog :content_class="content_class" :content_id="bc_id"></oe1-changelog>
    </div>
  </div>
</template>

<script>

export default {
  data: function () { return {
      content_class: 'broadcast',
      bc: {previewlink:''},
    };
  },
  computed: {
    tab: function() { return this.$route.params.tab || 'bcdetails' },
    currentView: function() { return 'oe1-'+this.tab },
    bc_id: function() { return parseInt(this.$route.params.bc_id) }
  },
  watch: {
    'bc_id': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  components: {
    'oe1-bcdetails': require('./BroadcastDetails.vue').default,
    'oe1-more': {
        props: ['bc', 'content_class', 'content_id'],
        template: '<div>'
                + '  <oe1-tags :content_id="content_id" :content_class="content_class"></oe1-tags>'
                + '  <oe1-relatedcontent :content_class="content_class" :content_id="content_id"></oe1-relatedcontent>'
                + '  <oe1-events :content_id="content_id" :content_class="content_class"></oe1-events>'
                + '  <oe1-authors :content_class="content_class" :content_id="content_id"></oe1-authors>'
                + '</div>',
    }
  },
  methods: {
    fetchData: function () {
      var vm = this;
      vm.axios.get('/api/broadcast/' + vm.bc_id)
        .then(function (response) {
          vm.bc = response.data;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
  }
};
</script>
