<template>
 <div>
    <div class="col-md-9" role="main">
      <ul class="nav nav-tabs" role="tablist">
        <li :class="{active: tab === 'snippetdetails'}">
          <router-link :to="{path:'/snippet/'+snippet_id}">Schipsel</router-link>
        </li>
        <li v-if="snippet_id" :class="{active: tab === 'simple'}">
          <router-link :to="{path:'/snippet/'+snippet_id+'/simple'}">Einfacher Editor</router-link>
        </li>
      </ul>
      <form v-on:submit.prevent>
        <div class="form-group">
          <label for="inputInternalName">Interner Name</label>
          <input type="text" class="form-control" id="inputInternalName" v-model="inputInternalName" disabled>
        </div>
        <template v-if="tab == 'snippetdetails'">
          <div class="form-group">
            <label for="inputContent">Inhalt</label>
            <textarea v-model="inputContent" type="text" class="form-control" id="inputContent" rows="15" required></textarea>
          </div>
        </template>
        <template v-if="tab == 'simple'">
          <div v-for="(row, index) in inputContentData">
            <h4>{{ index+1 }}. Eintrag</h4>
            <div class="form-group" v-for="(value,key) in row">
              <label for="inputContent">{{ key }}</label>
              <template v-if="typeof(value) === 'object'" class="form-control">{{ value }}</template>
              <input v-else type="text" :value="value" @change="setValue(index,key,$event)" class="form-control">
            </div>
          </div>
        </template>
        <div class="form-group">
          <label for="inputDescription">Beschreibung</label>
          <textarea v-model="inputDescription" type="text" class="form-control" id="inputDescription" required></textarea>
        </div>
        <oe1-save-close :content_id="snippet_id" :submit_method="submit_snippet"></oe1-save-close>
      </form>
    </div>
  </div>
</template>


<script>

export default {
  data: function () {
    return {
      inputInternalName: '',
      inputContent: '',
      inputDescription: '',
      inputContentData: undefined
    };
  },
  computed: {
    tab: function() { return this.$route.params.tab || 'snippetdetails' },
    snippet_id: function() { return parseInt(this.$route.params.snippet_id) }
  },
  watch: {
    'snippet_id': 'fetchData'
  },
  created: function () {
    this.fetchData();
  },
  methods: {
    setValue: function(index,key,$event) {
      if (this.inputContentData === undefined) {
        return;
      }
      this.inputContentData[index][key] = $event.target.value;
      this.inputContent = JSON.stringify(this.inputContentData, null, 4);
    },
    fetchData: function () {
      var vm = this;
      vm.axios.get('/api/snippet/' + this.snippet_id)
        .then(function (response) {
          vm.inputContent      = response.data.content;
          vm.inputDescription  = response.data.description;
          vm.inputInternalName = response.data.internal_name;
          vm.inputContentData  = JSON.parse(response.data.content);
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    'submit_snippet': function () {
      var vm = this;
      var data = {
          content: vm.inputContent,
          description: vm.inputDescription
      };
      var promise = vm.axios.post('/api/snippet/' + vm.snippet_id, data);
      promise
        .then(function (response) {
          vm.$log('Schnippsel "'+vm.snippet_id+'" wurde aktualisiert.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    }
  }
};
</script>
