<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td><img :src="imgSrc(row.item)"/></td>
          <td>#{{ row.item.id }}<br/>
          {{ row.item.filename}}<br/>
          ({{row.item.width}}/{{row.item.height}})
          </td>
          <td>{{ row.item.alt }}</td>
          <td>
            <button type="button" class="btn btn-default btn-xs" v-on:click="editImage(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
            <oe1-image-modal :image_id="row.item.image"></oe1-image-modal>
          </td>
        </template>
      </oe1-table>
    </div>

    <template slot="actions">
      <router-link to="/image/create" class="btn btn-info">Bild hochladen</router-link>
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>

    <div slot="filter">
      <oe1-filter-content-object
        :show-created-at=false
        :show-status=false
        :show-tag=false
        :show-title=false
        :params="params"
        v-on:oe1-update-params="updateParams"
        :status-options="statusOptions"
      ></oe1-filter-content-object>

      <oe1-filter-element label="ALT-Text" param="alt">
        <input type="text" v-model="params.alt" v-on:change="updateParams"/>
      </oe1-filter-element>

      <oe1-filter-element label="Copyright" param="alt">
        <input type="text" v-model="params.copyright" v-on:change="updateParams"/>
      </oe1-filter-element>

      <oe1-filter-element label="Text" param="alt">
        <input type="text" v-model="params.text" v-on:change="updateParams"/>
      </oe1-filter-element>

      <oe1-filter-element label="Schlagwort" param="alt">
        <input type="text" v-model="params.keywords" v-on:change="updateParams"/>
      </oe1-filter-element>

    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'image',
      params: {
        alt: '',
        copyright: '',
        text: '',
      },
      columns: [
        { label: "Bild",},
        { label: "ID",},
        { label: "ALT-Text",},
        { label: "", width: "5%" }
      ],
    };
  },
  methods: {
    editImage: function(item) {
      var vm = this;
      vm.$router.push('/image/' + item.id);
    },
    imgSrc(img) {
      return '/i/list/' + img.sha1.substr(0,2) + '/' + img.sha1.substr(2,2) + '/' + img.sha1 + '.' + img.extension;
    }
  }
};
</script>
