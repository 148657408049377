<template>
  <div>
    <h3 v-if="link.id">Link bearbeiten</h3>
    <h3 v-else>Link anlegen</h3>

    <form v-on:submit.prevent>
      <div class="form-group">
        <label for="title">Titel</label>
        <input v-model="link.title" type="text" class="form-control" id="title"  autofocus>
      </div>
      <div class="form-group">
        <label for="status">Status</label>
        <select class="form-control" id="status" v-model="link.status" required>
          <option v-for="o in $store.state.config.simple_status_options" :value="o.value">{{ o.label }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="subtitle">Subtitel</label>
        <input v-model="link.subtitle" type="text" class="form-control" id="subtitle" >
      </div>
      <div class="form-group">
        <label for="teaser">Teaser</label>
        <input v-model="link.teaser" type="text" class="form-control" id="teaser" >
      </div>
      <div v-if="link.has_target" class="form-group">
        <b>Ziel:</b><br/>
        <span v-if="link.content_object.id">
          <router-link :to="{ path: '/' + link.content_class + '/' + link.content_id }">{{ link.content_object.title }}</router-link> ({{ link.content_class }}) - {{ link.content_id }}
        </span>
        <span v-else>
          <a :href="link.target" target="_new">{{ link.target }}</a>
        </span>
        <oe1-confirm-modal :callback="removeTarget" body="Ziel zurücksetzen?"></oe1-confirm-modal>
      </div>
      <div v-else class="form-inline">
        <b>Ziel:</b><br/>
        <div class="form-group">
          <label for="target">Link</label>
          <input v-model="link.target" type="text" placeholder="http://..." class="form-control" id="target" >
        </div>
        - ODER -
        <div class="form-group">
          <label for="inputIdClass">Objekt</label>
          <select class="form-control" id="inputIdClass" v-model="link.content_class">
            <option v-for="o in $store.state.config.content_classes" :value="o.value">{{ o.label }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="inputIdId">id</label>
          <input type="number" class="form-control" v-model="link.content_id">
        </div>
      </div>

      <div class="form-group">
        <label for="inputSortDate">Sortier Datum</label>
        <div>
          <flat-pickr v-model="link.sort_date" :config="$store.state.config.datetime_options" />
        </div>
      </div>

      <oe1-save-close :content_id="link.id" :submit_method="submit_link"></oe1-save-close>
    </form>
  </div>
</template>

<script>

export default {
  data: function () { return {
  }},
  props: ['link'],
  methods: {
    'submit_link': function () {
      var vm = this;
      var saveUrl = vm.link.id
                    ? '/api/link' + '/' + vm.link.id
                    : '/api/link';
      var promise = vm.axios.post(saveUrl, vm.link);
      promise
        .then(function (response) {
          vm.$log('Link wurde gespeichert');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    deleteBuilder: function() {
      return this.removeTarget.bind(this);
    },
    removeTarget: function() {
      var vm = this;
      vm.link.has_target=0;
      vm.link.target=undefined;
      vm.link.content_class=undefined;
      vm.link.content_id=undefined;
      vm.link.content_object={};
    },
  }
};

</script>
