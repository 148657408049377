<template>
  <div>
    <h3 v-if="ugc.id">UGC bearbeiten</h3>
    <h3 v-else>UGC anlegen</h3>

    <form v-on:submit.prevent>
      <div class="form-group">
        <label for="title">Titel</label>
        <input v-model="ugc.title" type="text" class="form-control" id="title" required autofocus>
      </div>
      <div class="form-group">
        <label for="status">Status</label>
        <select class="form-control" id="status" v-model="ugc.status" required>
          <option v-for="o in $store.state.config.simple_status_options" :value="o.value">{{ o.label }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="teaser">Teaser</label>
        <textarea v-model="ugc.teaser" rows="3" class="form-control" id="teaser" placeholder="Teaser"></textarea>
      </div>

      <div style="padding-bottom:1em">
        <label>Kategorien</label>
        <table class="table table-striped table-list">
          <thead>
            <tr><th>Kategorie</th><th>intern</th><th></th></tr>
          </thead>
          <tbody>
            <tr v-for="(cat,i) in ugc.categories" :key="i">
              <td><input v-model="cat.label" type="text" class="form-control" required/></td>
              <td><input v-model="cat.value" type="text" class="form-control" required/></td>
              <td><button type="button" class="btn btn-default" v-on:click="removeCat(i)"><span class="glyphicon glyphicon-remove"></span></button></td>
            </tr>
            <tr v-if="showAddCat == true">
              <td><input type="text" v-model="newCatLabel" class="form-control" required/></td>
              <td><input type="text" v-model="newCatValue" class="form-control" required/></td>
              <td><button type="button" class="btn btn-default" v-on:click="addCat"><span class="glyphicon glyphicon-floppy-disk"></span></button></td>
            </tr>
            <tr v-if="showAddCat == false"><td colspan="3">
              <button type="button" class="btn btn-default" v-on:click="showCatForm()"><span class="glyphicon glyphicon-plus"> Kategorie hinzufügen</span></button>
              </td>
            </tr>
            </tbody>
          </table>
      </div>

      <div class="form-group">
        <label for="categories">URL der Collection</label>
        <input v-model="ugc.url" type="text" class="form-control" id="url" placeholder="/some-fancy-ugc-url"></textarea>
      </div>

      <div class="form-group">
        <b>Features</b>
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="ugc.has_text" /> Text
          </label>
          <label>
            <input type="checkbox" v-model="ugc.has_image" /> Bild
          </label>
          <label>
            <input type="checkbox" v-model="ugc.has_audio" /> Audio
          </label>
          <label>
            <input type="checkbox" v-model="ugc.has_video" /> Video
          </label>
          <label>
            <input type="checkbox" v-model="ugc.has_social_media" /> Social Media
          </label>
        </div>
      </div>

      <div class="form-group">
        <b>Upload</b>
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="ugc.submission_open" /> Upload möglich
          </label>
          <label>
            <input type="checkbox" v-model="ugc.show_submission" /> Uploads anzeigen
          </label>
        </div>
      </div>

      <div class="form-group">
        <b>Display-Name</b>
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="ugc.show_display_name" /> anzeigen
          </label>
        </div>
      </div>

      <div class="form-group">
        <b>Voting</b>
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="ugc.with_voting" /> Mit Voting
          </label>
          <label>
            <input type="checkbox" v-model="ugc.voting_open" /> Voting möglich
          </label>
          <label>
            <input type="checkbox" v-model="ugc.show_votes" /> Ergebnisse anzeigen
          </label>
        </div>
      </div>

      <div class="form-group">
        <b>Geo-Coding</b>
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="ugc.with_geocode" /> Mit Geocoding
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="ugc.show_map" /> Karte anzeigen
          </label>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="ugc.geo_only_at" /> Nur in Österreich geocoden
          </label>
        </div>
        <div>
          Geo-Filter:
          <select id="geofilter" v-model="ugc.geo_filter">
            <option v-for="o in geo_filter_opts" :value="o.value">{{ o.label }}</option>
          </select>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="ugc.with_en" /> Englische Version anbieten
          </label>
        </div>
      </div>

      <oe1-save-close :content_id="ugc.id" :submit_method="submit_ugc"></oe1-save-close>
    </form>
  </div>
</template>

<script>
export default {
  props: [ 'ugc' ],
  data: function () { return {
    foo: 1,
    showAddCat: false,
    newCatLabel: undefined,
    newCatValue: undefined,
    geo_filter_opts: [
      {
        value: "",
        label: "kein Filter"
      },
      {
        value: "at_bundesland",
        label: "Bundesland Österreich"
      },
      {
        value: "countries",
        label: "Staat (funktioniert nicht, wenn 'nur in Österreich geocodes' angekeuzt ist)"
      },
    ],
  }},
  methods: {
    'submit_ugc': function () {
      var vm = this;
      var saveUrl = vm.ugc.id
        ? '/api/ugc/' + vm.ugc.id
        : '/api/ugc';
      var promise = vm.axios.post(saveUrl, vm.ugc);
      promise
        .then(function (response) {
          vm.$log('UGC wurde gespeichert.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    showCatForm() {
      this.showAddCat = !this.showAddCat;
    },
    addCat(){
      this.ugc.categories.push({ label: this.newCatLabel, value: this.newCatValue });
      this.newCatLabel=undefined;
      this.newCatValue=undefined;
      this.showAddCat=false;
    },
    removeCat(i){
      this.$delete(this.ugc.categories, i);
    }
  }
};
</script>
