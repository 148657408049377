export default {
  props: {
    content_id: { required: true },
    content_class: {
        type: String,
        required: true,
        validator: function (val) {
            var valid_classes = [
              'article',
              'broadcast',
              'serial',
              'link',
              'collection',
              'widget',
              'paragraph',
              'selection',
              'usr',
              'image',
              'url',
              'author',
              'tag',
              'ugc',
              'ugc_submission',
              'push_notification'
            ];
            if (valid_classes.indexOf(val) === -1) {
                console.warn("content_class='"+val+"'");
                return false;
            }
            else {
                return true;
            }
        }
    },
  },
};
