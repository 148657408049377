<template>
  <div>
    <form v-on:submit.prevent>
      <h4 v-if="paragraph_id">{{ para.type }}</h4>
      <h4 v-else>Absatz anlegen</h4>

      <div class="form-group">
        <label for="inputStatus">Status</label>
        <select class="form-control" id="inputStatus" required v-model="para.status">
          <option v-for="o in $store.state.config.simple_status_options" :value="o.value">{{ o.label }}</option>
        </select>
      </div>

      <div v-if="!paragraph_id">
        <div class="form-group">
          <label for="inputType">Typ</label>
          <select class="form-control" id="inputType" required v-model="para.type" autofocus>
            <option v-for="o in $store.state.config.paragraph_options" :value="o.value">{{ o.label }}</option>
          </select>
        </div>
      </div>

      <div class="form-group" v-if="para.type != 'quotation' && para.type != 'internal_key_value' && para.type != 'stoerer'">
        <label for="inputTitle">Überschrift</label>
        <input type="text" id="inputTitle" class="form-control" v-model="para.args.title">
      </div>

      <div v-if="para.type != 'image' && para.type != 'poll' && para.type != 'social_media' && para.type != 'stoerer'">
        <div class="form-group">
          <label for="inputText">Text</label>
          <textarea class="form-control" rows="5" id="inputText" v-model="para.args.text"></textarea>
        </div>
      </div>

      <div v-if="para.type === 'text' || para.type === 'image' || para.type === 'infobox' || para.type === 'infobox_with_image'">
        <div class="form-group">
          <label for="inputText">Tracking-Pixel</label>
          <input type="text" id="inputTrackingPixel" class="form-control" v-model="para.args.tracking_pixel" />
        </div>
      </div>

      <div v-if="para.type === 'image' || para.type === 'infobox_with_image' || para.type === 'embed_external'">
        <b v-if="para.type === 'embed_external'">Preview-Bild:</b>
        <b v-else>Foto:</b>
        <div v-if="para.image && para.image.src">
          <div class="row">
            <div class="col-md-6">
              <img :src="para.image.src">
              <div>#{{para.image.id}} - {{para.image.filename}} ({{para.image.width}}/{{para.image.height}})</div>
              <div v-if="para.type === 'embed_external'">
                <button type="button" class="btn" v-on:click="removeEmbedExternalImage()">Bild löschen</button>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="liAlt">Alt</label>
                <input type="text" class="form-control" id="liAlt" v-model="para.image.alt">
              </div>
              <div class="form-group">
                <label for="liCopyright">Copyright</label>
                <input type="text" class="form-control" id="liCopyright" v-model="para.image.copyright">
              </div>
              <div class="form-group">
                <label for="liKeywords">Schlagwörter</label>
                <textarea class="form-control" rows="2" id="liKeywords" v-model="para.image.keywords"></textarea>
              </div>
              <div class="form-group">
                <label for="inputText">Bild-Text</label>
                <textarea class="form-control" rows="5" id="inputText" v-model="para.image.text"></textarea>
              </div>
              <div class="form-group" v-if="para.type === 'image'">
              <div class="form-group">
                <label for="inputImageSize">Darstellungsgröße</label>
                  <select class="form-control" id="inputImageSize" required v-model="para.args.display_size" autofocus>
                    <option v-for="o in image_display_size" :value="o.value">{{ o.label }}</option>
                  </select>
                </div>

                <div class="checkbox">
                  <label>
                    <input type="checkbox" id="inputIsGallery" v-model="para.args.is_gallery"> Gallery
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="form-group">
          <oe1-addimage v-on:oe1-link-image="linkImage"/>
        </div>
      </div>

      <div v-else-if="para.type === 'pdf'">
        <div v-if="para.args.pdf">
          <b>PDF</b><br>
          <a :href="para.pdf_link" target="_new">{{ para.args.pdf }}</a>
        </div>
        <div v-else>
          <div class="form-group">
            <label for="inputFile">PDF</label>
            <input type="file" id="inputFile" v-on:change="onFileChange">
          </div>
        </div>
        <div class="form-group">
          <label for="inputDisplayFilename">Anzeige Dateiname</label>
          <input type="text" class="form-control" id="inputDisplayFilename" v-model="para.args.display_filename">
        </div>
      </div>

      <div v-else-if="para.type === 'audio'">
        <oe1-audiobrowser :audio_url="para.args.audio_url" :audio_info="para.args.audio_info" v-on:oe1-audio-selected="linkAudio"/>

        <div class="checkbox">
          <label>
            <input type="checkbox" id="inputAudioIsStream" v-model="para.args.audio_is_livestream"> Livestream
          </label>
        </div>

        <div>
          <b>Gestalter:</b>
          <ul>
            <li v-for="a in para.args.authors">
              {{ a.name }} <a v-bind:href="a.link">{{a.link}}</a> <button type="button" class="btn btn-xs" v-on:click="removeGestalter(a)">x</button>
            </li>
          </ul>

          <div class="form-group" v-if="show_gestalter_form === true" >
            <oe1-autocomplete :datasource="'/api/autocomplete/author?q='" v-on:selected="addGestalter" ></oe1-autocomplete>
            <button type="button" class="btn btn-default" v-on:click="show_gestalter_form=false">Abbrechen</button>
          </div>
          <button type="button" class="btn btn-default" v-on:click="show_gestalter_form=true">Gestalter hinzufügen</button>
        </div>
      </div>

      <div v-else-if="para.type === 'video_external'">
        <div class="form-group">
          <label for="inputVideoProvider">Quelle</label>
          <select type="text" class="form-control" id="inputVideoProvider" v-model="para.args.video_provider">
            <option value="youtube">YouTube</option>
            <option value="vimeo">Vimeo</option>
          </select>
        </div>
        <div class="form-group">
          <label for="inputVideoId">ID</label>
          <input type="text" class="form-control" id="inputVideoId" v-model="para.args.video_id">
        </div>
      </div>

      <div v-else-if="para.type === 'video_oon'">
        <div class="form-group">
          <label for="inputVideoPath">Path</label>
          <input type="text" class="form-control" id="inputVideoPath" v-model="para.args.path">
        </div>
        <div class="form-group">
          <label for="inputVideoName">Name</label>
          <input type="text" class="form-control" id="inputVideoName" v-model="para.args.name">
        </div>

        <div class="checkbox">
          <label>
            <input type="checkbox" id="inputVideoAutoplay" v-model="para.args.autoplay"> Autoplay
          </label>
        </div>

        <div class="checkbox">
          <label>
            <input type="checkbox" id="inputIsLivestream" v-model="para.args.video_is_livestream"> Livestream
          </label>
        </div>

        <div class="form-group">
          <label for="inputVideoPreview">Preview Image URL</label>
          <input type="text" class="form-control" id="inputVideoPreview" v-model="para.args.preview">
        </div>
        <div class="form-group">
          <label for="inputVideoLoadingMessage">Loading-Message</label>
          <input type="text" class="form-control" id="inputVideoLoadingMessage" v-model="para.args.loadingmessage">
        </div>
        <div class="form-group">
          <label for="inputVideoErrorMessage">Error-Message</label>
          <input type="text" class="form-control" id="inputVideoErrorMessage" v-model="para.args.errormessage">
        </div>


      </div>

      <div v-else-if="para.type === 'download'">
        <div class="form-group">
          <label for="inputUrl">URL</label>
          <input type="text" class="form-control" id="inputUrl" v-model="para.args.download_url">
        </div>
      </div>
      <div v-else-if="para.type === 'internal_form'">
        <div class="form-group">
          <label for="inputInternalForm">Formular</label>
          <select class="form-control" id="inputInternalForm" required v-model="para.args.form_name">
            <option v-for="o in internal_forms" :value="o.value">{{ o.label }}</option>
          </select>
          <div class="form-group">
            <label for="inputInternalFormThanksTitle">Danke: Titel</label>
            <input type="text" class="form-control" id="inputInternalFormThanksTitle" v-model="para.args.thanks_title">
          </div>
          <div class="form-group">
            <label for="inputInternalFormThanksText">Danke: Text</label>
            <textarea class="form-control" rows="5" id="inputInternalFormThanksText" v-model="para.args.thanks_text"></textarea>
          </div>
        </div>
      </div>

      <div v-else-if="para.type === 'radiothek'">
        <div class="form-group">
          <label for="inputRadiothekIdent">Radiothek Ident (z.B.: <code>"20200903/611304/1599130407000"</code>, <code>"20200903/611304"</code>, <code>"im-oe1-journal-zu-gast/im-oe1-journal-zu-gast-29082020"</code>)</label>
          <input type="text" class="form-control" id="inputRadiothekIdent" v-model="para.args.radiothek_ident" required>
        </div>

        <div class="form-group">
          <label for="inputDisplayType">Typ</label>

          <select class="form-control" id="inputDisplayType" required v-model="para.args.radiothek_type">
            <option v-for="o in radiothek_type" :value="o.value">{{ o.label }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="inputRadiothekTitelOverride">Titel Override</label>
          <input type="text" class="form-control" id="inputRadiothekTitelOverride" v-model="para.args.title_override">
        </div>
        <div class="form-group">
          <label for="inputRadiothekChannel">Kanal</label>
          <input type="text" class="form-control" id="inputRadiothekChannel" v-model="para.args.channel" required>
        </div>

      </div>

      <div v-else-if="para.type === 'internal_key_value'">
        <div class="form-group">
          <label for="inputInternalKey">Key</label>
          <input type="text" class="form-control" id="inputInternalKey" v-model="para.args.key">
        </div>
        <div class="form-group">
          <label for="inputInternalValue">Value</label>
          <input type="text" class="form-control" id="inputInternalValue" v-model="para.args.value">
        </div>
      </div>

      <div v-if="para.type === 'infobox' || para.type === 'infobox_with_image'">
        <label>
          <input type="checkbox" id="inputIsSidebar" v-model="para.args.is_sidebar"> Sidebar
        </label>
      </div>

      <div v-else-if="para.type === 'social_media'">
        <div class="form-group">
          <label for="inputDisplayType">Social Media</label>
          <select class="form-control" id="inputDisplayType" required v-model="para.args.social_media">
            <option v-for="o in social_media" :value="o.value">{{ o.label }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="inputSocialMediaPermalink">Permalink</label>
          <input type="text" class="form-control" id="inputSocialMediaPermalink" v-model="para.args.permalink" required>
        </div>
        <div v-if="para.args.embed">
          <div><b>Preview</b></div>
          <div v-html="para.args.embed"></div>
        </div>
      </div>

      <div v-else-if="para.type === 'embed_external'">
        <div class="form-group">
          <label for="inputDisplayType">Provider</label>
          <input type="text" class="form-control" id="inputEmbedExternalProvider" v-model="para.args.provider" required>
        </div>

        <div class="form-group">
          <label for="inputDisplayType">URL</label>
          <input type="text" class="form-control" id="inputEmbedExternalUrl" v-model="para.args.url" required>
        </div>

        <div class="form-group">
          <div class="form-group">
            <label for="inputText">Embed Code</label>
            <textarea class="form-control" rows="5" id="inputEmbedExternalEmbedCode" v-model="para.args.embed"></textarea>
          </div>
        </div>
      </div>

      <div v-else-if="para.type === 'ugc_upload'">
        <div class="form-group">
          <label for="inputUgcId">UGC-ID</label>
          <input type="text" class="form-control" id="inputUgcKey" v-model="para.args.ugc">
        </div>
      </div>

      <div v-else-if="para.type === 'location'">
        <div><b>Achtung:</b> Die Map-Points / Locations müssen im Tab "Tags, Events, Links" unter "Location" eingegeben werden!</div>
        <div class="form-group">
          <label for="inputZoomLevel">Zoom Level</label>
          <select class="form-control" id="inputZoomLevel" required v-model="para.args.zoom_level">
            <option v-for="o in $store.state.config.location_zoom_level" v-bind:value="o.value">{{ o.label }}</option>
          </select>
        </div>
      </div>

      <div v-else-if="para.type === 'poll'">
        <div class="form-group">
          <label for="inputPollOptions">Optionen</label>
          <ul v-if="para.args.options">
            <li v-for="opt in para.args.options">{{opt}} - <button type="button" class="btn btn-xs" v-on:click="removePollOption(opt)">x</button></li>
          </ul>
          <input type="text" v-model="new_poll_opt" id="inputPollOptions">
          <button type="button" class="btn btn-default" v-on:click="addPollOption">hinzufügen</button>
        </div>
        <div class="form-group">
          <label for="inputDisplayType">Ergebnisse anzeigen</label>
          <select class="form-control" id="inputDisplayType" required v-model="para.args.display_type">
            <option v-for="o in poll_display_type" :value="o.value">{{ o.label }}</option>
          </select>
        </div>
        <div class="form-group">
          <div class="checkbox">
            <label>
              <input type="checkbox" id="inputIsClosed" v-model="para.args.is_closed"> Umfrage schliessen
            </label>
          </div>
        </div>

        <div class="form-group">
          <button type="button" class="btn btn-primary" v-on:click="showPollResult()">Resultat anzeigen</button>
        </div>

        <div class="form-group" v-if="para.args.display_type === 'admin_decision'">
          <div class="checkbox">
            <label>
              <input type="checkbox" id="inputShowResults" v-model="para.args.show_results"> Ergebnisse anzeigen
            </label>
          </div>
        </div>

        <div class="form-group">
          <label for="inputInfoHelp">Info & Hilfe</label>
          <textarea class="form-control" rows="5" id="inputInfoHelp" v-model="para.args.info" required="required"></textarea>
        </div>

        <div class="form-group">
          <label for="inputClosed">geschlossene Umfrage Text</label>
          <textarea class="form-control" rows="2" id="inputClosed" v-model="para.args.closed_message"></textarea>
        </div>

        <div class="form-group">
          <label for="inputSendResultInfo">"Antwort schicken" Einleitungstext</label>
          <textarea class="form-control" rows="5" id="inputSendResultInfo" v-model="para.args.send_result_info"></textarea>
        </div>

        <div class="form-group">
          <label for="inputSendResultRcpt">"Antwort schicken" an Redaktions-Email</label>
          <input type="text" class="form-control" id="inputSendResultRcpt" v-model="para.args.send_result_rcpt"/>
        </div>

      </div>

      <div style="padding-top:10px">
        <oe1-save-close :content_id="paragraph_id" :submit_method="submit_paragraph" :withAbort="true"></oe1-save-close>
      </div>
    </form>

    <div v-if="paragraph_id" style="padding-top:10px">
      <oe1-events content_class="paragraph" :content_id="paragraph_id"></oe1-events>
    </div>



    <div role="dialog" class="modal fade in" v-if="showPoll.display" style="display:block">
      <div role="document" class="modal-dialog">
        <div class="modal-content" style="max-height: calc(100vh - 50px); ;overflow:auto">
          <div class="modal-header">
            <button type="button" class="close" v-on:click="closePollResult()">
              <span>×</span>
            </button>
            <h4 class="modal-title">{{para.args.title}}</h4>
          </div>
          <div class="modal-body">
            <slot name="body">
              <b>Resultat:</b>
              <ul>
                <li v-for="(res, i) in showPoll.result" :key="i">{{res.option}}: {{res.votes}}</li>
              </ul>
            </slot>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" v-on:click="closePollResult()">schliessen</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data: function () { return {
    para: {
      id: '',
      article: '',
      type: '',
      status: 'online',
      args : {
        authors:[],
        options:[],
        display_type:'after_vote',
        is_closed: false,
        show_results: false,
        video_is_livestream: true,
        channel: "oe1",
        audio_url: null,
        audio_info: null,
      },
      file: {
        file:'',
        filename:'',
      },
      image: {
        src: '',
      },
      display_size:'normal',
    },
    showPoll: {
      display: false,
      result: [],
    },
    new_poll_opt:'',
    show_gestalter_form: false,
    internal_forms: [
      {"label":"Quiz Lösung","value":"Quiz"},
      {"label":"Eventanmeldung","value":"ConfirmEvent"}
    ],
    image_display_size: [
      {"label":"Normal","value":"normal"},
      {"label":"ganze Breite","value":"large"},
      {"label":"Kapiteltrenner","value":"seperator"},
      {"label":"Sidebar","value":"sidebar"},
      {"label":"Originalformat","value":"original"},
    ],
    poll_display_type: [
      {"label":"schon vor Stimmabgabe","value":"before_vote"},
      {"label":"nach Stimmabgabe","value":"after_vote"},
      {"label":"wenn Admin freigibt","value":"admin_decision"},
    ],
    social_media: [
      {"label":"Facebook Post","value":"facebook"},
      {"label":"Facebook Page","value":"facebookpage"},
      {"label":"Twitter","value":"twitter"},
      {"label":"Instagram","value":"instagram"},
    ],
    radiothek_type: [
      {"label":"ganze Sendung","value":"radiothekbroadcast"},
      {"label":"Beitrag","value":"radiothek"},
      {"label":"Podcast","value":"podcast"},
    ]
  }; },
  props: ['article_id', 'pos'],
  computed: {
    paragraph_id: function() { return parseInt(this.$route.params.paragraph_id) },
  },
  watch: {
    'paragraph_id': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  methods: {
    fetchData:  function () {
      var vm = this;
      if (vm.paragraph_id) {
        vm.axios.get('/api/paragraph/' + vm.paragraph_id)
          .then(function (response) {
            vm.para = response.data;
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
    },
    submit_paragraph: function () {
      var vm = this;
      var saveUrl = vm.paragraph_id
                    ? '/api/paragraph/' + vm.paragraph_id
                    : '/api/article/' + vm.article_id + '/paragraph/' + vm.pos;
      var promise = vm.axios.post(saveUrl, vm.para );
      promise
            .then(function (response) {
              vm.$log('Absatz wurde gespeichert.');
              vm.para = response.data;
            })
            .catch(function (error) {
              vm.$log(error);
            });
      return promise;
    },
    onFileChange: function(e) {
      var vm = this;
      vm.inputFile = '';
      var files = e.target.files;
      if (!files.length) { return; }
      var file = files[0];
      var image = new Image();
      var reader = new FileReader();
      reader.onload = function(event) {
        vm.para.file.file = event.target.result;
        vm.para.file.filename = file.name;
      };
      reader.readAsDataURL(file);
    },
    linkImage(e) {
      var vm = this;
      vm.para.args.image = e.id;
      vm.axios.get('/api/image/' + e.id )
        .then(function (response) {
            vm.$log('Bild sdfsf');
            vm.para.image = response.data;
        })
        .catch(function (error) {
            vm.$log(error);
        });
    },
    addGestalter: function(hit) {
      var vm = this;
      var gestalter = {
        name: hit.firstname + ' ' + hit.lastname,
        id: hit.id,
      };
      if (!hit.permalink.match(/\/author/)) {
        gestalter.link = hit.permalink;
      }
      vm.para.args.authors.push(gestalter);
      vm.show_gestalter_form = false;
    },
    removeGestalter: function(author) {
      var vm = this;
      var without = vm.para.args.authors.filter(function(el) {
        return el.id !== author.id;
      });
      vm.para.args.authors = without;
    },
    addPollOption: function() {
      var vm = this;
      vm.para.args.options.push(vm.new_poll_opt);
      vm.new_poll_opt='';
    },
    removePollOption: function(opt) {
      var vm = this;
      var without = vm.para.args.options.filter(function(el) {
        return el !== opt;
      });
      vm.para.args.options = without;
    },
    removeEmbedExternalImage: function() {
      var vm = this;
      vm.para.image = { src: ''};
      vm.para.args.image = null;
    },
    showPollResult() {
      var vm = this;
      vm.axios.get('/api/poll-result/' + vm.paragraph_id )
        .then(function (response) {
          vm.showPoll.display = true;
          vm.showPoll.result = response.data.result;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    closePollResult() {
      this.showPoll.display = false;
      this.showPoll.result = [];
    },
    linkAudio(audio) {
      this.para.args.audio_url = audio.value;
      this.para.args.audio_info = audio.label;
    }
  }
};
</script>
