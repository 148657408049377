<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.id }}</td>
          <td>{{ $root.getStatusLabel(row.item.status) }}</td>
          <td>{{ row.item.title }}</td>
          <td>
            <span v-if="row.item.has_text">Text</span>
            <span v-if="row.item.has_image">Bild</span>
            <span v-if="row.item.has_audio">Audio</span>
            <span v-if="row.item.has_video">Video</span>
            <span v-if="row.item.has_social_media">Social Media</span>
          </td>
          <td>
            <button class="btn btn-default btn-xs" v-on:click="goTo(row.item)"><span class="glyphicon glyphicon-arrow-right" aria-hidden="true"></span></button>
            <button type="button" class="btn btn-default btn-xs" v-on:click="editLink(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
          </td>
        </template>
      </oe1-table>
    </div>

    <template slot="actions">
      <router-link to="/ugc/create" class="btn btn-info">UGC anlegen</router-link>
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>

    <div slot="filter">
      <oe1-filter-content-object
        :show-page-id=true
        :show-tag=false
        :params="params"
        v-on:oe1-update-params="updateParams"
        :status-options="statusOptions"
      ></oe1-filter-content-object>
    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'ugc',
      params: { },
      columns: [
        { label: "ID",},
        { label: "Status",},
        { label: "Titel",},
        { label: "Features",},
        { label: "", width: "5%" }
      ],
    };
  },
  methods: {
    editLink: function(item) {
      var vm = this;
      vm.$router.push('/ugc/' + item.id);
    },
    goTo: function(item) {
      var vm = this;
      vm.$router.push('/ugcsubmission/list?ugc_id=' + item.id );
    },
  }
};
</script>
