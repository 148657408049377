<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Absatz</h3>
    </div>
    <div class="panel-body">
      <router-link :to="create_top" class="btn btn-info" v-if="!locked">Absatz anlegen</router-link>
    </div>

    <draggable
        class="list-group"
        :list="paragraphs"
        :options="{handle:'.draggable-handle'}"
        element="ul"
        v-on:update="onUpdate"
    >
      <li class="list-group-item" v-for="(paragraph,index) in paragraphs">
        <div class="row">
          <div class="col-md-7">
            <span v-html="paragraph.preview" />
          </div>
          <div class="col-md-5 text-right">
            <span class="badge">{{ $root.getListLabel($store.state.config.paragraph_options,paragraph.type) }}</span>
            <span class="badge">{{ $root.getStatusLabel(paragraph.status) }}</span>
	    <span v-if="!locked">
            <button type="button" class="btn btn-default btn-xs" v-on:click="editParagraph(paragraph)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
            <oe1-confirm-modal :callback="deleteParagraphBuilder(index)"></oe1-confirm-modal>
            <span class="draggable-handle btn btn-default btn-xs">
              <span class="glyphicon glyphicon-sort" aria-hidden="true"></span>
            </span>
            </span>
          </div>
        </div>
      </li>
    </draggable>

    <div class="panel-body">
      <router-link :to="create_bottom" class="btn btn-info" v-if="!locked">Absatz anlegen</router-link>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: function () { return { paragraphs: [] }; },
  props: [ 'content_id', 'content_class' ],
  computed: {
      locked: function(){ return this.$store.getters.locked },
      create_top: function(){return '/paragraph/create?pos=top&article_id='+this.content_id },
      create_bottom: function(){return '/paragraph/create?pos=bottom&article_id='+this.content_id },
  },
  components: {
    'draggable': require('vuedraggable'),
  },
  created: function () {
    var vm = this;
    vm.axios.get('/api/'+vm.content_class+'/'+vm.content_id+'/paragraph')
      .then(function (response) {
        vm.paragraphs = response.data;
      })
      .catch(function (error) {
        vm.$log(error);
      });
  },
  methods: {
    deleteParagraphBuilder: function(index) {
        return this.deleteParagraph.bind(this,index);
    },
    deleteParagraph: function (index) {
      var vm = this;
      var paragraph = vm.paragraphs[index];
      vm.axios.delete('/api/paragraph/' + paragraph.id)
        .then(function (response) {
          vm.paragraphs.splice(index, 1);
        })
        .catch(function (error) {
          vm.$log(error);
        });
      // TODO do the delete
    },
    editParagraph: function (paragraph) {
      var vm = this;
      vm.$router.push('/paragraph/' + paragraph.id);
    },
    onUpdate: function(){
      var vm = this;
      var data = this.paragraphs.map( function(el, index) { return {id:el.id, pos:index+1}; } );
      vm.axios.post('/api/' + vm.content_class + '/' + vm.content_id + '/sort_paragraphs', data)
        .then(function (response) {
          vm.$log('Absätze wurden neu geordnet.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
  }
};
</script>

<style scoped>
.draggable-handle {
  cursor: grab;
  margin-left: 7px;
}
</style>
