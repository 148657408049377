<template>
  <div>
    <div class="col-md-9" role="main">
      <ul class="nav nav-tabs" role="tablist">
        <li :class="{active: tab === 'articledetails'}">
          <router-link :to="{path:'/article/'+article.id}">Artikel</router-link>
        </li>
        <li v-if="article.id" :class="{active: tab === 'listimage'}">
          <router-link :to="{path:'/article/'+article.id+'/listimage'}">Listenbild</router-link>
        </li>
        <li v-if="article.id"  :class="{active: tab === 'paragraphs'}">
          <router-link :to="{path:'/article/'+article.id+'/paragraphs'}">Absätze</router-link>
        </li>
        <li v-if="article.id" :class="{active: tab === 'more'}">
          <router-link :to="{path:'/article/'+article.id+'/more'}">Tags, Events, Links</router-link>
        </li>
      </ul>
      <component :is="currentView" :article="article" :content_class="content_class" :content_id="article_id" :previewlink="article.previewlink"></component>
    </div>
    <div v-if="article.id" class="col-md-3 sidebar" role="complementary">
      <oe1-previewlink :link="article.previewlink"></oe1-previewlink>
      <oe1-memo :content_class="content_class" :content_id="article_id"></oe1-memo>
      <oe1-changelog :content_class="content_class" :content_id="article_id"></oe1-changelog>
    </div>
  </div>
</template>

<script>
import ParagraphList from './ParagraphList.vue';
import ArticleDetails from './ArticleDetails.vue';

export default {
  data: function () { return {
      content_class: 'article',
      article: {
        status: 'draft',
        area: 'oe1',
      },
    };
  },
  computed: {
    tab: function() { return this.$route.params.tab || 'articledetails' },
    currentView: function() { return 'oe1-'+this.tab },
    article_id: function() { return parseInt(this.$route.params.article_id) }
  },
  watch: {
    'article_id': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  components: {
    'oe1-articledetails': ArticleDetails,
    'oe1-paragraphs': ParagraphList,
    'oe1-more': {
        props: ['article', 'content_class', 'content_id', 'previewlink'],
        template: '<div>'
                + '  <oe1-tags :content_id="content_id" :content_class="content_class"></oe1-tags>'
                + '  <oe1-relatedcontent :content_class="content_class" :content_id="content_id"></oe1-relatedcontent>'
                + '  <oe1-events :content_id="content_id" :content_class="content_class" :previewlink="previewlink"></oe1-events>'
                + '  <oe1-authors :content_class="content_class" :content_id="content_id"></oe1-authors>'
                + '  <oe1-locations :content_class="content_class" :content_id="content_id"></oe1-locations>'
                + '</div>',
    }
  },
  methods: {
    fetchData: function () {
      var vm = this;
      if (vm.article_id) {
        vm.axios.get('/api/article/' + vm.article_id)
          .then(function (response) {
            vm.article = response.data;
          })
        .catch(function (error) {
          vm.$log(error);
        });
      }
    },
  }
};
</script>
