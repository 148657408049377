<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Listenbild</h3>
    </div>
    <div v-if="image.src" class="panel-body">
      <div class="row">
        <div class="col-md-8">
        <img :src="image.src"/>
        <div>#{{image.id}} - {{image.filename}} ({{image.width}}/{{image.height}})</div>
        </div>
        <div class="col-md-4">
          Formate:
          <ul>
            <li v-for="format in image.formats">
              <a :href="format.url" target="_new">{{ format.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <form v-on:submit.prevent="store_image_metadata">
        <div class="form-group">
          <label for="liAlt">Alt</label>
          <input type="text" class="form-control" id="liAlt" v-model="image.alt" required>
        </div>
        <div class="form-group">
          <label for="liCopyright">Copyright</label>
          <input type="text" class="form-control" id="liCopyright" v-model="image.copyright" required>
        </div>
        <div class="form-group">
          <label for="liKeywords">Schlagwörter</label>
          <textarea class="form-control" rows="2" id="liKeywords" v-model="image.keywords"></textarea>
        </div>
        <div class="form-group">
          <label for="liText">Text</label>
          <textarea class="form-control" rows="5" id="liText" v-model="image.text"></textarea>
        </div>
        <button class="btn btn-primary" :disabled="locked">
          <span class="glyphicon glyphicon-floppy-saved"></span>
          Speichern
        </button>
        <oe1-confirm-modal :callback="unlink_image" body="Willst du das Bild wirklich entfernen?" buttonText="Bild entfernen" buttonStyle="btn-danger"></oe1-confirm-modal>
      </form>
    </div>
    <div v-else class="panel-body">
      <oe1-addimage v-on:oe1-link-image="linkImage"/>
    </div>
  </div>
</template>

<script>
import ContentObject from '../mixins/ContentObject.js';
import { mapGetters } from 'vuex';

export default {
  name: "oe1-listimage",
  mixins: [ ContentObject ],
  data: function() { return {
      image: {},
  } },
  computed: mapGetters(['locked']),
  created: function () {
    var vm = this;
    vm.axios.get('/api/image/edit_of/'+ vm.content_class + '/' + vm.content_id)
    .then(function (response) {
        vm.image = response.data;
    })
    .catch(function (error) {
        vm.$log(error);
    });
  },
  methods: {
    unlink_image: function() {
        var vm = this;
        vm.axios.delete('/api/image/'+vm.image.id+'/'+ vm.content_class +'/'+vm.content_id)
        .then(function (response) {
            vm.image = {};
            vm.$log('Listenbild wurde entfernt!');
        })
        .catch(function (error) {
            vm.$log(error);
        });
    },
    linkImage: function(e) {
        var vm = this;
        console.log(e);
        vm.axios.post('/api/image/' + e.id + '/' + vm.content_class +'/'+vm.content_id  )
        .then(function (response) {
            vm.$log('Listenbild wurde angelegt!');
            vm.image = response.data;
        })
        .catch(function (error) {
            vm.$log(error);
        });
    },
    store_image_metadata: function(e) {
        var vm = this;
        var data = {
            alt: vm.image.alt,
            copyright: vm.image.copyright,
            text: vm.image.text,
            keywords: vm.image.keywords,
        };
         vm.axios.post('/api/image/' + vm.image.id, data  )
        .then(function (response) {
            vm.$log('Listenbild Metadaten wurden gespeichert');
            vm.image = response.data;
        })
        .catch(function (error) {
            vm.$log(error);
        });
    }
  }
};
</script>

