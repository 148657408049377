<template>
<form v-on:submit.prevent="update_password">
  <div class="form-group">
    <label for="password">Passwort</label>
    <input v-model="password" type="password" class="form-control" id="password" placeholder="Password" required>
  </div>
  <div class="form-group">
    <label for="repeat">Passwort wiederholen</label>
    <input v-model="repeat" type="password" class="form-control" id="repeat" required placeholder="wiederholen" v-on:keyup="verifyPwd">
  </div>
  <button type="submit" class="btn btn-primary" :disabled="pwd_mismatch" >
    <span class="glyphicon glyphicon-floppy-saved"></span>
    Speichern
  </button>
</form>

</template>

<script>

export default {
  data: function () { return {
      password: '',
      repeat: '',
      pwd_mismatch: true,
    };
  },
  methods: {
    'update_password': function () {
      var vm = this;
      if (vm.pwd_mismatch) {
          vm.$log("Die Passwörter stimmen nicht überein!");
          return;
      }
      vm.axios.post('/api/user/' + vm.$route.params.user_id +'/password', {
          password: vm.password,
        })
        .then(function (response) {
          vm.$log('Passwort wurde aktualisiert.');
          vm.$router.push('/user/'+ vm.$route.params.user_id);
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    'verifyPwd': function() {
      var vm = this;
      if (vm.password === vm.repeat) {
        vm.pwd_mismatch=false;
      } else {
        vm.pwd_mismatch=true;
      }
    }
  },
};
</script>

