<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.id }}</td>
          <td>{{ $root.getListLabel($store.state.config.article_area,row.item.area) }}</td>
          <td>{{ row.item.title }}</td>
          <td>{{ $root.getStatusLabel(row.item.status) }}</td>
          <td>{{ row.item.display_date }}</td>
          <td>
            <button type="button" class="btn btn-default btn-xs" v-on:click="editItem(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>

            <a :href="row.item.previewlink" type="button" class="btn btn-default btn-xs" target="_new" title="zeige Artikel in neuem Fenster."><span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a>
            <oe1-image-modal :image_id="row.item.image"></oe1-image-modal>
          </td>
        </template>
      </oe1-table>
    </div>

    <template slot="actions">
      <router-link to="/article/create" class="btn btn-info">Artikel anlegen</router-link>
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>

    <div slot="filter">
      <oe1-filter-element label="Bereich" param="area">
        <select class="form-control" v-model="params.area" v-on:change="updateParams">
          <option></option>
          <option v-for="o in $store.state.config.article_area" :value="o.value">{{o.label}}</option>
        </select>
      </oe1-filter-element>

      <oe1-filter-content-object
        :show-created-at=true
        :params="params"
        :status-options="statusOptions"
        v-on:oe1-update-params="updateParams"
      ></oe1-filter-content-object>

      <oe1-filter-element label="Thema" param="thema">
        <input type="text" v-model="params.thema" v-on:keyup.enter="updateParams" v-on:change="updateParams">
      </oe1-filter-element>

    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'article',
      params: { status: 'all' },
      columns: [
        { label: "ID", width:"10%" },
        { label: "Bereich", width:"10%"},
        { label: "Titel" },
        { label: "Status", width:"10%" },
        { label: "Datum", width:"13%" },
        { label: "", width: "5%" }
      ]
    };
  },
};
</script>
