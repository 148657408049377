<template>
  <nav aria-label="Page navigation">
    <ul class="pagination" v-if="current_pager.total_entries > 0 && last_page > 1">
      <li v-if="first_page < current_page"><a href="#" v-on:click="goto_page(first_page, $event)" aria-label="Previous">&laquo;</a></li>
      <li v-for="link_page in prev_pages" ><a href="#" v-on:click="goto_page(link_page, $event)" >{{ link_page }}</a></li>
      <li class="active"><span class="active"><strong>{{current_page}}</strong></span></li>
      <li v-for="link_page in next_pages" ><a href="#" v-on:click="goto_page(link_page, $event)" >{{ link_page }}</a></li>
      <li v-if="current_page < last_page"><a href="#" v-on:click="goto_page(last_page, $event)"  aria-label="Next">&raquo;</a></li>
    </ul>

    <div class="pager-entries" v-if="current_pager.total_entries === 1">
        Ein Eintrag gefunden
    </div>
    <div class="pager-entries" v-else-if="current_pager.total_entries > 0">
        {{ total_entries }} Einträge gefunden
    </div>
    <div class="pager-entries" v-else>
        Keine Einträge gefunden. Bitte pass die Suchkriterien an!
    </div>
  </nav>
</template>

<style scoped>
.pager-entries {
    float: right;
    margin: 24px 0;
    font-weight: 700;
}
</style>

<script>

export default {
  name: 'oe1-pager',
  props: {
    steps           : { type: Number, default: 4 },
    current_pager   : { type: Object, default: function() {
      return { current_page: Math.max(parseInt(this.$route.query.page),1) }
    } }
  },
  data: function () { return {
    first_page: 1
  } },
  computed: {
    last_page: function(){
      return this.current_pager.last_page || 1;
    },
    total_entries: function(){
      return this.current_pager.total_entries || 0;
    },
    entries_per_page: function() {
      return this.current_pager.entries_per_page || 50;
    },
    current_page: function() {
      return this.current_pager.current_page || 1;
    },
    prev_page: function(){
      return Math.max(this.first_page,this.current_page - 1);
    },
    next_page: function(){
      return Math.min(this.current_page + 1,this.last_page);
    },
    next_pages: function() {
      var next = Math.min(this.last_page,parseInt(this.current_page) + this.steps);
      var next_pages = [];
      for (var i = this.current_page+1; i<=next; i++ ) {
        next_pages.push(i);
      }
      return next_pages;
    },
    prev_pages: function() {
      var prev = Math.max(this.first_page + 1,parseInt(this.current_page) - this.steps);
      var prev_pages = [];
      for (var i = prev; i<this.current_page; i++ ) {
        prev_pages.push(i);
      }
      return prev_pages;
    }
  },
  methods: {
    reload: function () { this.set_page(this.current_page); },
    goto_page: function (page,event) {
      var vm = this;
      event.preventDefault();
      var query = Object.assign({}, this.$route.query, { page: page });
      this.$router.push({
        query: query
      });
      return false;
    },
    set_page: function (page) {
      var vm = this;
      vm.$emit('oe1-update-params', { page: page });
    },
  }
};
</script>
