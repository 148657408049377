<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.url }}</td>
          <td><router-link :to="{ path: '/' + row.item.content_class + '/' + row.item.content_id }">{{ row.item.content_class }}:{{row.item.content_id}}</router-link>
          </td>
          <td><router-link v-if="row.item.canonical_content_class" :to="{ path: '/' + row.item.canonical_content_class + '/' + row.item.canonical_content_id }">{{ row.item.canonical_content_class }}:{{row.item.canonical_content_id}}</router-link></td>
          <td>
            <button type="button" class="btn btn-default btn-xs" v-on:click="editUrl(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
             <a :href="row.item.previewlink" type="button" class="btn btn-default btn-xs" target="_new" title="zeige Ziel in neuem Fenster."><span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a>
            <oe1-confirm-modal :callback="deleteUrlBuilder(row.item)"></oe1-confirm-modal>
          </td>
        </template>
      </oe1-table>
    </div>

    <template slot="actions">
      <router-link to="/url/create" class="btn btn-info">URL anlegen</router-link>
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>

    <div slot="filter">
      <oe1-filter-content-object
        :show-status=false
        :show-title=false
        :show-page-id=false
        :show-created-at=false
        :show-tag=false
        :params="params"
        v-on:oe1-update-params="updateParams"
        :status-options="statusOptions"
      ></oe1-filter-content-object>

      <oe1-filter-element label="URL" param="url">
        <input type="text" v-model="params.url" v-on:change="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Verweist auf Objekt" param="content_class">
        <select class="form-control" v-model="params.content_class" v-on:change="updateParams">
          <option></option>
          <option v-for="o in $store.state.config.content_classes" :value="o.value">{{o.label}}</option>
        </select>
      </oe1-filter-element>

      <oe1-filter-element label="Verweist auf ID" param="content_id">
        <input type="text" v-model="params.content_id" v-on:change="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Kanonisch für Objekt" param="canonical_content_class">
        <select class="form-control" v-model="params.canonical_content_class" v-on:change="updateParams">
          <option></option>
          <option v-for="o in $store.state.config.content_classes" :value="o.value">{{o.label}}</option>
        </select>
      </oe1-filter-element>

      <oe1-filter-element label="Kanonisch für ID" param="canonical_content_id">
        <input type="text" v-model="params.canonical_content_id" v-on:change="updateParams">
      </oe1-filter-element>

    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'url',
      params: {
        canonical_content_class: '',
        canonical_content_id: '',
        content_class: '',
        content_id: '',
        url: '',
      },
      columns: [
        { label: "URL",},
        { label: "Verweist auf",},
        { label: "Kanonisch für",},
        { label: "", width: "5%" }
      ],
    };
  },
  methods: {
    editUrl: function(item) {
      var vm = this;
      vm.$router.push('/url/' + item.id);
    },
    deleteUrlBuilder: function(item) {
        return this.deleteUrl.bind(this,item);
    },
    deleteUrl: function(item) {
      var vm = this;
      vm.axios.delete('/api/url/' + item.id )
        .then(function (response) {
          vm.$log('URL "' + item.url + '" wurde entfernt.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      vm.$router.go(vm.$router.currentRoute)
    },
  }
};
</script>
