module.exports = [
    { value: 'content_object_by_tag',    label: 'Objekt Tag', show: {rows: true, continue: true, order_by: true} },
    { value: 'content_object_by_id', label: 'Objekt Id', show: {rows: false, continue: false, order_by: false} },
    { value: 'any_object_by_tag',    label: 'Beliebiges Objekt Tag', show: {rows: true, continue: true, order_by: true} },
    { value: 'broadcast_by_tag', label: 'Sendungen mit Tag', show: {rows: true, continue: true, order_by: false} },
    { value: 'broadcast_by_serial', label: 'Sendungen einer Sendereihe', show: {rows: true, continue: true, order_by: false} },
    { value: 'broadcast_by_author', label: 'Sendungen von Gestalter*in', show: {rows: true, continue: true, order_by: false} },
    { value: 'broadcast_by_serial_set', label: 'Sendungen mehrer Sendereihen', show: {rows: true, continue: true, order_by: false} },
    { value: 'broadcast_downloadable', label: 'Downloadbare Sendungen', show: {rows: true, continue: true, order_by: false} },
    { value: 'search_by_author', label: 'Suche nach Gestalter*in', show: {rows: true, continue: true, order_by: false} },
    { value: 'serial', label: 'Sendereihe', show: {rows: false, continue: false, order_by: false} },
    { value: 'article_by_area', label: 'Artikel eines Bereichs', show: {rows: true, continue: true, order_by: true} },
    { value: 'kulturkalender',   label: 'Kulturkalender' , show: {rows: false, continue: false, order_by: false}},
    { value: 'recent_journal_stories',   label: 'Journalbeiträge',show: { rows: true, continue: false, order_by: false }},
    { value: 'live_video_stream_orf_media_library',   label: 'Live Video Stream',show: { rows: false, continue: false, order_by: false}},
    { value: 'text_link', label: 'Text und Link',show: { rows: false, continue: false, order_by: false}},
    { value: 'link', label: 'Link',show: { rows: false, continue: false, order_by: false}},
    { value: 'az_nav', label: 'A-Z Navigation',show: { rows: false, continue: false, order_by: false}},
    { value: 'all_journal_stories',   label: 'Alle Journalbeiträge',show: { rows: true, continue: true, order_by: false }},
    { value: 'ugc_submissions_list', label: 'UGC Einreichungen',show: { rows: true, continue: true, order_by: false}},
    { value: 'location', label: 'Locations',show: { rows: true, continue: true, order_by: false}},
];
