<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Links</h3>
    </div>
    <div class="panel-body">
      <button type="button" class="btn btn-info" v-on:click="createLink" :disabled="locked">Link anlegen</button>
      <button type="button" class="btn btn-info" v-on:click="listLinks">Zur Liste</button>
    </div>
    <oe1-table
      v-if="items.length"
      :columns="columns"
      :items="items">
      <template slot-scope="row">
        <td>{{ row.item.id }}</td>
        <td>{{ row.item.status }}</td>
        <td>{{ row.item.title }}</td>
        <td>
          <button type="button" class="btn btn-default btn-xs" v-on:click="goTo(row.item)">
            <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
          </button>
        </td>
      </template>
    </oe1-table>
    <div v-else class="panel-footer">
      Keine Links vorhanden.
    </div>
  </div>
</template>

<script>
import Table from './Table.vue';

export default {
  name: "oe1-link",
  data: function () { return {
    items: [],
    columns: [
      { label: "ID"},
      { label: "Status"},
      { label: "Titel"},
      { label: "", width: "10%" }
    ],
  }; },
  props: ['content_class','content_id'],
  components: {
    'oe1-table': Table,
  },
  created: function () {
    var vm = this;
    vm.axios.get('/api/link',{params : {content_class: vm.content_class,content_id: vm.content_id, entries_per_page: 5}})
      .then(function (response) {
        vm.items = response.data.items;
      })
      .catch(function (error) {
        vm.$log(error);
      });
  },
  computed: {
    locked: function(){ return this.$store.getters.locked },
  },
  methods: {
    createLink: function () {
      var vm = this;
      vm.axios.post('/api/link/create-from/'+ vm.content_class +'/'+ vm.content_id)
        .then(function (response) {
          vm.$log('Link wurde angelegt.');
          vm.$router.push('/link/' + response.data.id );
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    listLinks: function () {
      var vm = this;
      vm.$router.push('/link/list?content_class=' + vm.content_class +'&content_id='+vm.content_id);
    },
    goTo: function(item) {
      var vm = this;
      vm.$router.push('/link/' +item.id + '?tab=main');
    },
  }
};
</script>

