<template>
  <div>
    <form v-on:submit.prevent>
      <div class="form-group">
        <label for="status">Status</label>
        <select class="form-control" id="widget.status" required v-model="widget.status">
          <option v-for="o in $store.state.config.simple_status_options" :value="o.value">{{ o.label }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="title">Titel</label>
        <input v-model="widget.title" type="text" class="form-control" id="widget.title" placeholder="Titel" autofocus>
      </div>

      <div v-if="widget.show_show_more" style="padding-bottom:22px">
        <label>  Mehr anzeigen
        <input type="checkbox" v-model="widget.show_more" />
        </label>
      </div>

      <div v-if="widget.show_show_image_in_profile" style="padding-bottom:22px">
        <label>  Bild (wenn vorhanden) im Sidebar anzeigen
        <input type="checkbox" v-model="widget.show_image_in_profile" />
        </label>
      </div>

      <div v-if="widget.render_as === 'box_image' || widget.render_as === 'box_text'" style="padding-bottom:22px">
        <label for="widget.tracking_pixel">Tracking Pixel</label>
        <input v-model="widget.tracking_pixel" type="text" class="form-control" id="widget.tracking_pixel" />
      </div>


      <oe1-save-close :content_id="widget.id" :submit_method="submit_widget"></oe1-save-close>
    </form>
    <div v-if="widget.id" style="margin-top:20px;">
      <oe1-events :content_class="'widget'" :content_id="widget.id"></oe1-events>
    </div>
  </div>
</template>


<script>

export default {
  data: function () { return {
    widget: {
      status: 'draft',
      show_show_more: false,
      show_show_image_in_profile: false
    }
    };
  },
  watch: {
    '$route': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  props: ['widget_id'],
  methods: {
    fetchData: function () {
      var vm = this;
      vm.axios.get('/api/widget/' + vm.$route.params.widget_id)
        .then(function (response) {
          vm.widget = response.data;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    'submit_widget': function () {
      var vm = this;
      var promise = vm.axios.post('/api/widget/' + vm.widget_id, vm.widget);
      promise
        .then(function (response) {
          vm.$log('Widget wurde gespeichert.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    }
  }
};
</script>
