module.exports = [
    { value: 'oe1',   label: 'Ö1' },
    { value: 'archiv', label: 'Archiv' },
    { value: 'campus', label: 'Campus' },
    { value: 'club', label: 'Club' },
    { value: 'podcast', label: 'Podcast' },
    { value: 'quiz', label: 'Quiz' },
    { value: 'schule', label: 'Schule' },
    { value: 'talenteboerse', label: 'Talentebörse' },
    { value: 'rkh-event', label: 'RKH Events' },
    { value: 'rkh-libero', label: 'RKH Libero' },
    { value: 'hoerspiel', label: 'Hörspiel' },
    { value: 'error', label: 'Fehlerseite' },
];
