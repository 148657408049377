import Vue from 'vue';
import { mapState } from 'vuex';
import store from "./Store.js";

const component = {
  template: '<div><div v-for="(msg,idx) in log_msgs"><div :class="[\'alert\', msg.level]">{{ msg.message}}<button type="button" class="close" style="opacity:.5;color:#a00;border:1px solid #a00;padding:1px;font-size:smaller" v-on:click="removeMsg(msg.ident)"> <span>X</span> </button></div></div></div>',
  computed: mapState(['log_msgs']),
  methods: {
    removeMsg: function(ident) {
      store.dispatch('remove_log_msg', {ident: ident});
    }
  }

};

var plugin = {
  install: function(Vue, options) {
    Vue.prototype.$log = function(msg) {
      this.$store.dispatch('add_log_msg', {msg: msg});
    }
  }
};

Vue.use(plugin);
export default component;
