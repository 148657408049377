module.exports = [
  { value: 'text'               , label: 'Text' },
  { value: 'image'              , label: 'Bild' },
  { value: 'tip'                , label: 'Tipp' },
  { value: 'infobox'            , label: 'Infobox' },
  { value: 'infobox_with_image' , label: 'Infobox mit Bild' },
  { value: 'pdf'                , label: 'PDF Download' },
  { value: 'radiothek'          , label: 'Radiothek' },
  { value: 'audio'              , label: 'Audio' },
  { value: 'download'           , label: 'Download' },
  { value: 'video_oon'          , label: 'Video Stream' },
  { value: 'video_external'     , label: 'Externes Video' },
  { value: 'social_media'       , label: 'Social Media' },
  { value: 'embed_external'     , label: 'Embed External' },
  { value: 'quotation'          , label: 'Zitat' },
  { value: 'poll'               , label: 'Umfrage' },
  { value: 'ugc_upload'         , label: 'UGC-Upload' },
  { value: 'location'           , label: 'Location' },
  { value: 'internal_form'      , label: 'Intern: Formular' },
  { value: 'internal_key_value'      , label: 'Intern: Key-Value' },
  { value: 'stoerer'  , label: 'Störer' },
  { value: 'raw'      , label: 'Raw' },
];

