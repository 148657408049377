<template>
    <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">AutorInnen</h3>
    </div>
    <div class="panel-body">
      <div class="form-inline">
        <oe1-autocomplete :datasource="'/api/autocomplete/author?q='" v-on:selected="addAuthor" placeholder="AutorIn hinzufügen"></oe1-autocomplete>
      </div>
    </div>

    <div v-if="items.length">
      <draggable
        class="list-group"
        :list="items"
        :options="{handle:'.draggable-handle'}"
        element="ul"
        v-on:update="newOrder"
      >
        <li class="list-group-item" v-for="author in items">
          <div class="row">
            <div class="col-md-7">
              {{author.title}}
            </div>
            <div class="col-md-5 text-right">
              <button class="btn btn-default btn-xs" v-on:click="goTo(author)"><span class="glyphicon glyphicon-arrow-right" aria-hidden="true"></span></button>
              <oe1-confirm-modal :callback="removeAuthorBuilder(author)"></oe1-confirm-modal>
              <span class="draggable-handle btn btn-default btn-xs" v-if="!locked">
                <span class="glyphicon glyphicon-sort" aria-hidden="true"></span>
              </span>
            </div>
          </div>
        </li>
      </draggable>
    </div>
    <div v-else class="panel-footer">
      Keine AutorInnen verlinkt.
    </div>
  </div>
</template>

<script>
import ContentObject from '../mixins/ContentObject.js';

export default {
  name: "oe1-authors",
  mixins: [ ContentObject ],
  data: function() { return {
      items: [],
  } },
  components: {
    draggable: require('vuedraggable'),
  },
  props: {},
  created: function () {
    var vm = this;
    vm.axios.get('/api/author/linked/'+vm.content_object_url)
      .then(function (response) {
        vm.items = response.data;
      })
      .catch(function (error) {
        vm.$log(error);
      });
  },
  computed: {
    locked: function(){ return this.$store.getters.locked },
    content_object_url: function() {
      var vm = this;
      return vm.content_class+'/'+vm.content_id;
    },
    showSearchResult: function() {
      var vm = this;
      return vm.searchResult.length > 0 ? true : false;
    },
  },
  methods: {
    newOrder: function() {
      var vm = this;
      vm.axios.put('/api/author/linked/'+vm.content_object_url, vm.items)
        .then(function (response) {
          vm.$log('AutorInnen wurden umsortiert.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    addAuthor: function(hit) {
      var vm = this;
      vm.axios.post('/api/author/'+ hit.id +'/link/' + vm.content_object_url)
        .then(function (response) {
          vm.items = response.data;
          vm.$log('AutorIn "' + hit.value + '" wurde hinzugefügt.');
          vm.searchResult = [];
          vm.searchQuery='';
        })
        .catch(function (error) {
          vm.$log(error);
          vm.searchResult = [];
          vm.searchQuery='';
        });
    },
    removeAuthorBuilder: function(item) {
        return this.removeAuthor.bind(this,item);
    },
    removeAuthor: function (rc) {
      var vm = this;
      vm.axios.delete('/api/author/' + rc.id + '/link/' + vm.content_object_url )
        .then(function (response) {
          vm.items = response.data;
          vm.$log('AutorIn "' + rc.title + '" wurde entfernt.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    goTo: function(item) {
      var vm = this;
      vm.$router.push('/author/' +item.id + '?tab=main');
    },
  }
};
</script>


