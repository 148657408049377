<template>
  <div>
    <div v-if="allow_add_selection && selections.length > 10">
      <div v-if="showAddSelectionForm">
        <form class="form-inline" v-on:submit.prevent="addSelection('top')">
          <div class="form-group">
            <label for="inputAction">Selection-Typ</label>
            <select class="form-control" id="newSelection" v-model="newSelection" required>
              <option v-for="o in available_selections" :value="o">{{ o }}</option>
            </select>
          </div>
          <button type="button" class="btn btn-default" v-on:click="addSelection('top')">Selection hinzufügen</button>
          <button type="button" class="btn btn-default" v-on:click="showAddSelectionForm=false">Abbrechen</button>
        </form>
      </div>
      <div v-else>
        <button type="button" class="btn btn-info" v-on:click="showAddSelectionForm = true" :disabled="locked">Selection hinzufügen</button>
      </div>
    </div>
    <draggable
        class="list-group"
        :list="selections"
        :options="{handle:'.draggable-handle'}"
        element="ul"
        v-on:update="sortSelections"
    >
      <li class="list-group-item" :class="selection.status" v-for="(selection,index) in selections">
        <div class="row">
          <div class="col-md-7">
            <h5><span v-if="selection.title">{{ selection.title }}: </span>{{ selection.type }} - {{ selection.status }}</h5>
          </div>
          <div class="col-md-5 text-right">
            <button type="button" class="btn btn-default btn-xs" v-on:click="editSelection(selection)" :disabled="locked">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
            <oe1-confirm-modal :callback="deleteSelectionBuilder(selection,index)"></oe1-confirm-modal>
            <span class="draggable-handle btn btn-default btn-xs glyphicon glyphicon-sort" v-if="!locked"></span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            {{ selection.info }}
          </div>
        </div>
      </li>
    </draggable>
    <div v-if="allow_add_selection">
      <div v-if="showAddSelectionForm">
        <form class="form-inline" v-on:submit.prevent="addSelection('bottom')">
          <div class="form-group">
            <label for="inputAction">Selection-Typ</label>
            <select class="form-control" id="newSelection" v-model="newSelection" required>
              <option v-for="o in available_selections" :value="o">{{ o }}</option>
            </select>
          </div>
          <button type="button" class="btn btn-default" v-on:click="addSelection('bottom')">Selection hinzufügen</button>
          <button type="button" class="btn btn-default" v-on:click="showAddSelectionForm=false">Abbrechen</button>
        </form>
      </div>
      <div v-else>
        <button type="button" class="btn btn-info" v-on:click="showAddSelectionForm = true" :disabled="locked">Selection hinzufügen</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() { return {
    showAddSelectionForm: false,
    newSelection: '',
  } },
  components: {
    draggable: require('vuedraggable'),
  },
  computed: {
    locked: function(){ return this.$store.getters.locked },
    allow_add_selection: function() {
      var vm = this;
      if (vm.only_one_selection === 0) {
       return true;
      }
      if (vm.selections.length >= 1) {
        return false;
      }
      return true;
    }
  },
  props: ['collection_id', 'widget_id', 'selections','only_one_selection','available_selections'],
  methods: {
    addSelection: function (pos) {
      var vm = this;
      this.$router.push('/selection/create?widget_id='+vm.widget_id+'&type='+vm.newSelection+'&pos='+pos);
    },
    editSelection: function (selection) {
      var vm = this;
      this.$router.push('/selection/'+  selection.id);
    },
    deleteSelectionBuilder: function (selection, index) {
      return this.deleteSelection.bind(this,selection.id,index);
    },
    deleteSelection: function (selection_id, index) {
      var vm = this;
      vm.axios.delete('/api/selection/' + selection_id)
        .then(function (response) {
          vm.selections.splice(index, 1);
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    sortSelections: function () {
      var vm = this;
      var data = vm.selections.map( function(el) { return el.id } );
      vm.axios.put('/api/widget/' + vm.widget_id + '/selection', data)
        .then(function (response) {
          vm.$log('Selections wurden neu geordnet.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
  }
};
</script>


<style scoped>

li.list-group-item {
border: 2px solid #ddd;
margin-bottom:5px;
}


li.draft {
  background-color:#d2b4b4;
}

li.offline {
  background-color:#ccc;
}

h5 {
  margin-top:0px;
}

</style>
