<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="linkId">Mit Image-ID verlinken:</label>
          <input type="text" class="form-control" id="linkId" v-model="linkId" placeholder="12345" v-on:keyup.enter="linkImage"/>
        </div>
        <button class="btn btn-primary" v-on:click="linkImage" :disabled="!linkId">verlinken</button>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="inputFile">Neues Bild hochladen:</label>
          <input type="file" id="inputFile" v-on:change="onFileSelected" />
        </div>
        <button class="btn btn-primary" v-on:click="uploadImage" :disabled="!file_selected">Bild speichern</button>
      </div>
    </div>
    <div class="row" style="margin-top:1em; margin-bottom:1em">
      <div class="col-md-12">
        <label for="autocomplete">Suchen & verlinken:</label>
        <input type="text" class="form-control" id="autocomplete" v-model="acIn" placeholder="Hier tippen, um Bilder via Autocomplete zu suchen" @input="autocomplete(1)"/>
      </div>
    </div>
    <div v-if="ajaxBusy" class="row">
      <div class="col-md-2">
        <img src="/ajax-loader.gif"/>
      </div>
    </div>
    <div v-if="hasAcResult">
      <div class="row">
        <div class="col-md-12 text-center">
          <button v-if="acPager.current_page > 1" @click="autocomplete(acPager.current_page - 1)" class="btn btn-primary">&laquo;</button>
          <span style="padding-left:1em;padding-right:1em">{{ acPager.total_entries}} Treffer - Seite {{ acPager.current_page }} / {{ acPager.last_page }}</span>
          <button v-if="acPager.current_page < acPager.last_page" @click="autocomplete(acPager.current_page + 1)" class="btn btn-primary">&raquo;</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4" v-for="(img, i) in acResult" :key="i" style="padding:15px;height:195px;overflow: hidden;white-space: nowrap;">
          <img :src="imgSrc(img)" @click="linkImage(img.id)"/><br/>
          #{{ img.id }} ({{img.width}}/{{img.height}})<br/>
          <router-link :to="{ name: 'image.edit', params: {image_id: img.id}  }" target="_blank" type="button" class="btn btn-primary btn-xs"><span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></router-link> {{ img.filename}}
        </div>
      </div>
      <div class="row" style="padding-top:10px">
        <div class="col-md-12 text-center">
          <button v-if="acPager.current_page > 1" @click="autocomplete(acPager.current_page - 1)" class="btn btn-primary">&laquo;</button>
          <span style="padding-left:1em;padding-right:1em">{{ acPager.total_entries}} Treffer - Seite {{ acPager.current_page }} / {{ acPager.last_page }}</span>
          <button v-if="acPager.current_page < acPager.last_page" @click="autocomplete(acPager.current_page + 1)" class="btn btn-primary">&raquo;</button>
        </div>
      </div>
    </div>
    <div v-else-if="noAcHits" class="row">
      <div class="col-md-12">
        <p><b>Keine Fotos gefunden!</b></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "oe1-addimage",
  data: function() { return {
    linkId: undefined,
    file_selected: false,
    file: '',
    filename: '',
    width: undefined,
    height: undefined,
    acIn: undefined,
    acResult: [],
    acPager: {},
    acDebounce: undefined,
    ajaxBusy: false,
  } },
  computed: {
    hasAcResult() {
      return this.acResult.length ? true : false;
    },
    noAcHits() {
      return this.acPager.total_entries == 0 ? true : false;
    }
  },
  methods: {
    uploadImage: function() {
        var vm = this;
        var data = {
            file: vm.file,
            filename: vm.filename,
            width: vm.width,
            height: vm.height,
        };
        vm.axios.post('/api/image', data  )
        .then(function (response) {
            vm.$log('Foto "'+vm.filename +'" wurde gespeichert.');
            vm.$emit('oe1-link-image',{ id: response.data.id });
        })
        .catch(function (error) {
            vm.$log(error);
        });
    },
    linkImage: function(id) {
      if (this.linkId) {
        id = this.linkId;
      }
      if (typeof id == 'number' || id.match(/^[0-9]+$/) != null) {
        this.$emit('oe1-link-image',{ id: id });
      }
      else {
        this.$log({name: 'Fancy', msg: 'Ungültige Foto-ID: ' + id, level: 'alert-danger' }  );
      }
    },
    onFileSelected: function(e) {
        var vm = this;
        vm.inputFile = '';
        var files = e.target.files;
        if (!files.length) { return; }
        var file = files[0];
        var image = new Image();
        var reader = new FileReader();
        reader.onload = function(event) {
            vm.file = event.target.result;
            vm.filename = file.name;

            image.src = reader.result;

            image.onload = function() {
              vm.width = image.width;
              vm.height = image.height;
            };
        };
        reader.readAsDataURL(file);
        vm.file_selected = true;
    },
    autocomplete(page) {
      var vm = this;
      vm.ajaxBusy = true;
      if (this.acDebounce) clearTimeout(this.acDebounce);
      this.acDebounce = setTimeout(async function() {
        vm.axios.get('/api/image', { params: { q: vm.acIn , entries_per_page: 12, page: page} }  )
          .then(function (response) {
            vm.acResult = response.data.items;
            vm.acPager = response.data.pager;
            vm.ajaxBusy = false;
          })
          .catch(function (error) {
              vm.$log(error);
              vm.ajaxBusy = false;
          });
      }, 350);
    },
    imgSrc(img) {
      return '/i/list/' + img.sha1.substr(0,2) + '/' + img.sha1.substr(2,2) + '/' + img.sha1 + '.' + img.extension;
    }
  }
};
</script>

