<template>
  <div>
    <h1>Passwort ändern</h1>
    <form v-on:submit.prevent="update_password">
      <div class="form-group">
        <label for="oldPassword">Aktuelles Passwort</label>
        <input v-model="oldPassword" type="password" class="form-control" id="oldPassword" required>
      </div>

      <div class="form-group">
        <label for="newPassword">Neues Passwort</label>
        <input v-model="newPassword" type="password" class="form-control" id="newPassword" placeholder="Neues Password" required v-on:keyup="verifyPwd">
      </div>
      <div class="form-group">
        <label for="repeat">Passwort wiederholen</label>
        <input v-model="repeat" type="password" class="form-control" id="repeat" required placeholder="wiederholen" v-on:keyup="verifyPwd">
      </div>
      <div v-if="pwd_error" class="alert alert-danger" role="alert">
        <div v-if="pwd_mismatch">Die Passwörter stimmen nicht überein!</div>
        <div v-if="pwd_too_short">Passwort zu kurz!</div>
      </div>
      <button type="submit" class="btn btn-primary" :disabled="pwd_error" >
        <span class="glyphicon glyphicon-floppy-saved"></span>
        Passwort ändern
      </button>
    </form>
  </div>
</template>

<script>

export default {
  data: function () { return {
      oldPassword: '',
      newPassword: '',
      repeat: '',
      pwd_mismatch: false,
      pwd_too_short: false,
    };
  },
  computed: {
    pwd_error: function() {
      var vm = this;
      return vm.pwd_mismatch || vm.pwd_too_short;
    },
  },

  methods: {
    'update_password': function () {
      var vm = this;
      if (vm.pwd_mismatch) {
          vm.$log("Die Passwörter stimmen nicht überein!");
          return;
      }
      vm.axios.post('/api/my/password', {
          old: vm.oldPassword,
          new: vm.newPassword,
        })
        .then(function (response) {
          vm.$log('Passwort wurde aktualisiert.');
          vm.$router.push('/');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    'verifyPwd': function() {
      var vm = this;
      if (vm.newPassword === vm.repeat) {
        vm.pwd_mismatch = false;
      } else {
        vm.pwd_mismatch = true;
      }

      if (vm.newPassword.length < 6) {
        vm.pwd_too_short = true;
      }
      else {
        vm.pwd_too_short = false;
      }
    }
  },
};
</script>

