import pager from '../reusable/Pager.vue';
import FilterElement from '../reusable/FilterElement.vue';
import Table from '../reusable/Table.vue';
import ListPage from '../reusable/ListPage.vue';

export default {
  data: function() {
    return {
      items: [],
      pager: {},
      params: {},
      queried_params: '',
      stringified_params: ''
    }
  },
  components: {
    'oe1-pager': pager,
    'oe1-filter-element': FilterElement,
    'oe1-table': Table,
    'oe1-list-page': ListPage
  },
  created: function () {
    this.getParamsFromRoute(this.$route.query);
    this.fetchItems(true);
  },
  watch: {
    '$route': function(to, from) {
      this.getParamsFromRoute(to.query);
    }
  },
  computed: {
    statusOptions: function() {
      return this.$store.state.config.content_status_options;
    }
  },
  methods: {
    getParamsFromRoute: function(query) {
      var params = {};
      Object.keys(query).forEach(function(key) {
        params[key] = query[key];
      });
      if (Object.keys(params).length === 0
        && this.$store.state.list_params[this.apiName] !== undefined) {
        params = this.$store.state.list_params[this.apiName];
      }
      this.params = params;
      this.updateParams();
    },
    resetParams: function() {
      this.params = { page: 1 };
      this.$store.commit('set_list_params',{ list: this.apiName, params: {} });
      this.fetchItems();
    },
    updateParams: function() {
      // Always go to page 1 if params change
      if (this.stringified_params !== this.stringifyParams(this.params)) {
        this.params.page = 1;
      }

      this.$store.commit('set_list_params',{ list: this.apiName, params: this.params });

      this.fetchItems();
    },

    stringifyParams: function(params) {
        params = params || this.params;

        if (params === null) {
          return 'page=#'
        }

        var list = [];
        Object.keys(params).sort().forEach(function(key) {
          if (key === 'page'
            || params[key] === undefined
            || params[key] === '') {
            return;
          }
          list.push(key+'='+params[key]);
        });

        return list.join('&');
    },

    fetchItems: function(force) {
      var vm = this;

      var newParams = JSON.stringify(vm.params);
      if (force === undefined && vm.queried_params === newParams) {
        return;
      }

      var query = {};
      Object.keys(vm.params).forEach(function(key) {
        if (vm.params[key] !== undefined
          && vm.params[key] !== '') {
          query[key] = vm.params[key];
        }
      });

      vm.stringified_params = vm.stringifyParams();
      vm.queried_params = newParams;
      vm.$router.push({
        query: query
      });

      vm.axios
        .get('/api/' + vm.apiName, { params: vm.params } )
        .then(function (response) {
          vm.items = response.data.items;
          vm.pager = response.data.pager;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    editItem: function (item) {
      this.$router.push('/'+this.apiName+'/'+item.id);
      // TODO letzten filter wegspeichern um zurück zu springen nach edit
      // TODO apiName oder eigene prop?
      // Anmerkung davewood zum TODO: wenn man im edit einfach router.back() macht
      // erspart man sich vermute ich das wegspeichern des filters.
    },
  }
};
