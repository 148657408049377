<template>
  <form v-on:submit.prevent>
    <div class="form-group">
      <label for="inputStatus">Status</label>
      <select class="form-control" id="inputStatus" required v-model="serial.status">
        <option v-for="o in $store.state.config.content_status_options" :value="o.value">{{ o.label }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="inputTitle">Titel</label>
      <input
        v-model="serial.title"
        type="text"
        class="form-control"
        id="inputTitle"
        placeholder="Titel"
        required
        autofocus
      />
    </div>
    <div class="form-group">
      <label for="inputRessort">Ressort</label>
      <select class="form-control" id="inputRessort" required v-model="serial.ressort">
        <option v-for="o in $store.state.config.ressorts" :value="o.value">{{ o.label }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="inputTeaser">Teaser</label>
      <textarea
        v-model="serial.teaser"
        rows="5"
        class="form-control"
        id="inputTeaser"
        placeholder="Teaser"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="inputSendungsid">Sendungs-ID</label>
      <input
        v-model="serial.sendungsid"
        type="text"
        class="form-control"
        id="inputSendungsid"
        placeholder="Sendungs-ID"
        required
        autofocus
      />
    </div>
    <div class="form-group">
      <label for="inputCode">Digas/Archiv-Kürzel</label>
      <input
        v-model="serial.code"
        type="text"
        placeholder="ABC"
        class="form-control"
        id="inputCode"
      />
    </div>
    <div class="form-group">
      <label for="inputID3">ID3-Tag</label>
      <input
        v-model="serial.id3tag"
        type="text"
        class="form-control"
        id="inputID3"
      />
    </div>

    <div class="form-group">
      <label for="inputSortDate">Sortier Datum</label>
      <flat-pickr v-model="serial.sort_date" :config="$store.state.config.datetime_options" />
    </div>
    <div class="form-group">
      <div class="checkbox">
        <label>
          <input type="checkbox" v-model="serial.is_downloadable" /> Zum Downloaden
        </label>
        <label>
          <input type="checkbox" v-model="serial.has_download_optin" /> Opt-in fürs Download
        </label>
        <label>
          <input type="checkbox" v-model="serial.has_playlist" /> Hat Playlist
        </label>
        <label>
          <input type="checkbox" v-model="serial.is_on_demand" /> On-Demand
        </label>
        <label>
          <input type="checkbox" v-model="serial.is_journal" /> Journal
        </label>
      </div>
    </div>

    <oe1-save-close :content_id="serial.id" :submit_method="submit_serial"></oe1-save-close>
    </span>

  </form>
</template>

<script>

export default {
  props: [ 'serial' ],
  methods: {
    'submit_serial': function () {
      var vm = this;
      var save_url = vm.serial.id
                     ? '/api/serial/' + vm.serial.id
                     : '/api/serial';

      var promise = vm.axios.post(save_url, vm.serial);
      promise
        .then(function (response) {
          vm.$log('Sendereihe wurde gespeichert.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
  }
};
</script>
