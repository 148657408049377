<template>
  <div>
    <div class="col-md-9" role="main">

      <h3 v-if="push_notification.id">Push Notification bearbeiten</h3>
      <h3 v-else>Push Notification anlegen</h3>

      <form v-on:submit.prevent>
        <div class="form-group" v-if="push_notification.status">
          <label for="inputStatus">Status</label>
          {{ push_notification.status }}
          <span v-if="push_notification.status === 'submitted'"> - id {{ push_notification.notification_id }}</span>
          <span v-if="push_notification.is_expired"> - ausgeliefert</span>
          <button v-if="push_notification.status === 'pending'" class="btn btn-default" style="margin-left:10px" @click="publishNotification">
            <span class="glyphicon glyphicon-send"></span>
            Veröffentlichen
          </button>
          <button v-if="push_notification.status === 'submitted' && !push_notification.is_expired" class="btn btn-default" style="margin-left:10px" @click="deleteNotification">
            <span class="glyphicon glyphicon-delete"></span>
            Löschen
          </button>

        </div>

        <div class="form-group">
          <label for="inputTopic">Topic</label>
          <select class="form-control" id="inputTopic" required :disabled="push_notification.is_expired" v-model="push_notification.topic">
            <option v-for="o in $store.state.config.push_topic_options" :value="o.value">{{ o.label }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="title">Titel</label>
          <input v-model="push_notification.title" type="text" class="form-control" id="title" placeholder="" autofocus required :disabled="push_notification.is_expired">
        </div>

        <div class="form-group">
          <label for="inputContent">Content</label>
          <textarea v-model="push_notification.content" rows="3" class="form-control" id="inputCOntent" placeholder="Content" required :disabled="push_notification.is_expired"></textarea>
        </div>

        <div class="form-group">
          <label for="url">URL</label>
          <input v-model="push_notification.url" type="text" class="form-control" id="url" autofocus :disabled="push_notification.is_expired">
        </div>

        <div class="form-group">
          <label for="inputDate">Zeitpunkt</label>
          <div>
            <flat-pickr v-model="push_notification.time_to_send" :config="$store.state.config.datetime_options" :disabled="push_notification.is_expired"/>
          </div>
        </div>

        <div class="form-group">
          <label for="expiration">Ablauf nach Zeitpunkt (Minuten)</label>
          <input v-model="push_notification.expiration" type="number" min="0" class="form-control" id="expiration" autofocus :disabled="push_notification.is_expired">
        </div>

        <oe1-save-close :content_id="push_notification.id" :submit_method="submit_push_notification" :hide_save="push_notification.status === 'submitted'"></oe1-save-close>
      </form>

    </div>
    <div v-if="push_notification.id" class="col-md-3 sidebar" role="complementary">
      <oe1-memo :content_class="content_class" :content_id="push_notification.id"></oe1-memo>
      <oe1-changelog :content_class="content_class" :content_id="push_notification.id"></oe1-changelog>
    </div>

  </div>
</template>


<script>

export default {
  data: function () { return {
    push_notification: {
      id: '',
      status: '',
    },
    content_class: 'push_notification',
    };
  },
  watch: {
    '$route': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  methods: {
    fetchData: function () {
      var vm = this;
      if (vm.$route.params.push_notification_id) {
        vm.axios.get('/api/push_notification/' + vm.$route.params.push_notification_id)
          .then(function (response) {
            vm.push_notification = response.data;
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
    },
    submit_push_notification() {
      var vm = this;
      var saveUrl = vm.push_notification.id
                    ? '/api/push_notification/' + vm.push_notification.id
                    : '/api/push_notification';
      var promise = vm.axios.post(saveUrl, vm.push_notification);
      promise
        .then(function (response) {
          vm.push_notification = response.data;
          vm.$log('Push Notification wurde gespeichert');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    publishNotification() {
      var vm = this;
      var promise = vm.axios.post('/api/push_notification/' + vm.push_notification.id + '/publish');
      promise
        .then(function (response) {
          const rv = response.data;
          if (rv.status === 'error') {
             vm.$log(rv.message);
          }
          else {
            vm.$log('Push Notification wurde abgeschickt!');
            vm.push_notification = rv;
          }
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    deleteNotification() {
      var vm = this;
      var promise = vm.axios.delete('/api/push_notification/' + vm.push_notification.id + '/publish');
      promise
        .then(function (response) {
          const rv = response.data;
          if (rv.status === 'error') {
             vm.$log(rv.message);
          }
          else {
            vm.$log('Push Notification wurde gelöscht!');
            vm.push_notification = rv;
          }
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    }
  },
};
</script>
