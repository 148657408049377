module.exports = [
    { value: 'article',   label: 'Artikel' },
    { value: 'broadcast', label: 'Sendung' },
    { value: 'serial',    label: 'Sendereihe' },
    { value: 'author',    label: 'Autor' },
    { value: 'link',      label: 'Link' },
    { value: 'url',       label: 'URL' },
    { value: 'collection',       label: 'Collection' },
    { value: 'tag',       label: 'Tag' },
];
