<template>
    <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Tags</h3>
    </div>
    <div class="panel-body">
      <div class="form-inline">
        <oe1-autocomplete :datasource="'/api/autocomplete/tag?q='" v-on:selected="addTag" placeholder="Tag hinzufügen"></oe1-autocomplete>
      </div>
    </div>

    <div v-if="items.length">
      <draggable
        class="list-group"
        :list="items"
        :options="{handle:'.draggable-handle'}"
        element="ul"
        v-on:update="newOrder"
      >
        <li class="list-group-item" :class="row.status" v-for="row in items">
          <div class="row">
            <div class="col-md-5">{{ row.title }}</div>
            <div class="col-md-7 text-right">
              <span class="badge">{{ row.type }}</span>
              <span class="badge">{{ $root.getStatusLabel(row.status) }}</span>
              <span class="badge" v-if="row.is_programmschwerpunkt">Programm-SP {{ row.programmschwerpunkt_offline ? '('+row.programmschwerpunkt_offline+')':'' }}</span>

              <oe1-confirm-modal :callback="removeTagBuilder(row)"></oe1-confirm-modal>
              <span class="draggable-handle btn btn-default btn-xs" v-if="!locked">
                <span class="glyphicon glyphicon-sort" aria-hidden="true"></span>
              </span>
            </div>
          </div>
        </li>
      </draggable>
    </div>
    <div v-else class="panel-footer">
      Nicht getagged.
    </div>
  </div>
</template>

<script>
import ContentObject from '../mixins/ContentObject.js';

export default {
  name: "oe1-tags",
  mixins: [ ContentObject ],
  data: function() { return {
      items: [],
  } },
  components: {
    draggable: require('vuedraggable'),
  },
  props: {},
  created: function () {
    var vm = this;
    vm.axios.get('/api/tag/linked/'+vm.content_object_url)
      .then(function (response) {
        vm.items = response.data;
      })
      .catch(function (error) {
        vm.$log(error);
      });
  },
  computed: {
    locked: function(){ return this.$store.getters.locked },
    content_object_url: function() {
      var vm = this;
      return vm.content_class+'/'+vm.content_id;
    },
    showSearchResult: function() {
      var vm = this;
      return vm.searchResult.length > 0 ? true : false;
    },
  },
  methods: {
    removeTagBuilder: function(item) {
        return this.removeTag.bind(this,item);
    },
    removeTag: function (tag) {
      var vm = this;
      vm.axios.delete('/api/tag/' + tag.id + '/link/' + vm.content_object_url )
        .then(function (response) {
          vm.items = response.data;
          vm.$log('Tag "' + tag.title + '" wurde entfernt.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    newOrder: function() {
      var vm = this;
      vm.axios.put('/api/tag/linked/'+vm.content_object_url, vm.items)
        .then(function (response) {
          vm.$log('Tags wurden umsortiert.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    addTag: function(hit) {
      var vm = this;
      vm.axios.post('/api/tag/'+ hit.id +'/link/' + vm.content_object_url)
        .then(function (response) {
          vm.items = response.data;
          vm.$log('Tag "' + hit.title + '" wurde hinzugefügt.');
          vm.searchResult = [];
          vm.searchQuery='';
        })
        .catch(function (error) {
          vm.$log(error);
          vm.searchResult = [];
          vm.searchQuery='';
        });
    },
  }
};
</script>

