module.exports = [
    { value: 'enable_upload',   label: 'Upload möglich' },
    { value: 'disable_upload',    label: 'Upload gesperrt' },
    { value: 'show_submissions',    label: 'Einreichungen anzeigen' },
    { value: 'hide_submissions',   label: 'Einreichungen nicht anzeigen' },
    { value: 'enable_voting',   label: 'Voting geöffnet' },
    { value: 'disable_voting',   label: 'Voting gesperrt' },
    { value: 'show_votes',   label: 'Votes anzeigen' },
    { value: 'hide_votes',   label: 'Votes nicht anzeigen' },
];
