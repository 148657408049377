<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.success }}</td>
          <td>{{ $root.getListLabel(filterContentClasses,row.item.content_class) }}</td>
          <td>{{ row.item.content_id }}</td>
          <td>{{ $root.getListLabel('event_actions',row.item.action) }}</td>
          <td>{{ row.item.schedule }}</td>
          <td>{{ row.item.ran }}</td>
          <td>
            <span v-if="!row.item.ran" class="pull-right">
              <button type="button" class="btn btn-default btn-xs" v-on:click="editEvent(row.item)">
                <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
              </button>
              <oe1-confirm-modal :callback="deleteBuilder(row)"></oe1-confirm-modal>
            </span>
          </td>
        </template>
      </oe1-table>
    </div>

    <div slot="filter">
      <oe1-filter-content-object
        :show-status=false
        :show-title=false
        :show-page-id=false
        :show-tag=false
        :show-created-at=false
        :params="params"
        :status-options="statusOptions"
        v-on:oe1-update-params="updateParams"
      ></oe1-filter-content-object>

      <oe1-filter-element label="Status" param="type">
        <select class="form-control" v-model="params.type" v-on:change="updateParams">
          <option v-for="o in filterTypes" :value="o.value">{{o.label}}</option>
        </select>
      </oe1-filter-element>

      <oe1-filter-element label="Event" param="content_class">
        <select class="form-control" v-model="params.content_class" v-on:change="updateParams">
          <option></option>
          <option v-for="o in filterContentClasses" :value="o.value">{{o.label}}</option>
        </select>
      </oe1-filter-element>

      <oe1-filter-element label="ID" param="content_id">
        <input type="text" v-model="params.content_id" v-on:change="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Aktion" param="action">
        <select class="form-control" v-model="params.action" v-on:change="updateParams">>
          <option></option>
          <option :value="o.value" v-for="o in $store.state.config.event_actions">{{ o.label }}</option>
        </select>
      </oe1-filter-element>

    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'schedule',
      params: {
        content_class: '',
        content_id: '',
        type:'upcoming',
        action:'',
      },
      columns: [
        { label: "ok", width: "3%"},
        { label: "Ding", width: "5%"},
        { label: "id", width: "5%"},
        { label: "Aktion", width:"13%" },
        { label: "geplant", width:"10%" },
        { label: "ausgeführt", width:"10%" },
        { label: "", width: "5%" }
      ],
      filterTypes: [
        { label: "geplant", value:"upcoming" },
        { label: "vergangen", value:"past" },
        { label: "nicht erledigt", value:"pending" },
        { label: "erledigt", value:"done" },
        { label: "Fehler", value:"error" },
      ],
      filterContentClasses: [
        { label: "Artikel", value: "article" },
        { label: "Sendung", value: "broadcast" },
        { label: "Collection", value: "collection" },
        { label: "Widget", value: "widget" },
        { label: "Selection", value: "selection" },
      ],
    };
  },
  methods: {
    editEvent: function(item) {
      this.$router.push('/event/' + item.id);
    },
    deleteBuilder: function(row) {
        return this.deleteEvent.bind(this,row);
    },
    deleteEvent: function (row) {
      var vm = this;
      var delIndex = row.index;
      var delId = row.item.id;
      vm.axios.delete('/api/schedule/' + delId )
        .then(function (response) {
          vm.items.splice(row.index, 1)
          vm.$log('Event '+ delId +' gelöscht');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
  }
};
</script>
