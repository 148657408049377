<template>
  <table class="table table-striped table-list" style="table-layout: fixed; width:100%;">
    <thead>
      <tr>
        <th v-for="column in columns" :style="{ width: column.width }">{{ column.label }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item,item_index) in items" :class="item.status" >
        <slot :item="item" :index="item_index"></slot>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'oe1-table',
  props: {
    columns: { type: Array, required: true },
    items: { type: Array, required: true },
  },
  data: function () { return {} },
};
</script>
