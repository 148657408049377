<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.internal_name }}</td>
          <td>{{ row.item.internal_name }}</td>
          <td>
            <button type="button" class="pull-right btn btn-default btn-xs" v-on:click="editItem(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
          </td>
        </template>
      </oe1-table>
    </div>

    <div slot="filter">
      <oe1-filter-content-object
        :show-status=false
        :show-title=false
        :show-page-id=false
        :show-tag=false
        :params="params"
        v-on:oe1-update-params="updateParams"
        :status-options="statusOptions"
      ></oe1-filter-content-object>

      <oe1-filter-element label="Interner Name" param="internal_name">
        <input type="text" v-model="params.internal_name" v-on:change="updateParams">
      </oe1-filter-element>
    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'snippet',
      columns: [
        { label: "Interner Namen" },
        { label: "", width: "5%" }
      ]
    };
  },
};
</script>

