<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.id }}</td>
          <td>{{ $root.getListLabel('ressorts',row.item.ressort) }}</td>
          <td>{{ row.item.title }}</td>
          <td>{{ row.item.code }}</td>
          <td>{{ row.item.sendungsid }}</td>
          <td>{{ $root.getStatusLabel(row.item.status) }}</td>
          <td>
            <button type="button" class="pull-right btn btn-default btn-xs" v-on:click="editItem(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
            <oe1-image-modal :image_id="row.item.image"></oe1-image-modal>
          </td>
        </template>
      </oe1-table>
    </div>

    <template slot="actions">
      <router-link to="/serial/create" class="btn btn-info">Sendereihe anlegen</router-link>
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>

    <div slot="filter">
      <oe1-filter-content-object
        :show-created-at=false
        :show-tag=false
        :params="params"
        v-on:oe1-update-params="updateParams"
        :status-options="statusOptions"
      ></oe1-filter-content-object>

      <oe1-filter-element label="Ressort" param="ressort">
        <select type="text" v-model="params.ressort" v-on:change="updateParams">
          <option value=""></option>
          <option v-for="o in $store.state.config.ressorts" :value="o.value">{{ o.label }}</option>
        </select>
      </oe1-filter-element>
      <oe1-filter-element label="Code" param="code">
        <input type="text" v-model="params.code" v-on:change="updateParams">
      </oe1-filter-element>
      <oe1-filter-element label="Sendungsid" param="sendungsid">
        <input type="text" v-model="params.sendungsid" v-on:change="updateParams">
      </oe1-filter-element>
    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'serial',
      columns: [
        { label: "ID"      },
        { label: "Ressort" },
        { label: "Titel"   },
        { label: "Code"    },
        { label: "Sendungsid"    },
        { label: "Status"  },
        { label: "", width: "5%" },
      ]
    };
  },
};
</script>

