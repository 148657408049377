<template>
  <div>
    <div class="col-md-9" role="main">
      <form v-on:submit.prevent>
        <div class="form-group">
          <label for="inputUsername">Benutzername</label>
          <input v-model="inputUsername" type="text" class="form-control" id="inputUsername" placeholder="Benutzername" required autofocus>
        </div>
        <div class="form-group">
          <label for="inputFirstname">Vorname</label>
          <input v-model="inputFirstname" type="text" class="form-control" id="inputFirstname" placeholder="Vorname" required autofocus>
        </div>
        <div class="form-group">
          <label for="inputLastname">Nachname</label>
          <input v-model="inputLastname" type="text" class="form-control" id="inputLastname" placeholder="Nachname" required autofocus>
        </div>
        <div class="form-group">
          <label for="inputEmail">e-Mail</label>
          <input v-model="inputEmail" type="email" class="form-control" id="inputEmail" placeholder="e-Mail" required>
        </div>
        <div v-if="!user_id" class="form-group">
          <label for="inputPassword">Passwort</label>
          <input v-model="inputPassword" type="password" class="form-control" id="inputPassword" placeholder="Passwort" required>
        </div>
        <div class="form-group">
          <div class="checkbox">
            <label>
              <input type="checkbox" id="inputIsActive" v-model="inputIsActive"> active
            </label>
          </div>
        </div>
        <div class="form-group oe1-user-roles">
          <h4>Rollen</h4>
          <span v-for="role in roles">
            <label :class="{active: role.selected}">
              <input type="checkbox" v-model="role.selected"> {{ role.role }}
            </label>
          </span>
        </div>
        <div v-if="legacy_roles.length" class="form-group">
          <h4>Legacy-Rollen im alten CMS</h4>
          <p>
            <span v-for="role in legacy_roles">{{ role }} </span>
          </p>
        </div>

        <oe1-save-close :content_id="user_id" :submit_method="submit_user"></oe1-save-close>
      </form>

    </div>
    <div v-if="user_id" class="col-md-3 sidebar" role="complementary">
     <button type="button" class="btn btn-info" v-on:click="changePassword()">Passwort</button>
     <oe1-memo content_class="usr" :content_id="user_id"></oe1-memo>
     <oe1-changelog content_class="usr" :content_id="user_id"></oe1-changelog>
    </div>
  </div>
</template>

<script>

export default {
  data: function () { return {
      inputUsername: '',
      inputPassword: '',
      inputEmail: '',
      inputFirstname: '',
      inputLastname: '',
      inputIsActive: false,
      roles: [],
      legacy_roles:[],
    };
  },
  props: ['user_id'],
  watch: {
    '$route': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  methods: {
    fetchData: function () {
      var vm = this;
      if (vm.user_id) {
        vm.axios.get('/api/user/' + vm.user_id)
          .then(function (response) {
            vm.inputUsername = response.data.username;
            vm.inputEmail = response.data.email;
            vm.inputFirstname = response.data.firstname;
            vm.inputLastname = response.data.lastname;
            vm.inputIsActive = response.data.is_active;
            vm.roles = response.data.roles;
            vm.legacy_roles = response.data.legacy_roles;
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
      else {
        vm.axios.get('/api/user/available_roles')
          .then(function (response) {
            vm.roles = response.data;
          })
          .catch(function (error) {
            vm.$log(error);
          });
        }
    },
    'submit_user': function () {
      var vm = this;
      var save_url = vm.user_id
          ? '/api/user/' + vm.user_id
          : '/api/user';

      var data = {
        username: vm.inputUsername,
        password: vm.inputPassword,
        email: vm.inputEmail,
        firstname: vm.inputFirstname,
        lastname: vm.inputLastname,
        is_active: vm.inputIsActive,
        roles: vm.roles,
      };

      var promise = vm.axios.post(save_url, data);
      promise
            .then(function (response) {
              vm.$log('Benutzer wurde gespeichert.');
            })
            .catch(function (error) {
              vm.$log(error);
            });
      return promise;
    },
    changePassword: function() {
      var vm = this;
      this.$router.push('/user/'+  vm.user_id +'/password');
    }
  }
};
</script>

<style scoped>
.oe1-user-roles input[type=checkbox] {
  display: none;
  visibility: hidden;
}
.oe1-user-roles label {
  color: #555;
  font-weight: normal;
  padding: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin: 3px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.oe1-user-roles label.active {
  color: #468847;
  background-color: #dff0d8;
}
</style>

