<template>
<div>
  <div class="row">
    <div class="col-md-12">
      <h3>Download Statistik</h3>
      <form id="statform" method="post" action="/export/downloadaccountstats">
        <div class="form-group">

          <label class="radio-inline">
            <input type="radio" value="alle" v-model="form.searchtype"> Alle Sendungen
          </label>
          <label class="radio-inline">
            <input type="radio" v-model="form.searchtype" value="reihen"> Alle Sendereihen
          </label>
          <label class="radio-inline">
            <input type="radio" v-model="form.searchtype"  value="eine"> Eine Sendereihe:
            <select v-model="form.searchtype_in" @click="searchtypeSingle()">
              <option v-for="o in typeopts" :value="o.value">{{ o.label }}</option>
            </select>
            <input type="text" v-model="form.searchtype_text" size="15" @click="searchtypeSingle()">
          </label>
        </div>
        <div class="form-group">
          Sendedatum von
          <input type="text" v-model="form.broadcast_from" size="8" placeholder="YYYYMMDD">
          bis
          <input type="text"  v-model="form.broadcast_until" size="8" placeholder="YYYYMMDD">
        </div>
        <div class="form-group">
          Downloadzeitraum von
          <input type="text" v-model="form.download_from" size="8" placeholder="YYYYMMDD">
          bis
          <input type="text" v-model="form.download_until" size="8" placeholder="YYYYMMDD">
        </div>

        <div class="form-group">
          Filter DL:
          <label class="radio-inline"><input type="radio" v-model="form.status" value=""> Alle</label>
        <label class="radio-inline"><input type="radio" v-model="form.status" value="ok"> erfolgreich</label>
        <label class="radio-inline"><input type="radio" v-model="form.status" value="err"> Fehler</label>
        </div>

        <div class="form-group">
          Filter User:
          <label class="radio-inline"><input type="radio" v-model="form.user" value=""> Alle</label>
          <label class="radio-inline"><input type="radio" v-model="form.user" value="allg"> Allgemein</label>
          <label class="radio-inline"><input type="radio" v-model="form.user" value="1"> Ö1 Club</label>
          <label class="radio-inline"><input type="radio" v-model="form.user" value="2"> ö1 Uni-Card</label>
          <label class="radio-inline"><input type="radio" v-model="form.user" value="5"> MitarbeiterInnen</label>
          <label class="radio-inline"><input type="radio" v-model="form.user" value="9"> Schule</label>
        </div>

        <div class="btn-group" role="group">
          <div class="btn btn-default" @click="showStats">
            <span class="glyphicon glyphicon-search"></span>
            Anzeigen
          </div>
          <div class="btn btn-default" @click="exportStats">
            <span class="glyphicon glyphicon-export"></span>
            Exportieren
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row" v-if="stats">
    <div class="col-md-6">
      <h3>Ergebnis</h3>
      <table class="table table-condensed table-bordered" width="50%">
        <thead>
          <tr><th>Typ</th><th class="text-right">Anzahl</th><th class="text-right">Prozent</th></tr>
        </thead>
        <tbody>
          <tr v-for="s in stats" v-bind:class="{ heading: s.is_heading }">
            <td>{{ s.name }}</td><td class="text-right" v-if="!s.is_heading">{{s.value}}</td><td class="text-right" v-if="s.percent">{{s.percent}}%</td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>

export default {
  data: function() { return {
    form: {
      searchtype:'',
      searchtype_in:'archiv',
      searchtype_text:'',
      broadcast_from:'',
      broadcast_until:'',
      download_from:'',
      download_until:'',
      status:'',
      user:'',
    },
    typeopts:[
      {label:"Digas/Archiv-Kürzel",value:"archiv"},
      {label:"SendungsID",value:"sendungsid"},
      {label:"Einzeltitel",value:"title"},
    ],
    stats:[]
  }
  },
  methods: {
    'showStats': function() {
      var vm = this;
      var promise = vm.axios.post('/api/downloadaccountstats',vm.form);
      promise
        .then(function (response) {
          vm.stats = response.data;

        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    'exportStats': function() {
      var vm = this;
      var param = JSON.stringify(vm.form);
      window.open('/export/downloadaccountstats?search='+param);
    },
    'searchtypeSingle': function() {
      this.form.searchtype='eine';
    },
  }
};
</script>

<style scoped>
tr.heading {
  font-weight: bold;
}
</style>
