<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Events</h3>
    </div>
    <div class="panel-body">
      <button type="button" class="btn btn-info" v-on:click="createEvent" :disabled="locked">Event anlegen</button>
      <button type="button" class="btn btn-info" v-on:click="listEvents">Alle Events</button>
    </div>
    <oe1-table
      v-if="items.length"
      :columns="columns"
      :items="items">
      <template slot-scope="row">
        <td>
          <a :href="getPreviewlink(row.item)" v-if="hasPreviewlink">{{ row.item.schedule }}</a>
          <template v-else>{{ row.item.schedule }}</template></td>
        <td>{{ $root.getListLabel('event_actions',row.item.action) }}</td>
        <td>{{ row.item.args }}</td>
        <td>
          <button type="button" class="btn btn-default btn-xs" v-on:click="editItem(row.item)" :disabled="locked">
            <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
          </button>
          <oe1-confirm-modal :callback="deleteBuilder(row)"></oe1-confirm-modal>
        </td>
      </template>
    </oe1-table>
    <div v-else class="panel-footer">
      Keine Events geplant.
    </div>
  </div>
</template>

<script>
import Table from './Table.vue';

export default {
  name: "oe1-events",
  data: function () { return {
    items: [],
    columns: [
      { label: "geplant für"},
      { label: "Aktion"},
      { label: "Details" },
      { label: "", width: "6%" }
    ],
  }; },
  props: [
    'content_class',
    'content_id',
    'previewlink'
  ],
  components: {
    'oe1-table': Table,
  },
  created: function () {
    var vm = this;
    vm.axios.get('/api/schedule/link/'+vm.content_class + '/' + vm.content_id)
      .then(function (response) {
        vm.items = response.data.items;
      })
      .catch(function (error) {
        vm.$log(error);
      });
  },
  computed: {
    locked: function(){ return this.$store.getters.locked },
    hasPreviewlink: function() {
      return this.previewlink !== null
        && this.previewlink !== undefined
        && this.previewlink !== '';
    },
  },
  methods: {
    getPreviewlink: function(row) {
      var link = this.previewlink;

      if (link.indexOf('?')) {
        link = link + '%26';
      } else {
        link = link + '%3F';
      }
      link = link + 'timemachine=' + row.schedule;
      return link;
    },
    deleteBuilder: function(row) {
      return this.deleteEvent.bind(this,row);
    },
    deleteEvent: function (row) {
      var vm = this;
      var delIndex = row.index;
      var delId = row.item.id;
      vm.axios.delete('/api/schedule/' + delId )
        .then(function (response) {
          vm.items.splice(row.index, 1)
          vm.$log('Event '+ delId +' gelöscht');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    editItem: function (row) {
      var vm = this;
      vm.$router.push('/event/' + row.id);
    },
    createEvent: function () {
      var vm = this;
      vm.$router.push('/event/create?content_class=' +vm.content_class +'&content_id='+vm.content_id );
    },
    listEvents: function () {
      var vm = this;
      vm.$router.push('/event/list?content_class=' + vm.content_class +'&content_id='+vm.content_id);
    },
  }
};
</script>

