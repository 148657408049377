<template>
  <div>
    <input class="input" :class="inputClass" @input="search($event.target.value)" @keydown.down="moveDown" @keydown.up="moveUp" @keydown.enter="select" :placeholder="placeholder" v-model="searchQuery" v-on:blur="reset" :disabled="locked"></input>
    <div class="dropdown" :class="{open: showSearchResult}">
      <ul class="dropdown-menu" style="width:70%">
        <li v-for="(hit, index) in searchResult" :class="{
          'highlighted': index === searchHighlightedPos
        }"
        @mouseenter="searchHighlightedPos = index"
        @mousedown="select"
        >
        {{hit.value}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Oe1Autocomplete",
  data: function() { return {
      searchQuery: '',
      searchResult: [],
      searchHighlightedPos: 0,
  } },
  props: {
    datasource: {
      type: String,
      requried: true,
    },
    placeholder: {
      type: String,
      default: '...',
    },
    keep: {
      type: Boolean,
      default: false,
    },
    selectevent: {
      type: String,
      default: 'selected'
    },
    inputClass: {
      type: String,
      default: 'form-control'
    }
  },
  computed: {
    locked: function(){ return this.$store.getters.locked },
    showSearchResult: function() {
      var vm = this;
      return vm.searchResult.length > 0 ? true : false;
    },
  },
  methods: {
    search: function(query) {
      var vm = this;
      vm.searchHighlightedPos=0;
      if (query.length < 3) {
        vm.searchResult = [];
        if (this.keep && query.length === 0) {
          vm.$emit(vm.selectevent,undefined);
        }
        return
      }
      query = encodeURI(query);
      query = query.replace(/#/g, '%23');
      vm.axios.get(vm.datasource+query)
        .then(function (response) {
          vm.searchResult = response.data;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    select: function() {
      var vm = this;
      var hit = vm.searchResult[vm.searchHighlightedPos];
      vm.searchResult=[];
      vm.searchHighlightedPos = 0;
      if (vm.keep) {
        if (hit === undefined) {
          vm.searchQuery='';
        } else {
          vm.searchQuery=hit.title;
        }
      }
      vm.$emit(vm.selectevent,hit);
    },
    moveDown: function() {
      var vm = this;
      vm.searchHighlightedPos = (vm.searchHighlightedPos + 1) % vm.searchResult.length
    },
    moveUp: function() {
      var vm = this;
      vm.searchHighlightedPos = vm.searchHighlightedPos - 1 < 0
        ? vm.searchResult.length - 1
        : vm.searchHighlightedPos - 1
    },
    reset: function() {
      var vm = this;
      vm.searchResult=[];
      vm.searchHighlightedPos = 0;
      if (!vm.keep) {
        vm.searchQuery='';
      }
    }
  }
};
</script>

<style scoped>
li.highlighted {
  background: #faa
}
</style>
