module.exports = [
    { value: '',   label: 'Alle' },
    { value: 'download', label: 'Download' },
    { value: 'archiv', label: 'Archiv' },
    { value: 'club', label: 'Club' },
    { value: 'schule', label: 'Schule' },
    { value: 'talenteboerse', label: 'Talentebörse' },
    { value: 'hoerspiel', label: 'Hörspiel' },
    { value: 'programm', label: 'Programm' },
    { value: 'nachhaltigleben', label: 'Nachhaltig leben' },
];
