<template>
  <span>
    <button v-on:click="showChangeLog()" class="btn btn-info">Log</button>
    <oe1-aside title="Changelog" ref="aside" position="right">
      <div v-for="(item,item_index) in display_items" class="changelog-item">
        <div v-if="item.skipper" class="clearfix">
          <button type="button" v-on:click="display_all=true" class="pull-right btn btn-xs btn-default" style="margin-bottom:8px">
            <span class="glyphicon glyphicon-option-vertical" aria-hidden="true"></span>
          </button>
        </div>
        <div v-else>
          <div class="user">{{ item.user }}</div>
          <div class="change">
            <span class="what">{{ item.action }}</span>
            <span class="when pull-right">{{ item.created }}</span>
          </div>
          <div v-if='item.args' class="detail">
            {{ item.args }}
          </div>
        </div>
      </div>
    </oe1-aside>
  </span>
</template>

<style scoped>

div.changelog-item {
  border-bottom: 1px solid #d9edf7;
  margin: 8px 0px;
  padding-bottom: 2px;
}

div.change {
  margin-left: 2em;
  font-size: 0.9em;
}

div.detail {
  margin-left: 4em;
  font-size: 0.8em;
}

</style>

<script>
import aside from './Aside.vue'
import ContentObject from '../mixins/ContentObject.js'

export default {
  name: "oe1-changelog",
  mixins: [ ContentObject ],
  components: {
    'oe1-aside': aside
  },
  data: function() {
    return {
      items: [],
      display_all: false,
      num_logs: 3, // show num_logs log entries + the first entry
    };
  },
  computed: {
    display_items: function() {
      if (this.display_all || this.items.length <= this.num_logs) {
        return this.items;
      }
      else {
        var display_items = this.items.slice(0,this.num_logs);
        display_items.push({skipper:true});
        display_items.push(this.items.slice(-1)[0]);
        return display_items;
      }
    }
  },
  methods: {
    showChangeLog: function() {
      this.get_changelogs();
    },
    get_changelogs: function(event, type) {
      var vm = this;
      vm.axios.get('/api/changelog/'+this.content_class+'/'+this.content_id)
        .then(function (response) {
          vm.items = response.data.items
          vm.$refs.aside.showAside();
        })
        .catch(function (error) {
          vm.$log(error);
        });
    }
  }
};
</script>
