<template>
  <div>
    <h2>{{ bc.title }}</h2>
    <div>{{ bc.airdate }}</div>
    <div><b>Teaser</b><br>{{ bc.teaser }}</div>
    <div v-if="bc.is_journal">
      <b>Content wird nicht angezeigt, weil diese Sendung ein Journal ist</b><br><p style="text-decoration: line-through;">{{ bc.content }}</p>
    </div>
    <div v-else>
      <b>Content</b><br>{{ bc.content }}
    </div>
    <div><b>Service</b><br>{{ bc.service }}</div>
    <div><b>Playlist</b><br>{{ bc.playlist }}</div>
    <div v-if="bc.beitraege_radimeta"><b>Beiträge</b><br>{{ bc.beitraege_radimeta }}</div>

    <div class="btn btn-default" style="margin-top:22px" @click="close">
      <span class="glyphicon glyphicon-remove"></span>
      Schließen
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'bc' ],
  methods: {
    close: function() {
      var vm = this;
      vm.$router.push('/broadcast/list');
    }
  }
}
</script>

