<template>
  <div>
    <div class="col-md-9" role="main">
      <slot name="main"></slot>
    </div>
    <div class="col-md-3 sidebar" role="complementary">
      <div class="btn-group" role="group" title="Aktionen">
        <slot name="actions"></slot>
      </div>

      <div class="filter" title="Filter">
        <slot name="filter"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'oe1-list-page',
  props: {},
  data: function () { return {} },
};
</script>

