<template>
  <form v-on:submit.prevent>
    <div class="form-group">
      <label for="inputStatus">Status</label>
      <select class="form-control" id="inputStatus" required v-model="collection.status">
        <option v-for="o in $store.state.config.content_status_options" :value="o.value">{{ o.label }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="inputTitle">Titel</label>
      <input v-model="collection.title" type="text" class="form-control" id="inputTitle" placeholder="Titel" autofocus>
    </div>
    <div class="form-group">
      <label for="inputOewa">OEWA</label>
      <input v-model="collection.oewa" type="text" class="form-control" id="inputTitle" placeholder="RedCont/KulturUndFreizeit/KulturUeberblick/v1oe1" autofocus>
    </div>

    <div class="form-group">
      <label for="inputTemplate">Template</label>
      <select class="form-control" id="inputTemplate" required v-model="collection.template">
        <option v-for="o in template_options" :value="o.value">{{ o.label }}</option>
      </select>
    </div>
    <div v-if="collection.id">
      <b>URLs</b><br>
      <div v-if="collection.urls.length">
        <ul>
          <li v-for="url in collection.urls"><router-link :to="{ path: '/url/'  + url.id }">{{ url.url }}</router-link></li>
        </ul>
      </div>
      <div v-else>
        Keine URL zugewiesen.
      </div>
    </div>
    <oe1-save-close :content_id="collection.id" :submit_method="submit_collection"></oe1-save-close>
    <div v-if="collection.id" style="padding-top:20px">
      <oe1-confirm-modal :callback="cloneCollection" body="Soll diese Sammlung geklont werden?" buttonStyle="btn btn-danger" buttonText="Klonen"></oe1-confirm-modal>
      <oe1-confirm-modal :callback="flushCache" body="Sammlung aus Frontend-Cache löschen?" buttonStyle="btn btn-danger" buttonText="Flush Cache"></oe1-confirm-modal>
    </div>
  </form>
</template>

<script>
export default {
  data: function () { return {
      template_options: require('./config/collection_template_options.js'),
  }},
  props: ['collection'],
  methods: {
    'submit_collection': function () {
      var vm = this;
      var save_url = vm.collection.id
                     ? '/api/collection/' + vm.collection.id
                     : '/api/collection';

      var promise = vm.axios.post(save_url, {
        status: vm.collection.status,
        title: vm.collection.title,
        template: vm.collection.template,
        oewa: vm.collection.oewa,
      });
      promise
        .then(function (response) {
          vm.$log('Sammlung wurde gespeichert.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    cloneCollection: function() {
      var vm = this;
      vm.axios.post('/api/clone-collection/'+vm.collection.id)
        .then(function (response) {
          vm.$router.push('/collection/'+response.data.id );
          vm.$log('Sammlung wurde geklont.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    flushCache: function() {
      var vm = this;
      vm.axios.get('/api/flush-cache/collection/'+vm.collection.id)
        .then(function (response) {
          vm.$log('Sammlung wurde aus Frontend-Cache gelöscht.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
  }
};
</script>
