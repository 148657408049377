<template>
  <div>
    <div class="col-md-9" role="main">
      <ul class="nav nav-tabs" role="tablist">

        <li :class="{active: tab === 'imagedetails'}">
          <router-link :to="{path:'/image/'+image.id}">Foto</router-link>
        </li>
        <li v-if="image.id" :class="{active: tab === 'more'}">
          <router-link :to="{path:'/image/'+image.id+'/more'}">Tags, Events</router-link>
        </li>
      </ul>
      <component :is="currentView" :image="image" :content_class="content_class" :content_id="image_id"></component>
    </div>
    <div v-if="image.id" class="col-md-3 sidebar" role="complementary">
      <oe1-memo :content_class="content_class" :content_id="image.id"></oe1-memo>
      <oe1-changelog :content_class="content_class" :content_id="image.id"></oe1-changelog>
    </div>
  </div>
</template>

<script>

export default {
  data: function () { return {
      image: {
      },
      content_class: 'image',
    };
  },
  computed: {
    tab: function() { return this.$route.params.tab || 'imagedetails' },
    currentView: function() { return 'oe1-'+this.tab },
    image_id: function() { return parseInt(this.$route.params.image_id) }
  },
  watch: {
    'image_id': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  components: {
    'oe1-imagedetails': require('./ImageDetails.vue').default,
    'oe1-more': {
        props: ['image', 'content_class', 'content_id'],
        template: '<div>'
                + '  <oe1-tags :content_id="content_id" :content_class="content_class"></oe1-tags>'
                + '  <oe1-events :content_id="content_id" :content_class="content_class"></oe1-events>'
                + '</div>',
    }
  },
  methods: {
    fetchData: function() {
      var vm = this;
      if (vm.image_id) {
        vm.axios.get('/api/image/' + vm.image_id)
          .then(function (response) {
            vm.image = response.data;
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
    }
  }
};
</script>
