<template>
  <span>
    <button v-on:click="show_memo()" :class="buttonStyle">Memos: {{ hasMemos }}</button>
    <oe1-aside title="Memos" ref="aside">
      <div v-for="(item,item_index) in items" class="memo-item">
        {{ item.memo }}
        <div class="memo-info">
          Am <span class="date">{{ item.created }}</span> <span class="user">{{ item.usr.lastname }}, {{ item.usr.firstname }} ( {{ item.usr.username }} )</span>
        </div>
      </div>
      <div class="memo-form">
        <textarea v-model="memo" class="form-control" placeholder="Neues Memo"></textarea>
        <button v-on:click="add_memo()" class="btn btn-info">Memo speichern</button>
      </div>
    </oe1-aside>
  </span>
</template>

<style scoped>

.memo-item {
  border-bottom: 1px solid #337ab7;
  margin: 8px 0px;
}

.memo-info {
  font-style: italic;
  font-size: 0.9em;
}

.memo-form {
  margin-top: 5px;
}

</style>

<script>
import aside from './Aside.vue'
import ContentObject from '../mixins/ContentObject.js'

export default {
  name: "oe1-memo",
  mixins: [ ContentObject ],
  data: function() {
    return {
      memo: '',
      hasMemos: 0,
      items: []
    };
  },
  computed: {
    buttonStyle: function() {
      if (this.hasMemos > 0) {
        return "btn btn-danger";
      }
      else {
        return "btn btn-info";
      }
    }
  },
  components: {
    'oe1-aside': aside
  },
  created: function(){
    var vm = this;
    vm.axios.get('/api/has-memo/'+this.content_class+'/'+this.content_id)
      .then(function (response) {
        vm.hasMemos = response.data.memos;
      })
      .catch(function (error) {
        vm.$log(error);
      });
  },
  methods: {
    show_memo: function() {
      this.get_memos();
    },
    add_memo: function() {
      var vm = this;
      if (vm.memo == '')
        return;
      vm.axios.post('/api/memo/'+this.content_class+'/'+this.content_id,{ memo: vm.memo })
        .then(function (response) {
          vm.memo = '';
          vm.get_memos();
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    get_memos: function(event, type) {
      var vm = this;
      vm.axios.get('/api/memo/'+this.content_class+'/'+this.content_id)
        .then(function (response) {
          vm.items = response.data.items;
          vm.hasMemos = vm.items.length;
          vm.$refs.aside.showAside();
        })
        .catch(function (error) {
          vm.$log(error);
        });
    }
  }
};
</script>

<style scoped>
.memo-form button {
  margin-top: 3px;
  width: 100%;
}
</style>
