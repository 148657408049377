<template>
  <div>
    <div class="col-md-9" role="main">
      <ul class="nav nav-tabs" role="tablist">
        <li :class="{active: tab === 'ugcsubmissiondetails'}">
          <router-link :to="{path:'/ugcsubmission/'+ugcsubmission.uuid}">UGC</router-link>
        </li>
      </ul>
      <component :is="currentView" :ugcsubmission="ugcsubmission" :content_class="content_class" :content_id="ugcsubmission.id" :previewlink="ugcsubmission.previewlink"></component>

      <div style="padding:1em"></div>
      <oe1-relatedcontent v-if="ugcsubmission.id" content_class="ugc_submission" :content_id="ugcsubmission.id"></oe1-relatedcontent>

    </div>

    <div class="col-md-3 sidebar" role="complementary">
      <span><a :href="ugcsubmission.previewlink" type="button" class="btn btn-info" target="_new">Vorschau</a></span>

      <div v-if="ugcsubmission.id">
        <oe1-memo :content_class="content_class" :content_id="ugcsubmission.id"></oe1-memo>
        <oe1-changelog :content_class="content_class" :content_id="ugcsubmission.id"></oe1-changelog>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  data: function () { return {
      ugcsubmission: {
        id:'',
        data:{},
        audio:{}
      },
      content_class: 'ugc_submission',
    };
  },
  computed: {
    tab: function() { return this.$route.params.tab || 'ugcsubmissiondetails' },
    currentView: function() { return 'oe1-'+this.tab },
    ugcsubmission_id: function() { this.ugcsubmission.id },
  },
  watch: {
    'ugcsubmission_id': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  components: {
    'oe1-ugcsubmissiondetails': require('./UgcSubmissionDetails.vue').default,
  },
  methods: {
    fetchData: function () {
      var vm = this;
      if (vm.$route.params.ugcsubmission_id) {
        vm.axios.get('/api/ugcsubmission/' + vm.$route.params.ugcsubmission_id)
          .then(function (response) {
            vm.ugcsubmission = response.data;
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
    },
  },
};
</script>
