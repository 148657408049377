<script>

import selectionForm from './CollectionSelectionForm.html';

export default {
  data: function () { return {
      selection: {
        args: {tag:'',tag_display:''},
        file: {
          file:'',
          filename:'',
        },
        image: {
          src: '',
        },
      },
      image_just_deleted: false,
      type_immutable: true,
    };
  },
  template: '<div>' + selectionForm + '</div>',
  props: ['selection_id'],
  watch: {
    '$route': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  computed: {
    currentType: function() {
      var vm = this;
      return vm.selection.type;
    },
    showStuff: function(){
      var vm = this;
      var currentType = vm.selection.type;
      if (!currentType) { return {
        rows: false,
        continue: false
      } };
      var opts = vm.$store.state.config.selection_types;

      for(var i=0; i<opts.length; i++) {
        if (opts[i].value == currentType) return opts[i].show;
      }
      return {
        rows: false,
        continue: false
      } ;
    },
  },
  methods: {
    fetchData: function () {
      var vm = this;
      vm.axios.get('/api/selection/' + vm.$route.params.selection_id)
        .then(function (response) {
          vm.selection = response.data;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    'selectTag': function (hit) {
      var vm = this;
      console.log("in SEL TAG", hit);
      vm.selection.args.tag = hit.id;
      vm.selection.args.tag_display = hit.value;
    },
    'submit_selection': function () {
      var vm = this;
      var promise = vm.axios.post('/api/selection/' + vm.$route.params.selection_id, vm.selection);
      promise
        .then(function (response) {
          vm.$log('Selection wurde gespeichert');
          vm.image_just_deleted = false;
          vm.selection = response.data;
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    onFileChange: function(e) {
      var vm = this;
      vm.inputFile = '';
      var files = e.target.files;
      if (!files.length) { return; }
      var file = files[0];
      var image = new Image();
      var reader = new FileReader();
      reader.onload = function(event) {
        vm.selection.file.file = event.target.result;
        vm.selection.file.filename = file.name;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function() {
      var vm = this;
      vm.selection.image = { src: ''};
      vm.selection.args.image = null;
      vm.image_just_deleted = true;
    }
  }
};
</script>
