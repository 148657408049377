<template>
  <span>
    <button v-if="image_id" type="button" class="btn btn-xs btn-default" @click="showImage()">
      <slot name="button">
        <span class="glyphicon glyphicon-camera" aria-hidden="true" @mouseover="showImage()" @mouseleave="closeImage()"></span>
      </slot>
    </button>
    <div v-if="showImageModal" :class="display_class" style="display:block" @click="closeImage()">
      <div role="document" class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              {{ image.alt }}
            </h4>
          </div>
          <div class="modal-body">
            <slot name="body">
              <img :src="image.src" :alt="image.alt" :title="image.copyright">
              <br>{{ image.copyright }}
            </slot>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<style>

.preview-list-image {
    position: relative;
    top: -40px;
    left: -620px;
    z-index: 99999;
}
.preview-paragraph-image {
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 99999;
}

</style>

<script>

export default {
  name: 'oe1-image-modal',
  props: {
    image_id: Number,
    display_class: {
      type: String,
      default: 'preview-list-image',
    }
  },
  data: function () { return {
    showImageModal: false,
    image:{},
  } },
  methods: {
    closeImage: function() {
      this.showImageModal = false;
    },
    showImage: function() {
      var vm = this;
      vm.axios
        .get('/api/image/'+vm.image_id )
        .then(function (response) {
          vm.image = response.data;
          vm.showImageModal = true;
          setTimeout( function() {
            vm.closeImage();
          }, 15000);
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
  }
};
</script>
