<template>
  <div class="row">
    <div class="col-md-5">
      <h3>Anzahl Download User</h3>
      <table class="table table-condensed table-bordered" width="50%">
        <thead>
          <tr><th>Typ</th><th align="right">Anzahl</th></tr>
        </thead>
        <tbody>
          <tr v-for="s in stats">
            <td>{{ s.label }}</td><td align="right">{{s.count}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  data: function() { return {
    stats: []
  }
  },
  created: function () {
    var vm = this;
    vm.axios.get('/api/downloadaccountcount')
    .then(function (response) {
      vm.stats = response.data;
    })
    .catch(function (error) {
      vm.$log(error);
    });
  },
};
</script>

