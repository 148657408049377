<template>
  <div class="navbar-text navbar-right" style="margin-right:1em" v-if="logged_in">
    <button class="btn btn-default btn-xs" v-on:click="profile">{{ username }}</button>
    <button class="btn btn-default btn-xs" v-on:click="logout">Logout</button>
    <button class="btn btn-default btn-xs" v-on:click="weblogin">Web-Login</button>

  </div>
  <div v-else style="margin-right:1em">
    <form class="navbar-form navbar-right" v-on:submit.prevent="login">
      <div class="form-group" style="margin-right:.5em">
        <input v-model="inputUsername" size="10" type="text" class="form-control" id="inputUsername" placeholder="Benutzername" required autofocus>
      </div>
      <div class="form-group" style="margin-right:.5em">
        <input v-model="inputPassword" size="10" type="password" class="form-control" id="inputPassword" placeholder="Passwort" required>
      </div>
      <button type="submit" class="btn btn-default">Login</button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: function () {
    return {
      inputUsername: '',
      inputPassword: ''
    }
  },
  computed: mapState(['logged_in', 'username']),
  created: function () {
        var vm = this;
        vm.axios.get('/status')
          .then(function (response) {
            vm.update_auth(response.data.logged_in, response.data.username);
          })
          .catch(function (error) {
            vm.$log(error);
          });
  },
  methods: {
    update_auth: function(logged_in, username) {
      this.$store.commit('update_auth', {
        logged_in: logged_in,
        username: username
      });
    },
    login: function () {
        var vm = this;
        vm.axios.post('/login', {
            username: vm.inputUsername,
            password: vm.inputPassword
          })
          .then(function (response) {
            vm.inputUsername = '';
            vm.update_auth(response.data.logged_in, response.data.username);
            vm.$log('Angemeldet als ' + response.data.username);
          })
          .catch(function (error) {
                          vm.$log(error);
                        });
        vm.inputPassword = '';
        return false;
    },
    logout: function () {
        var vm = this;
        vm.axios.post('/logout')
          .then(function (response) {
            vm.update_auth(response.data.logged_in, response.data.username);
            vm.$log('Erfolgreich abgemeldet.');
          })
          .catch(function (error) {
                          vm.$log(error);
                        });
        return false;
    },
    weblogin: function () {
      window.open('/login/web', '_blank');
    },
    profile: function () {
      this.$router.push('/my/password');
    },

  }
};
</script>
