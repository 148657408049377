<template>
  <span>
    <oe1-filter-element v-if="showStatus" label="Status" param="status">
      <select v-model="params.status" v-on:change="updateParams">
        <option value="all">Alle</option>
        <option v-for="o in statusOptions" :value="o.value">{{ o.label }}</option>
      </select>
    </oe1-filter-element>
    <oe1-filter-element v-if="showTitle" label="Titel" param="title">
      <input type="text" v-model="params.title" v-on:keyup.enter="updateParams" v-on:change="updateParams">
    </oe1-filter-element>
    <oe1-filter-element v-if="showTag" label="Tag" param="id" divClass="'nope'">
      <oe1-autocomplete :datasource="'/api/autocomplete/tag?q='" v-on:selected="selectTag" placeholder="Tag" :keep="true"></oe1-autocomplete>
    </oe1-filter-element>
    <oe1-filter-element v-if="showPageId" label="PageID" param="id">
      <input type="text" v-model="params.id" v-on:keyup.enter="updateParams" v-on:change="updateParams">
    </oe1-filter-element>
    <oe1-filter-element v-if="showCreatedAt" label="Erstellt" param="created_at">
      <div class="input-group">
        <flat-pickr v-model="params.created_at" :config="fpOpts" class="form-control" @on-change="updateCreatedAt" />
        <div v-on:click="clearCreatedAt" class="input-group-addon"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></div>
      </div>
    </oe1-filter-element>

    <oe1-filter-element v-if="showAirdate" label="Airdate" param="airdate">
      <div class="input-group">
        <flat-pickr v-model="params.airdate" :config="fpOpts" class="form-control" @on-change="updateAirdate" />
        <div v-on:click="clearAirdate" class="input-group-addon"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></div>
      </div>
    </oe1-filter-element>

  </span>
</template>

<script>

import FilterElement from './FilterElement.vue';

export default {
  name: 'oe1-filter-content-object',
  props: {
    statusOptions: {
      type: Array,
      default: function() {
        return this.$store.state.config.content_status_options;
      }
    },
    params: {
      type: Object,
      default: true
    },
    showTag: {
      type: Boolean,
      default: true
    },
    showStatus: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showPageId: {
      type: Boolean,
      default: true
    },
    showCreatedAt: {
      type: Boolean,
      default: false
    },
    showAirdate: {
      type: Boolean,
      default: false
    }
  },
  data: function () { return {
    fpOpts: Object.assign(
      {
        onChange: this.updateParams,
      },
      this.$store.state.config.date_options
    ),
  } },
  components: {
    'oe1-filter-element': FilterElement,
  },
  methods: {
    selectTag: function(hit) {
      if (hit === undefined) {
        delete this.params.tag;
      } else {
        this.params.tag = hit.id;
      }
      this.updateParams();
    },
    updateCreatedAt: function (sel,str) {
      this.params.created_at = str;
      this.$emit('oe1-update-params');
    },
    updateAirdate: function (sel,str) {
      this.params.airdate = str;
      this.$emit('oe1-update-params');
    },
    updateParams: function () {
      this.$emit('oe1-update-params');
    },
    clearCreatedAt: function () {
      this.updateCreatedAt(null, '');
    },
    clearAirdate: function () {
      this.updateAirdate(null, '');
    },
  }
};
</script>
