<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.uuid }}</td>
          <td>{{ row.item.created }}</td>
          <td v-if='row.item.is_submitted'>{{ $root.getStatusLabel(row.item.status) }}</td>
          <td v-else>Nicht eingereicht</td>
          <td><router-link :to="{ path: '/ugc/' + row.item.ugc_id }">{{ row.item.ugc_id }}:{{ row.item.ugc_title }}</router-link></td>
          <td>{{ row.item.type }} <span v-if="row.item.category"><br/>{{ row.item.category }}</span></td>
          <td>{{ row.item.title }}</td>
          <td>
            <button type="button" class="btn btn-default btn-xs" v-on:click="editLink(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
            <a :href="row.item.previewlink" type="button" class="btn btn-default btn-xs" target="_new" title="zeige UGC in neuem Fenster."><span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a>
          </td>
        </template>
      </oe1-table>
    </div>

    <div slot="filter">
      <oe1-filter-content-object
        :show-page-id=false
        :show-tag=false
        :show-title=false
        :params="params"
        v-on:oe1-update-params="updateParams"
        :status-options="statusOptions"
      ></oe1-filter-content-object>

      <oe1-filter-element label="Beitrag UUID" param="uuid">
       <input type="text" v-model="params.uuid" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Beitrag Titel" param="title">
       <input type="text" v-model="params.title" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Email" param="email">
       <input type="text" v-model="params.email" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="UGC-ID" param="ugc_id">
       <input type="text" v-model="params.ugc_id" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'ugcsubmission',
      params: { },
      columns: [
        { label: "UUID",},
        { label: "Angelegt",},
        { label: "Status",},
        { label: "UGC",},
        { label: "Type",},
        { label: "Titel",},
        { label: "", width: "5%" }
      ],
    };
  },
  methods: {
    editLink: function(item) {
      var vm = this;
      vm.$router.push('/ugcsubmission/' + item.uuid);
    },
  }
};
</script>
