<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.kundennummer }}</td>
          <td>{{ row.item.userid }}</td>
          <td>{{ row.item.vorname }} {{ row.item.nachname }} {{ row.item.email}}</td>
          <td>{{ row.item.may_download }}</td>
          <td>
            <button type="button" class="pull-right btn btn-default btn-xs" v-on:click="editItem(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
          </td>
        </template>
      </oe1-table>
    </div>
    <template slot="actions">
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>

    <div slot="filter">
      <oe1-filter-element label="Userid" param="userid">
        <input type="text" v-model="params.userid" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Kundennummer" param="kundennummer">
        <input type="text" v-model="params.kundennummer" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="SAP Bestellnummer" param="sap_auftragsnummer">
        <input type="text" v-model="params.sap_auftragsnummer" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="E-Mail" param="email">
        <input type="text" v-model="params.email" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Name" param="name">
        <input type="text" v-model="params.name" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Berechtigt" param="may_download">
        <select type="text" v-model="params.may_download" v-on:change="updateParams">
          <option value=""></option>
          <option v-for="o in $store.state.config.download_account_type" :value="o.value">{{ o.label }}</option>
        </select>
      </oe1-filter-element>
    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'downloadaccount',
      columns: [
        { label: "Kundennummer" },
        { label: "Userid" },
        { label: "Name" },
        { label: "berechtigt" },
        { label: "", width: "5%" }
      ],
      availableRoles: []
    };
  },
  components: {},
  methods: {},
};
</script>

