<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td :class="row.item.status">{{ row.item.bc_title }}</td>
          <td :class="row.item.status">{{ row.item.bc_airdate }}</td>
          <td :class="row.item.status">{{ row.item.title }}</td>
          <td :class="row.item.status">{{ row.item.subtitle }}</td>
          <td align="center" :class="row.item.status">
            <button v-if="!row.item.is_aufmacher" type="button" class="btn btn-default" v-on:click="toggleHide(row.item)">
              <span class="glyphicon" :class="row.item.hide_icon" aria-hidden="true"></span>
            </button>
          </td>
          <td align="center" :class="row.item.status">
            <button  v-if="!row.item.hide" type="button" class="btn btn-default" v-on:click="toggleAufmacher(row.item)">
              <span class="glyphicon" :class="row.item.aufmacher_icon"  aria-hidden="true"></span>
            </button>
          </td>
        </template>
      </oe1-table>
    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'journalelement',
      columns: [
        { label: "Journal" },
        { label: "Datum" },
        { label: "Titel" },
        { label: "Untertitel" },
        { label: "verstecken" },
        { label: "Aufmacher" },
      ],
      availableRoles: []
    };
  },
  components: {},
  methods: {
    toggleHide: function(item) {
      var vm = this;
      vm.axios.post('/api/journalelement/toggle/'+item.id+'/hide')
        .then(function (response) {
          vm.items = response.data;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    toggleAufmacher: function(item) {
      var vm = this;
      vm.axios.post('/api/journalelement/toggle/'+item.id+'/aufmacher')
        .then(function (response) {
          vm.items = response.data;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
  },
};
</script>

<style scoped>

.versteckt {
  background-color: #ccc;
}

.aufmacher {
  background-color:#d55;
}

</style>


