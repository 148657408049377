<template>
  <div>

    <div class="col-md-9" role="main">
      <ul class="nav nav-tabs" role="tablist">
        <li :class="{active: tab === 'linkdetails'}">
          <router-link :to="{path:'/link/'+link.id}">Artikel</router-link>
        </li>
        <li v-if="link.id" :class="{active: tab === 'listimage'}">
          <router-link :to="{path:'/link/'+link.id+'/listimage'}">Listenbild</router-link>
        </li>
        <li v-if="link.id" :class="{active: tab === 'more'}">
          <router-link :to="{path:'/link/'+link.id+'/more'}">Tags, Events</router-link>
        </li>
      </ul>
      <component :is="currentView" :link="link" :content_class="content_class" :content_id="link_id"></component>
    </div>

    <div v-if="link.id" class="col-md-3 sidebar" role="complementary">
      <oe1-memo :content_class="content_class" :content_id="link.id"></oe1-memo>
      <oe1-changelog :content_class="content_class" :content_id="link.id"></oe1-changelog>
    </div>

  </div>
</template>

<script>
import LinkDetails from './LinkDetails.vue';

export default {
  data: function () { return {
    link: {
      status: 'draft',
      content_object: {
        id: ''
      },
    },
    content_class: 'link',
    saveAndClose: false
  }},
  computed: {
    tab: function() { return this.$route.params.tab || 'linkdetails' },
    currentView: function() { return 'oe1-'+this.tab },
    link_id: function() { return parseInt(this.$route.params.link_id) }
  },
  watch: {
    'link_id': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  components: {
    'oe1-linkdetails': LinkDetails,
    'oe1-more': {
        props: ['link', 'content_class', 'content_id', 'previewlink'],
        template: '<div>'
                + '  <oe1-tags :content_id="content_id" :content_class="content_class"></oe1-tags>'
                + '  <oe1-events :content_id="content_id" :content_class="content_class" :previewlink="previewlink"></oe1-events>'
                + '</div>',
    }
  },
  methods: {
    fetchData: function () {
      var vm = this;
      if (vm.link_id) {
        vm.axios.get('/api/link/' + vm.link_id)
          .then(function (response) {
            vm.link = response.data;
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
    },
  },
};
</script>
