<template>
  <div>
    <div class="col-md-9" role="main">
      <ul class="nav nav-tabs" role="tablist">
        <li :class="{active: tab === 'ugcdetails'}">
          <router-link :to="{path:'/ugc/'+ugc.id}">UGC</router-link>
        </li>
        <li v-if="ugc.id" :class="{active: tab === 'more'}">
          <router-link :to="{path:'/ugc/'+ugc.id+'/more'}">Events</router-link>
        </li>
      </ul>
      <component :is="currentView" :ugc="ugc" :content_class="content_class" :content_id="ugc_id" :previewlink="ugc.previewlink"></component>
    </div>

    <div v-if="ugc.id" class="col-md-3 sidebar" role="complementary">
      <span><a :href="ugc.previewlink" type="button" class="btn btn-info" target="_new">Vorschau</a></span>
      <span><router-link :to="{ path: '/ugcsubmission/list?ugc_id=' + ugc.id}" class="btn btn-info">Beiträge</router-link></span>

      <oe1-memo :content_class="content_class" :content_id="ugc.id"></oe1-memo>
      <oe1-changelog :content_class="content_class" :content_id="ugc.id"></oe1-changelog>
    </div>

  </div>
</template>


<script>

export default {
  data: function () { return {
      ugc: {
        id: '',
        status: 'draft',
        categories: [],
      },
      content_class: 'ugc',
    };
  },
  computed: {
    tab: function() { return this.$route.params.tab || 'ugcdetails' },
    currentView: function() { return 'oe1-'+this.tab },
    ugc_id: function() { return parseInt(this.$route.params.ugc_id) }
  },
  watch: {
    'ugc_id': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  components: {
    'oe1-ugcdetails': require('./UgcDetails.vue').default,
    'oe1-more': {
        props: ['ugc', 'content_class', 'content_id', 'previewlink'],
        template: '<oe1-events :content_id="content_id" :content_class="content_class" :previewlink="previewlink"></oe1-events>',
    }
  },
  methods: {
    fetchData: function () {
      var vm = this;
      if (vm.$route.params.ugc_id) {
        vm.axios.get('/api/ugc/' + vm.$route.params.ugc_id)
          .then(function (response) {
            vm.ugc = response.data;
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
    },
  },
};
</script>
