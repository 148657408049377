<template>
  <div
    v-if="display"
    :class="'aside slideleft-transition ' + position"
    :style="{ width: width + 'px' }">
    <div class="aside-dialog">
      <div class="aside-content">
        <div class="aside-header">
          <button type="button" class="close" v-on:click="hideAside()">
            <span>×</span>
          </button>
          <h4 class="aside-title">{{ title }}</h4>
        </div>
        <div class="aside-body">
          <slot>Empty</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.aside .aside-dialog .aside-header {
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.43px;
  padding: 6px 15px;
  background: #337ab7;
  color: #fff;
}

.aside .aside-dialog .aside-body {
    position: relative;
    padding: 15px;
}

.aside.left {
  right: auto;
  left: 0;
  border-right: 1px solid rgba(0,0,0,0.25);
}

.aside.right {
  right: 0;
  left: auto;
  border-left: 1px solid rgba(0,0,0,0.25);
}

.aside {
  position: fixed;
  top: 15px;
  bottom: 0;
  z-index: 1049;
  overflow: auto;
  background: #eee;
}
</style>

<script>

export default {
  name: 'oe1-aside',
  props: {
    title: {
      type: String,
      required: true
    },
    position: {
      default: 'left',
      validator: function (value) {
        return value === 'left' || value === 'right';
      }
    },
    width: {
      type: Number,
      default: 350
    }
  },
  data: function () { return {
    display: false
  } },
  components: {},
  methods: {
    showAside: function() {
      this.display = true;
    },
    hideAside: function() {
      this.display = false;
    },
  }
};
</script>
