<template>
  <div>
    <div class="col-md-9" role="main">

      <ul class="nav nav-tabs" role="tablist">
        <li :class="{active: tab === 'collectiondetails'}">
          <router-link :to="{path:'/collection/'+collection_id}">Sammlung</router-link>
        </li>
        <li v-if="collection_id" :class="{active: tab === 'collection-widgets'}">
          <router-link :to="{path:'/collection/'+collection_id+'/collection-widgets'}">Widgets</router-link>
        </li>
        <li v-if="collection_id" :class="{active: tab === 'more'}">
          <router-link :to="{path:'/collection/'+collection_id+'/more'}">Tags, Events</router-link>
        </li>
      </ul>
      <component :is="currentView" :collection="collection" :content_class="content_class" :content_id="collection_id"></component>

    </div>
    <div v-if="collection_id" class="col-md-3 sidebar" role="complementary">
      <oe1-previewlink :link="collection.previewlink"></oe1-previewlink>
      <oe1-memo :content_class="content_class" :content_id="collection_id"></oe1-memo>
      <oe1-changelog :content_class="content_class" :content_id="collection_id"></oe1-changelog>
    </div>

  </div>
</template>

<script>
import Widgets from './CollectionWidgets.vue';

export default {
  data: function () { return {
      content_class: 'collection',
      collection: {
        id: '',
        title: '',
        status: '',
        template: '',
        oewa:'',
        urls: [],
        previewlink: '',
        show_more: '',
        widgets: {
          left: [],
          right: [],
          allow_add_widgets_to_left: 1
        },
      },
    };
  },
  computed: {
    tab: function() { return this.$route.params.tab || 'collectiondetails' },
    currentView: function() { return 'oe1-'+this.tab },
    collection_id: function() { return parseInt(this.$route.params.collection_id) }
  },
  created: function () {
    this.fetchData()
  },
  watch: {
    'collection_id': 'fetchData'
  },
  components: {
    'oe1-collectiondetails': require('./CollectionDetails.vue').default,
    'oe1-collection-widgets': {
        props: ['collection'],
        components: { 'oe1-widgets': Widgets },
        template: '<div>'
                + '  <h2>Widgets</h2>'
                + '  <div class="widgetbox">'
                + '    <oe1-widgets area="left" :widgets="collection.widgets.left" :collection="collection" :allow_add_widgets="1" />'
                + '  </div>'
                + '  <div class="widgetbox">'
                + '    <oe1-widgets area="right" :widgets="collection.widgets.right" :collection="collection"  :allow_add_widgets="1"/>'
                + '  </div>'
                + '</div>',
     },
    'oe1-more': {
        props: ['collection', 'content_class', 'content_id'],
        template: '<div>'
                + '  <oe1-tags :content_id="content_id" :content_class="content_class"></oe1-tags>'
                + '  <oe1-events :content_id="content_id" :content_class="content_class" :previewlink="collection.previewlink"></oe1-events>'
                + '</div>',
    }
  },
  methods: {
    fetchData: function() {
      var vm = this;
      if (!vm.collection_id) { return; }
      vm.axios.get('/api/collection/' + vm.collection_id)
        .then(function (response) {
          vm.collection = response.data;
        })
      .catch(function (error) {
        vm.$log(error);
      });
    },
  }
};
</script>

<style>

div.widgetbox {
  border: 3px solid #555;
  margin-top:10px;
  padding:10px;
  background: #eee;
}

</style>

