import Vue              from 'vue';
import VueRouter        from 'vue-router';
import UserList         from './UserList.vue';
import UserEdit         from './UserEdit.vue';
import UserPwd          from './UserPwd.vue';
import UserSelfPwd      from './UserSelfPwd.vue';
import ArticleList      from './ArticleList.vue';
import ArticleEdit      from './ArticleEdit.vue';
import BroadcastList    from './BroadcastList.vue';
import BroadcastEdit    from './BroadcastEdit.vue';
import ParagraphEdit    from './ParagraphEdit.vue';
import CollectionList   from './CollectionList.vue';
import CollectionEdit   from './CollectionEdit.vue';
import CollectionWidgetEdit    from './CollectionWidgetEdit.vue';
import CollectionSelectionEdit from './CollectionSelectionEdit.vue';
import CollectionSelectionAdd  from './CollectionSelectionAdd.vue';
import SerialList       from './SerialList.vue';
import SerialEdit       from './SerialEdit.vue';
import SnippetList      from './SnippetList.vue';
import SnippetEdit      from './SnippetEdit.vue';
import EventList        from './EventList.vue';
import EventEdit        from './EventEdit.vue';
import TagList          from './TagList.vue';
import TagEdit          from './TagEdit.vue';
import LinkList         from './LinkList.vue';
import LinkEdit         from './LinkEdit.vue';
import UrlList          from './UrlList.vue';
import UrlEdit          from './UrlEdit.vue';
import AuthorList       from './AuthorList.vue';
import AuthorEdit       from './AuthorEdit.vue';
import UgcList          from './UgcList.vue';
import UgcEdit          from './UgcEdit.vue';
import UgcSubmissionList from './UgcSubmissionList.vue';
import UgcSubmissionEdit from './UgcSubmissionEdit.vue';
import Journalelements   from './Journalelements.vue';
import DownloadAccountList   from './DownloadAccountList.vue';
import DownloadAccountEdit   from './DownloadAccountEdit.vue';
import DownloadAccountCount   from './DownloadAccountCount.vue';
import DownloadAccountStats   from './DownloadAccountStats.vue';
import PushNotificationList          from './PushNotificationList.vue';
import PushNotificationEdit          from './PushNotificationEdit.vue';
import ImageList          from './ImageList.vue';
import ImageEdit          from './ImageEdit.vue';
Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    { name: 'user.list',         path: '/user/list', component: UserList },
    { name: 'user.create',       path: '/user/create', component: UserEdit },
    { name: 'user.edit',         path: '/user/:user_id', component: UserEdit, props: function(route) {return {user_id: parseInt(route.params.user_id)}} },
    { name: 'user.password',     path: '/user/:user_id/password', component: UserPwd },
    { name: 'user.selfpassword', path: '/my/password', component: UserSelfPwd },
    { name: 'article.list',      path: '/article/list', component: ArticleList },
    { name: 'article.create',    path: '/article/create/:tab?', component: ArticleEdit },
    { name: 'article.edit',      path: '/article/:article_id/:tab?', component: ArticleEdit },
    { name: 'paragraph.create',  path: '/paragraph/create', component: ParagraphEdit, props: function(route) {return {article_id: parseInt(route.query.article_id), pos: route.query.pos }} },
    { name: 'paragraph.edit',    path: '/paragraph/:paragraph_id', component: ParagraphEdit, props: true },
    { name: 'collection.list',   path: '/collection/list', component: CollectionList },
    { name: 'collection.create', path: '/collection/create', component: CollectionEdit },
    { name: 'collection.edit',   path: '/collection/:collection_id/:tab?', component: CollectionEdit },
    { name: 'widget.edit',       path: '/widget/:widget_id', component: CollectionWidgetEdit, props: true  },
    { name: 'selection.create',  path: '/selection/create', component: CollectionSelectionAdd, props: function(route) {return { widget_id: parseInt(route.query.widget_id), type: route.query.type, pos: route.query.pos }} },
    { name: 'selection.edit',    path: '/selection/:selection_id', component: CollectionSelectionEdit  },
    { name: 'serial.list',       path: '/serial/list', component: SerialList },
    { name: 'serial.create',     path: '/serial/create', component: SerialEdit },
    { name: 'serial.edit',       path: '/serial/:serial_id/:tab?', component: SerialEdit },
    { name: 'snippet.list',      path: '/snippet/list', component: SnippetList },
    { name: 'snippet.edit',      path: '/snippet/:snippet_id/:tab?', component: SnippetEdit },
    { name: 'event.list',        path: '/event/list', component: EventList },
    { name: 'event.create',      path: '/event/create', component: EventEdit,props: function(route) {return {content_id: parseInt(route.query.content_id),content_class: route.query.content_class  }}  },
    { name: 'event.edit',        path: '/event/:event_id', component: EventEdit },
    { name: 'broadcast.list',    path: '/broadcast/list', component: BroadcastList },
    { name: 'broadcast.edit',    path: '/broadcast/:bc_id/:tab?', component: BroadcastEdit },
    { name: 'tag.list',          path: '/tag/list', component: TagList },
    { name: 'tag.create',        path: '/tag/create', component: TagEdit },
    { name: 'tag.edit',          path: '/tag/:tag_id/:tab?', component: TagEdit },
    { name: 'link.list',         path: '/link/list', component: LinkList },
    { name: 'link.create',       path: '/link/create', component: LinkEdit },
    { name: 'link.edit',         path: '/link/:link_id/:tab?', component: LinkEdit },
    { name: 'url.list',          path: '/url/list', component: UrlList },
    { name: 'url.create',        path: '/url/create', component: UrlEdit },
    { name: 'url.edit',          path: '/url/:url_id', component: UrlEdit },
    { name: 'push_notification.list',          path: '/push_notification/list', component: PushNotificationList },
    { name: 'push_notification.create',        path: '/push_notification/create', component: PushNotificationEdit },
    { name: 'push_notification.edit',          path: '/push_notification/:push_notification_id', component: PushNotificationEdit },
    { name: 'author.list',       path: '/author/list', component: AuthorList },
    { name: 'author.create',     path: '/author/create/:tab?', component: AuthorEdit },
    { name: 'author.edit',       path: '/author/:author_id/:tab?', component: AuthorEdit },
    { name: 'ugc.list',          path: '/ugc/list', component: UgcList },
    { name: 'ugc.create',        path: '/ugc/create/:tab?', component: UgcEdit },
    { name: 'ugc.edit',          path: '/ugc/:ugc_id/:tab?', component: UgcEdit },
    { name: 'ugcsubmission.list', path: '/ugcsubmission/list', component: UgcSubmissionList },
    { name: 'ugcsubmission.edit', path: '/ugcsubmission/:ugcsubmission_id/:tab?', component: UgcSubmissionEdit },
    { name: 'journalelements',     path: '/journalelements', component: Journalelements },
    { name: 'downloadaccount',     path: '/downloadaccount/list', component: DownloadAccountList },
    { name: 'downloadaccount.count',     path: '/downloadaccount/count', component: DownloadAccountCount },
    { name: 'downloadaccount.edit', path: '/downloadaccount/:account_id', component: DownloadAccountEdit },
    { name: 'downloadaccount.stats', path: '/downloadaccountstats', component: DownloadAccountStats },
    { name: 'image.list',          path: '/image/list', component: ImageList },
    { name: 'image.create',        path: '/image/create', component: ImageEdit },
    { name: 'image.edit',          path: '/image/:image_id/:tab?', component: ImageEdit },
  ]
});

var return_target_conditions = [
  // if a [from, to] condition is satisfied save the 'from' route for returning later.
  // use empty string as wildcard. e.g. ['', 'event.create']
  ['article.edit', 'paragraph.create'],
  ['article.edit', 'paragraph.edit'],
  ['article.edit', 'link.create'],
  ['article.edit', 'link.edit'],
  ['article.edit', 'broadcast.edit'],
  ['article.list', 'article.create'],
  ['article.list', 'article.edit'],
  ['serial.list', 'serial.create'],
  ['serial.list', 'serial.edit'],
  ['author.edit', 'paragraph.create'],
  ['author.edit', 'paragraph.edit'],
  ['author.list', 'author.create'],
  ['author.list', 'author.edit'],
  ['snippet.list', 'snippet.edit'],
  ['url.list', 'url.create'],
  ['url.list', 'url.edit'],
  ['link.list', 'link.create'],
  ['link.list', 'link.edit'],
  ['collection.edit', 'selection.create'],
  ['collection.edit', 'selection.edit'],
  ['collection.edit', 'widget.edit'],
  ['collection.list', 'collection.create'],
  ['collection.list', 'collection.edit'],
  ['tag.list', 'tag.create'],
  ['tag.list', 'tag.edit'],
  ['user.list', 'user.create'],
  ['user.list', 'user.edit'],
  ['', 'event.create'],
];

import axios  from 'axios';

router.beforeEach(function(to, from, next) {
  axios.get('/api/check-lock',{ params: {path: to.path}})
      .then(function (response) {
        const lock = response.data;
        if (lock.locked) {
          router.app.$store.commit('lock',lock);
        }
        else {
          router.app.$store.commit('unlock');
        }
      })
      .catch(function (error) {
        router.app.$store.dispatch('add_log_msg', {msg: error});
      });

  return_target_conditions.forEach(function(routename){
    if (
        ( routename[0] === '' || from.name === routename[0] )
        &&
        ( routename[1] === '' || to.name === routename[1] )
    ) {
      //console.log(from.name + ' > ' + to.name + ' => add ' + from.name + ' to target list');
      router.app.$store.commit('push_return_target', { return_target: from.fullPath });
    }
  });
  next();
});

export default router;
