<template>
  <div>

    <div id="busy-indicator" v-show="ajax_busy > 0">
      <img src="/ajax-loader.gif" />
    </div>

    <nav class="navbar navbar-default" :class="currentAreaClass()">
      <div class="container-fluid">
        <div class="navbar-header">
          <a class="navbar-brand" href="/"><span class="readable">{{ currentAreaDisplay() }}</span></a>
        </div>
        <ul v-if="logged_in" class="nav navbar-nav">

          <li class="dropdown">
            <a ref="navDropdown" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">
              <span class="readable">ContentObjects<span class="caret"></span></span>
            </a>
            <ul class="dropdown-menu">
              <router-link active-class="active" tag="li" to="/article/list"   ><a>Artikel    </a></router-link>
              <router-link active-class="active" tag="li" to="/broadcast/list" ><a>Programm   </a></router-link>
              <router-link active-class="active" tag="li" to="/serial/list"    ><a>Sendereihen</a></router-link>
              <router-link active-class="active" tag="li" to="/author/list"    ><a>Autoren    </a></router-link>
              <router-link active-class="active" tag="li" to="/link/list"      ><a>Links      </a></router-link>
            </ul>
          </li>

          <router-link active-class="active" tag="li" to="/collection/list"><a><span class="readable">Collections</span></a></router-link>
          <router-link active-class="active" tag="li" to="/image/list"     ><a><span class="readable">Fotos      </span></a></router-link>
          <router-link active-class="active" tag="li" to="/tag/list"       ><a><span class="readable">Tags       </span></a></router-link>
          <router-link active-class="active" tag="li" to="/url/list"       ><a><span class="readable">URLs      </span></a></router-link>
          <router-link active-class="active" tag="li" to="/user/list"      ><a><span class="readable">Benutzer   </span></a></router-link>

          <li class="dropdown">
            <a ref="navDropdownDiverses" class="dropdown-toggle" data-toggle="dropdown_div" aria-haspopup="true" aria-expanded="false" href="#"><span class="readable">Diverses<span class="caret"></span></span>

            </a>
            <ul class="dropdown-menu">
              <router-link active-class="active" tag="li" to="/event/list"         ><a>Events          </a></router-link>
              <router-link active-class="active" tag="li" to="/snippet/list"       ><a>Schnipsel       </a></router-link>
              <router-link active-class="active" tag="li" to="/journalelements"    ><a>Journalelemente </a></router-link>
              <router-link active-class="active" tag="li" to="/ugc/list"           ><a>UGC             </a></router-link>
              <router-link active-class="active" tag="li" to="/ugcsubmission/list" ><a>UGC Manage      </a></router-link>
              <router-link active-class="active" tag="li" to="/push_notification/list"       ><a>Push Notifications</a></router-link>
              <router-link active-class="active" tag="li" to="/downloadaccount/list" ><a>Download Abos      </a></router-link>
              <router-link active-class="active" tag="li" to="/downloadaccount/count" ><a>Download Anzahl User</a></router-link>
              <router-link active-class="active" tag="li" to="/downloadaccountstats" ><a>Download Statistik</a></router-link>
              <li><a href="/manual.html" target="_new">Manual</a></li>
            </ul>
          </li>

        </ul>
        <oe1-auth></oe1-auth>
      </div>
    </nav>

    <div class="container main-container">
      <div v-if="logged_in">
        <div v-if="lock.locked"  class="alert alert-warning" role="alert">
            {{ lock.message }}
            <button class="btn btn-default" @click="stealLock">
                <span class="glyphicon glyphicon-lock"></span>
                Steal Lock
            </button>
        </div>
        <router-view></router-view>
      </div>
      <div v-else>
        <div><h1>Bitte einloggen</h1></div>
      </div>
    </div>

    <oe1-log id="oe1-log"></oe1-log>

  </div>
</template>

<script>
import 'babel-polyfill'

import axios  from 'axios';
import router from './Router.js';
import auth   from './Auth.vue';
import log    from './Log.js';
import store  from './Store'
import { mapState } from 'vuex';
import VueAxios from 'vue-axios'
import bsn from 'bootstrap.native';

import Vue from 'vue';
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
Vue.use(VueFlatPickr);

import Oe1SaveClose from "@/reusable/SaveClose.vue";
Vue.component(Oe1SaveClose.name,Oe1SaveClose);

import Oe1ConfirmModal from "@/reusable/ConfirmModal.vue";
Vue.component(Oe1ConfirmModal.name,Oe1ConfirmModal);

import Oe1ImageModal from "@/reusable/ImageModal.vue";
Vue.component(Oe1ImageModal.name,Oe1ImageModal);

import Oe1Autocomplete from "@/reusable/Autocomplete.vue";
Vue.component(Oe1Autocomplete.name,Oe1Autocomplete);

import Oe1ChangeLog from "@/reusable/ChangeLog.vue";
Vue.component(Oe1ChangeLog.name,Oe1ChangeLog);

import Oe1Memo from "@/reusable/Memo.vue";
Vue.component(Oe1Memo.name,Oe1Memo);

import Oe1Previewlink from "@/reusable/Previewlink.vue";
Vue.component(Oe1Previewlink.name,Oe1Previewlink);

import Oe1Tag from "@/reusable/Tag.vue";
Vue.component(Oe1Tag.name,Oe1Tag);

import Oe1Event from "@/reusable/Event.vue";
Vue.component(Oe1Event.name,Oe1Event);

import Oe1ListImage from "@/reusable/ListImage.vue";
Vue.component(Oe1ListImage.name,Oe1ListImage);

import Oe1AddImage from "@/reusable/AddImage.vue";
Vue.component(Oe1AddImage.name,Oe1AddImage);

import Oe1Author from "@/reusable/Author.vue";
Vue.component(Oe1Author.name,Oe1Author);

import Oe1Link from "@/reusable/Link.vue";
Vue.component(Oe1Link.name,Oe1Link);

import Oe1RelatedContent from "@/reusable/RelatedContent.vue";
Vue.component(Oe1RelatedContent.name,Oe1RelatedContent);

import Oe1Location from "@/reusable/Location.vue";
Vue.component(Oe1Location.name,Oe1Location);

import Oe1FilterContentObject from "@/reusable/FilterContentObject.vue";
Vue.component(Oe1FilterContentObject.name,Oe1FilterContentObject);

import Oe1NiceBool from "@/reusable/NiceBool.vue";
Vue.component(Oe1NiceBool.name,Oe1NiceBool);

import Oe1Audiobrowser from "@/reusable/Audiobrowser.vue";
Vue.component(Oe1Audiobrowser.name,Oe1Audiobrowser);

Vue.use(VueAxios, axios)

function _install_401_handler(app) {
  app.axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function(error) {
      if (error.response.status === 401) {
        app.$store.commit('update_auth', {
          logged_in: 0,
          username: ''
        });
        app.$router.push('/');
      }
      return Promise.reject(error);
    }
  );
}
function _install_busy_handler(app) {
  app.axios.interceptors.request.use(
    function (config) {
      app.ajax_busy++;
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );
  app.axios.interceptors.response.use(
    function (response) {
      app.ajax_busy--;
      return response;
    },
    function(error) {
      app.ajax_busy--;
      return Promise.reject(error);
    }
  );
}

var App = Vue.extend({
  store: store,
  router: router,
  name: 'oe1-app',
  data: function() { return { ajax_busy: 0 } },
  methods: {
    'niceBool': function(val) {
      if (val) {
        return '<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>';
      }
      return "NOP";
    },
    'getStatusLabel': function(key) {
      return this.getListLabel(this.$store.state.config.content_status_options,key);
    },
    'getListLabel': function(list,key) {
      if (typeof(list) !== 'object') {
        list = this.$store.state.config[list];
      }
      var option = list.find(function(element) {
        return element.value === key;
      });
      if (option !== undefined) {
        return option.label;
      } else {
        return key;
      }
    },
    'reactiveCopy': function(src, dst, keys) {
      var vm = this;
      keys.forEach(function (key) {
          if (!(key in src)) {
              return;
          }
          if (!(key in dst) ||
              typeof src[key] !== 'object' ||
              typeof dst[key] !== 'object'
          ) {
              dst[key] = src[key];
          }
          else {
              vm.reactiveCopy(
                  src[key],
                  dst[key],
                  Object.keys(src[key])
              )
          }
      });
    },
    'currentArea': function() {
      var name = this.$route.name;
      if (!name) {
        return '';
      }
      var area = name.replace(/\.\w+/,'');
      if (area == 'paragraph') {
        area = 'article';
      } else if (area == 'selection') {
        area = 'collection';
      } else if (area == 'widget') {
        area = 'collection';
      } else if (area == 'ugcsubmission') {
        area = 'ugc';
      }

      return area;
    },
    'currentAreaDisplay': function() {
      var area = this.currentArea();
      if (!area) {
        return 'Ö1::Admin';
      }
      area = 'Ö1::Admin::' + area.charAt(0).toUpperCase() + area.slice(1);
      return area;
    },
    'currentAreaClass': function() {
      var area = this.currentArea();
      if (!area) {
        return 'nav_none';
      }
      area = 'nav_' + area;
      return area;
    },
    stealLock: function() {
      axios.get('/api/steal-lock',{ params: {path: this.$router.currentRoute.path}})
        .then(function (response) {
          store.dispatch('add_log_msg', {msg: response.data.status});
          const lock = response.data.lock;
          if (lock.locked) {
            store.commit('lock',lock);
          }
          else {
            store.commit('unlock');
          }
        })
        .catch(function (error) {
          store.dispatch('add_log_msg', {msg: error});
        });
    }
  },
  components: {
    'oe1-auth': auth,
    'oe1-log': log
  },
  watch: {
    logged_in: function(newVal, oldVal) {
        // on first successful login initialize dropdown menu javascript
        if(!oldVal && newVal) {
          this.$nextTick(function() {
            new bsn.Dropdown(this.$refs.navDropdown);
            new bsn.Dropdown(this.$refs.navDropdownDiverses);
          });
        }
    }
  },
  computed: mapState(['logged_in','list_params','lock']),
  beforeCreate: function() {
    _install_401_handler(this);
    _install_busy_handler(this);
  }
});
export default App;
</script>

<style scoped>
#oe1-log {
    width: 200px;
    position: fixed;
    top: 60px;
    right: 25px;
    z-index: 1001;
}

.sidebar {
    position: fixed;
    right: 1px;
    border-left: 1px solid #e7e7e7;
    height: 100%;
    overflow-y: auto;
}

td.nowrap {
    white-space:nowrap;
}

#busy-indicator {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9999;
}

.offline {
  background-color:#CCC;
}

.online {
  background-color:#AD9;
}

.draft {
  background-color:#d2b4b4;
}

.table-striped > tbody > tr:nth-of-type(2n+1).draft {
  background-color:#f2c4c4;
}
.table-striped > tbody > tr:nth-of-type(2n+1).online {
  background-color:#BEA;
}
.table-striped > tbody > tr:nth-of-type(2n+1).offline {
  background-color:#DDD;
}

.nav_article{background-color:#61BD4F}
.nav_user{background-color:#F2D600}
.nav_collection{background-color:#FFAB4A}
.nav_serial{background-color:#EB5A46}
.nav_broadcast{background-color:#C377E0}
.nav_tag{background-color:#0079BF}
.nav_link{background-color:#FF80CE}
.nav_author{background-color:#00C2E0}
.nav_url{background-color:#f1c40f}
.nav_ugc{background-color:#51E898}
.nav_event{background-color:#4d4d4d}
.nav_snippet{background-color:#e7e7e7}
.nav_journalelements{background-color:#e7e7e7}
.nav_downloadaccount{background-color:#e7e7e7}
.nav_image{background-color:#4dd4ac}
.nav_none{background-color:#e7e7e7}

span.readable {
  padding:5px;
  background-color:#e7e7e7;
  border-radius: 4px;
}

</style>
