
<script>

import selectionForm from './CollectionSelectionForm.html';

export default {
  data: function () { return {
    selection: {
      args: {tag:'',tag_display:'',serial:'',serial_display:'',author:'',author_display:'',object_display:'',serial_set:[]}, // TODO needed so autocomplete can bind something, but maybe there's a better solution?
      status: 'online',
      title: '',
      file: {
        file:'',
        filename:'',
      },
      image: {
        src: '',
      },
    },
    image_just_deleted: false,
    };
  },
  computed: {
    currentType: function() {
      var vm = this;
      return vm.selection.type || vm.type;
    },
    showStuff: function(){
      var vm = this;
      var currentType = vm.currentType;
      if (!currentType) { return {
        rows: false,
        continue: false,
        order_by: false
      } };
      var opts = vm.$store.state.config.selection_types;

      for(var i=0; i<opts.length; i++) {
        if (opts[i].value == currentType) {
        return opts[i].show;
        }
      }
      return {
        rows: false,
        continue: false,
        order_by: false
      } ;
    }
  },
  template: '<div>' + selectionForm + '</div>',
  props: ['widget_id','type','pos'],
  methods: {
    'submit_selection': function () {
      var vm = this;
      vm.selection.type = vm.type;
      vm.selection.pos  = vm.pos;
      var promise = vm.axios.post('/api/widget/' + vm.widget_id + '/selection', vm.selection);
      promise
        .then(function (response) {
          vm.$log('Selection wurde angelegt');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    'selectTag': function (hit) {
      var vm = this;
      vm.selection.args.tag = hit.id;
      vm.selection.args.tag_display = hit.value;
    },
    'selectSerial': function (hit) {
      var vm = this;
      vm.selection.args.serial = hit.id;
      vm.selection.args.serial_display = hit.value;
    },
    'selectUGC': function (hit) {
      var vm = this;
      vm.selection.args.ugc = hit.id;
      vm.selection.args.ugc_display = hit.value;
    },
    'selectAuthor': function (hit) {
      var vm = this;
      vm.selection.args.author = hit.id;
      vm.selection.args.author_display = hit.value;
    },
    'addToSerialSet': function (hit) {
      var vm = this;
      vm.selection.args.serial_set.push({id:hit.id,display:hit.value});
    },
    'selectObject': function (hit) {
      var vm = this;
      if (hit.table === 'collection') {
        vm.$log({
          name:"Fancy",
          level:'alert-danger',
          msg:"Collections können derzeit nicht in Collections verwendet werden. Bitte einen Link als workaround verwenden!"});
        return;
      }
      vm.selection.args.content_class = hit.table;
      vm.selection.args.content_id = hit.id;
      vm.selection.args.object_display = hit.value;
    },
    onFileChange: function(e) {
      var vm = this;
      vm.inputFile = '';
      var files = e.target.files;
      if (!files.length) { return; }
      var file = files[0];
      var image = new Image();
      var reader = new FileReader();
      reader.onload = function(event) {
        vm.selection.file.file = event.target.result;
        vm.selection.file.filename = file.name;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function() {
      var vm = this;
      vm.selection.image = { src: ''};
      vm.selection.args.image = null;
      vm.image_just_deleted = true;
    }
  }
};
</script>
