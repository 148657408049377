<template>
  <span v-if="bool" class="glyphicon glyphicon-ok" aria-hidden="true" aria-label="true" style="color:#3c763d"></span>
  <span v-else class="glyphicon glyphicon-remove" aria-hidden="true" aria-label="false" style="color:#a94442"></span>
  </template>
<script>

export default {
  name: "oe1-nice-bool",
  props: {
    bool: { required: true },
  },
};

</script>
