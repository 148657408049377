<script>
export default {
  name: 'oe1-filter-element',
  props: {
    label: { type: String, required: true },
    param: { type: String, required: true },
    divClass: { type: String, default: 'form-control' },
  },
  data: function () { return {} },
  render: function (createElement) {
    var node = this.$slots.default[0];
    var id = 'filter-'+this.param;
    node.data.attrs = new Object();
    node.data.attrs.id = id;
    node.data.attrs.class = this.divClass;
    node.data.attrs.placeholder = this.label;

    var label = createElement(
      'label',
      {},
      [ this.label ]
    );
    label.data.attrs = new Object();
    label.data.attrs.for = id;

    return createElement(
      'div',
      {
        'class': 'form-group',
      },
      [
        label,
        this.$slots.default
      ]
    )
  },
};

</script>
