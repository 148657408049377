module.exports = [
    { value: '0',    label: '0 (ganze Welt)' },
    { value: '1',    label: '1' },
    { value: '2',    label: '2' },
    { value: '3',    label: '3 (Kontinent)' },
    { value: '4',    label: '4' },
    { value: '5',    label: '5' },
    { value: '6',    label: '6 (Staat)' },
    { value: '7',    label: '7' },
    { value: '8',    label: '8' },
    { value: '9',    label: '9' },
    { value: '10',    label: '10' },
    { value: '11',    label: '11 (Stadt)' },
    { value: '12',    label: '12' },
    { value: '13',    label: '13 (Dorf)' },
    { value: '14',    label: '14' },
    { value: '15',    label: '15' },
    { value: '16',    label: '16 (Gasse)' },
];
