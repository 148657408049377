<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Location</h3>
    </div>
    <div class="panel-body">
      <div v-if="!showMap">
        <button class="btn btn-info" v-on:click="showMap=true">Location hinzufügen</button>
      </div>
      <div v-if="showMap">
        <div class="row" style="padding-bottom:1em">
        <div class="col-md-2"><input v-model="newLoc.name" type="text" placeholder="Bezeichnung" required size="10"/></div>
        <div class="col-md-3"><input v-model="newLoc.latitude" type="text" placeholder="Breitengrad / Latitude" required size="18"/></div>
        <div class="col-md-3"><input v-model="newLoc.longitude" type="text" placeholder="Längengrad / Longitude" required size="18"/></div>
        <div class="col-md-4 text-right">
          <button class="btn btn-primary" :disabled="!canAdd" v-on:click="addLocation">Speichern</button>
          <button class="btn btn-info" v-on:click="closeMap">Abbrechen</button>
        </div>
        </div>
        <l-map style="height: 300px" :zoom="zoom" :center="center" @click="addMarker">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker v-if="newLoc.latitude" :lat-lng="[newLoc.latitude, newLoc.longitude]"></l-marker>
        </l-map>
      </div>
    </div>

    <div v-if="items.length">
      <li class="list-group-item">
        <div class="row">
          <div class="col-md-3">Name</div>
          <div class="col-md-3">Koordinaten</div>
          <div class="col-md-3 text-right"/>
        </div>
      </li>
      <li class="list-group-item" v-for="loc in items">
        <div class="row">
          <div class="col-md-4">
            {{loc.name}}
          </div>
          <div class="col-md-4">
            {{niceCoord(loc.latitude)}} / {{niceCoord(loc.longitude)}}
          </div>
          <div class="col-md-4 text-right">
            <button class="btn btn-default btn-xs" v-on:click="showReviewLoc(loc)"><span class="glyphicon glyphicon-arrow-right" aria-hidden="true"></span></button>
            <oe1-confirm-modal :callback="removeLocationBuilder(loc)"></oe1-confirm-modal>
          </div>
        </div>
      </li>
      <div v-if="reviewLoc.show">
        <div class=" text-right"><button class="btn btn-info" v-on:click="reviewLoc.show=false">Schliessen</button></div>
        <l-map style="height: 300px" :zoom="zoom" :center="[reviewLoc.latitude, reviewLoc.longitude]">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="[reviewLoc.latitude, reviewLoc.longitude]"></l-marker>
        </l-map>
      </div>
    </div>
    <div v-else class="panel-footer">
      Keine Location vorhanden.
    </div>
  </div>
</template>

<script>
import ContentObject from '../mixins/ContentObject.js';
import { L, Icon } from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
import "leaflet/dist/leaflet.css";

export default {
  name: "oe1-locations",
  mixins: [ ContentObject ],
  data: function() { return {
      items: [],
      showMap: false,
      url: 'https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 7,
      center: [48.194, 16.372],
      loc: undefined,
      newLoc: {
        name: undefined,
        latitude: undefined,
        longitude: undefined,
        latlng: []
      },
      reviewLoc: {
        show: false,
        latitude: undefined,
        longitude: undefined
      }
  } },
  props: {},
  created: function () {
    this.loadData();
  },
  computed: {
    locked: function(){ return this.$store.getters.locked },
    content_object_url: function() {
      var vm = this;
      return vm.content_class+'/'+vm.content_id;
    },
    canAdd() {
      if (this.newLoc.name && this.newLoc.longitude && this.newLoc.latitude) {
        return true;
      }
      return false;
    },
  },
  methods: {
    loadData: function() {
      var vm = this;
      vm.axios.get('/api/location/', { params: { content_class: vm.content_class, content_id: vm.content_id } })
        .then(function (response) {
          vm.items = response.data.items;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    addLocation: function() {
      var vm = this;
      vm.axios.post('/api/location', { ...vm.newLoc, content_id: vm.content_id, content_class: vm.content_class }  )
        .then(function (response) {
          vm.$log('Location "' + vm.newLoc.name + '" wurde hinzugefügt.');
          vm.loadData();
          vm.closeMap();
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    removeLocationBuilder: function(item) {
        return this.removeLocation.bind(this,item);
    },
    removeLocation: function (rc) {
      var vm = this;
      vm.axios.delete('/api/location/' + rc.id  )
        .then(function (response) {
          vm.$log('Location "' + rc.name + '" wurde entfernt.');
          vm.loadData();
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    addMarker(e) {
      this.newLoc.latitude=this.niceCoord(e.latlng.lat);
      this.newLoc.longitude=this.niceCoord(e.latlng.lng);
    },
    closeMap() {
      this.newLoc.name=undefined;
      this.newLoc.latitude=undefined;
      this.newLoc.longitude=undefined;
      this.showMap=false;
    },
    niceCoord(c) {
      return Number.parseFloat(c).toFixed(4);
    },
    showReviewLoc(loc) {
      this.reviewLoc.latitude=loc.latitude;
      this.reviewLoc.longitude=loc.longitude;
      this.reviewLoc.show=true;
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
};
</script>


