<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.title }}</td>
          <td>{{ row.item.template }}</td>
          <td>{{ $root.getStatusLabel(row.item.status) }}</td>
          <td>{{ row.item.url }}</td>
          <td>
            <button type="button" class="btn btn-default btn-xs" v-on:click="editItem(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
            <a :href="row.item.previewlink" type="button" class="btn btn-default btn-xs" target="_new" title="zeige Collection in neuem Fenster."><span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a>
          </td>
        </template>
      </oe1-table>
    </div>

    <template slot="actions">
      <router-link to="/collection/create" class="btn btn-info">Sammlung anlegen</router-link>
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>

    <div slot="filter">
      <oe1-filter-element label="URL" param="url">
        <input type="text" v-model="params.url" v-on:change="updateParams">
      </oe1-filter-element>
      <oe1-filter-content-object
        :params="params"
        v-on:oe1-update-params="updateParams"
        :status-options="statusOptions"
      ></oe1-filter-content-object>
    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'collection',
      columns: [
        { label: "ID", width:"10%" },
        { label: "Titel" },
        { label: "Template" },
        { label: "Status" },
        { label: "URL" },
        { label: "", width: "5%" }
      ],
      params: { status: 'all' }
    };
  },
  computed: {
    statusOptions: function() {
      return this.$store.state.config.simple_status_options;
    }
  },
};
</script>
