<template>
  <span>
    <button type="button" class="btn" :class="buttonStyle" v-on:click="actionAsk()" :disabled="locked">
      <slot name="button">
        <div v-if="buttonText == 'glyphicon'">
          <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
        </div>
        <div v-else>
          {{ buttonText }}
        </div>
      </slot>
    </button>

    <div role="dialog" class="modal fade in" v-if="showModal" style="display:block">
      <div role="document" class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" v-on:click="actionAbort()">
              <span>×</span>
            </button>
            <h4 class="modal-title">
              {{ title }}
            </h4>
          </div>
          <div class="modal-body">
            <slot name="body">
              {{ body }}
            </slot>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" v-on:click="actionAbort()">{{ cancel_text }}</button>
            <button type="button" class="btn btn-primary" v-on:click="actionContinue($event)">{{ continue_text }}</button>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<style>
.modal.in {
  background-color: rgba(0,0,0,0.5);
}

.modal {
  color: black;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
</style>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'oe1-confirm-modal',
  props: {
    title: {
      type: String,
      default: 'Bestätigung'
    },
    body: {
      type: String,
      default: 'Willst du das Element wirklich löschen?'
    },
    cancel_text: {
      type: String,
      default: 'Abbrechen'
    },
    continue_text: {
      type: String,
      default: 'Fortfahren'
    },
    callback: {
      type: Function,
      required: true,
    },
    buttonStyle: {
      type: String,
      default: 'btn-xs btn-default',
    },
    buttonText: {
      type: String,
      default: 'glyphicon',
    }
  },
  data: function () { return {
    showModal: false
  } },
  computed: mapGetters(['locked']),
  methods: {
    actionAbort: function() {
      this.showModal = false;
    },
    actionAsk: function() {
      this.showModal = true;
    },
    actionContinue: function(event) {
      this.showModal = false;
      this.callback(event);
      if (event) event.preventDefault();
    },
  }
};
</script>
