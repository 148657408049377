<template>
  <form v-on:submit.prevent>
    <div class="form-group">
      <label for="inputArea">Bereich</label>
      <select class="form-control" id="inputArea" required v-model="article.area">
        <option v-for="o in $store.state.config.article_area" :value="o.value">{{ o.label }}</option>
      </select>
    </div>

    <div class="form-group" :class="article.status">
      <label for="inputStatus">Status</label>
      <select class="form-control" id="inputStatus" required v-model="article.status">
        <option v-for="o in $store.state.config.content_status_options" :value="o.value">{{ o.label }}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="inputSubtitle">Stichwort</label>
      <input v-model="article.subtitle" type="text" class="form-control" id="inputSubtitle" placeholder="Stichwort" autofocus>
    </div>

    <div class="form-group">
      <label for="inputTitle">Titel</label>
      <input v-model="article.title" type="text" class="form-control" id="inputTitle" placeholder="Titel" required autofocus>
    </div>

    <div class="form-group">
      <label for="inputTitle">Thema</label>
      <input v-model="article.thema" type="text" class="form-control" id="inputThema" placeholder="Thema" autofocus>
    </div>

    <div class="form-group">
      <label for="inputTeaser">Teaser</label>
      <textarea v-model="article.teaser" rows="5" class="form-control" id="inputTeaser" placeholder="Teaser"></textarea>
    </div>

    <div class="form-group">
      <label for="inputCDR">CDR-Type</label>
      <div v-if="article.cdr_type=='program_sp'">
        Programmschwerpunkt
        <span class="badge" v-if="article.programmschwerpunkt_offline">{{ article.programmschwerpunkt_offline }}</span>
      </div>
      <div v-else>
        <select class="form-control" id="inputCDR" required v-model="article.cdr_type">
          <option v-for="o in cdrType" :value="o.value">{{ o.label }}</option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label for="inputSortDate">Sortier Datum</label>
      <div>
        <flat-pickr v-model="article.sort_date" :config="$store.state.config.datetime_options"/>
      </div>
    </div>
    <oe1-save-close :content_id="article.id" :submit_method="submit_article"></oe1-save-close>
  </form>
</template>

<script>
export default {
  data: function () { return {
      cdrType: [
        { value: '30_days', label: '30 Tage' },
        { value: '14_days', label: '14 Tage' },
        { value: 'unlimited', label: 'unlimitiert' },
      ],
  }},
  props: [ 'article' ],
  methods: {
    'submit_article': function () {
      var vm = this;
      var save_url = vm.article.id
        ? '/api/article/' + vm.article.id
        : '/api/article';
      var promise = vm.axios.post(save_url, vm.article);
      promise
            .then(function (response) {
              vm.$log('Artikel wurde gespeichert.');
            })
            .catch(function (error) {
              vm.$log(error);
            });
      return promise;
    },
  }
};
</script>
