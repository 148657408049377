<template>
  <div>
    <form v-on:submit.prevent>
      <div class="form-group">
        <label for="inputStatus">Status</label>
        <select class="form-control" id="inputStatus" required v-model="tag.status">
          <option v-for="o in $store.state.config.simple_status_options" :value="o.value">{{ o.label }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="inputType">Typ</label>
        <select class="form-control" id="inputType" required v-model="tag.type">
          <option v-for="o in type_options" :value="o.value">{{ o.label }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="title">Tag</label>
        <input v-model="tag.title" type="text" class="form-control" id="title" placeholder="Tag" required autofocus @keyup="tagFromDisplayName">
      </div>
      <div class="form-group">
        <label for="tag">Interner Name</label>
        <input v-model="tag.tag" type="text" class="form-control" id="tag" placeholder="Interner Name" required>
      </div>
      <div class="form-group">
        <label for="koko_match">Koko Match</label>
        <input v-model="tag.koko_match" type="text" class="form-control" id="koko_match" placeholder="Koko Match">
      </div>
      <div class="form-group">
        <label for="radiothek_tag_id">Radiothek Tag ID</label>
        <input v-model="tag.radiothek_tag_id" type="text" class="form-control" id="radiothek_tag_id" placeholder="Radiothek Tag ID">
      </div>

      <div class="form-group">
        <label for="inputTeaser">Teaser</label>
        <textarea v-model="tag.teaser" rows="5" class="form-control" id="inputTeaser" placeholder="Teaser"></textarea>
      </div>

      <div class="form-group">
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="tag.is_programmschwerpunkt" /> Programmschwerpunkt</label>
        </div>
      </div>

      <oe1-save-close :content_id="tag.id" :submit_method="submit_tag"></oe1-save-close>
      </span>
    </form>
  </div>
</template>

<script>
export default {
  data: function () { return {
      type_options: [
        { value: 'link',     label: 'Link' },
        { value: 'display',  label: 'Nur anzeigen' },
        { value: 'internal', label: 'Intern' },
      ],
    };
  },
  props: ['tag', 'content_class'],
  methods: {
    'submit_tag': function () {
      var vm = this;
      var saveUrl = vm.tag.id
                    ? '/api/tag/' + vm.tag.id
                    : '/api/tag/';
      var promise = vm.axios.post(saveUrl, vm.tag);
      promise
        .then(function (response) {
          vm.$log('Tag "'+vm.tag.tag +'" wurde gespeichert.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    'tagFromDisplayName': function() {
      var vm = this;
      if ( vm.tag.id > 1 ) { return }
      var autotag = vm.tag.title.replace(/\W+/g, "_");
      autotag = autotag.toLowerCase();
      vm.tag.tag = autotag;
    },
    closeEvent: function() {
      var vm = this;
      vm.$router.push('/tag/list' );
    },
  }
};
</script>
