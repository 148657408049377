<template>
  <div class="btn-group" role="group">
    <button v-if="!hide_save" class="btn btn-primary" @click="save" :disabled="this.locked">
      <span class="glyphicon glyphicon-floppy-saved"></span>
      Speichern</button>
    <button v-if="content_id && !hide_save" class="btn btn-default" @click="save_close" :disabled="this.locked">
      <span class="glyphicon glyphicon-floppy-remove"></span>
      Speichern und Schließen
    </button>
    <div v-if="content_id" class="btn btn-default" @click="close">
      <span class="glyphicon glyphicon-remove"></span>
      Schließen
    </div>
    <div v-if="withAbort && !content_id" class="btn btn-default" @click="close">
      <span class="glyphicon glyphicon-remove"></span>
      Abbrechen
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "Oe1SaveClose",
  props: {
    content_id: { required: true },
    submit_method: { required: true, type: Function },
    hide_save: { default: false },
    withAbort: { default: false },
  },
  computed: mapGetters(['locked']),
  methods: {
    save: function() {
      var vm = this;
      var promise = vm.submit_method();
      promise.then(function (response) {
        if (!vm.content_id) { // object has been created
          var path = vm.$route.fullPath.replace('create', response.data.id);
          vm.$router.push(path);
        }
      })
    },
    close: function() {
      var vm = this;

      if (vm.content_id) {
        var path = vm.$route.fullPath;
        vm.axios.delete('/api/remove-lock',{ params: {path: path} } )
          .then(function () {
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
      var target = vm.$store.getters.get_return_target;
      if (target) {
        vm.$store.commit('pop_return_target');
        vm.$router.push(target);
      }
      else if (vm.$route.name) {
        var listname = vm.$route.name.match(/^\w+\./);
        vm.$router.push({ name: listname + 'list'});
      }
      else {
        vm.$router.back();
      }
    },
    save_close: function() {
      var vm = this;
      var promise = this.submit_method();
      promise.then(function (response) {
        vm.close();
      })
    },
  }
};

</script>
