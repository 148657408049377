import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logged_in: false,
    username: '',
    log_msgs: [],
    list_params: {},
    return_targets: [],
    lock: {
      locked: false,
      message: ""
    },
    config: {
      content_status_options: require('./config/content_status_options.js'),
      simple_status_options: require('./config/simple_status_options.js'),
      datetime_options: require('./config/datetime_options.js'),
      date_options: require('./config/date_options.js'),
      ressorts: require('./config/ressorts.js'),
      selection_types: require('./config/selection_types.js'),
      event_actions: require('./config/event_actions.js'),
      content_classes: require('./config/content_classes.js'),
      paragraph_options: require('./config/paragraph_options.js'),
      order_by: require('./config/order_by.js'),
      order_by_es: require('./config/order_by_es.js'),
      article_area: require('./config/article_area.js'),
      download_account_type: require('./config/download_account_type.js'),
      broadcast_select_types: require('./config/broadcast_select_types.js'),
      searchbox_area: require('./config/searchbox_area.js'),
      ugc_schedule_actions: require('./config/ugc_schedule_actions.js'),
      push_status_options: require('./config/push_status_options.js'),
      push_topic_options: require('./config/push_topic_options.js'),
      location_zoom_level: require('./config/location_zoom_level.js'),
    }
  },
  getters: {
    get_return_target: function(state) {
      return state.return_targets[state.return_targets.length-1];
    },
    locked: function(state) {
      return state.lock.locked;
    }
  },
  mutations: {
    push_return_target: function (state, payload) {
      state.return_targets.push(payload.return_target);
    },
    pop_return_target: function (state, payload) {
      state.return_targets.pop();
    },
    update_auth: function (state, payload) {
      state.logged_in = payload.logged_in;
      state.username = payload.username;
    },
    addLogMsg: function (state, msg) {
      state.log_msgs.push( msg );
    },
    removeLogMsg: function (state, payload) {
      const ident = payload.ident;
      const clean_msgs = state.log_msgs.filter(function(o) {
        return o.ident !== ident;
      });
      state.log_msgs = clean_msgs;
    },
    set_list_params: function (state, payload) {
      state.list_params[payload.list] = payload.params;
    },
    lock: function (state, payload) {
      state.lock.locked = true;
      state.lock.message = payload.message;
    },
    unlock: function (state) {
      state.lock.locked = false;
      state.lock.message = "";
    }
  },
  actions: {
    add_log_msg: function (context, payload) {
      var msg = payload.msg;
      var level = 'alert-info';
      var nice_error = msg;
      var ident = 'id-' + Math.random().toString(36).substr(2, 16);
      var timeout = 5000;
      if (typeof msg === 'object') {
        if (msg.name === 'Error') {
          var res = msg.response;
          level='alert-danger';
          var error = res.data.error || res.data.message;
          nice_error = res.status + ' ' + res.statusText + ': ' + error;
          timeout = 60000;
        }
        else if (msg.name === 'Fancy') {
          if (msg.level) {
            level = msg.level;
          }
          nice_error = msg.msg;
        }
      }
      context.commit("addLogMsg",{ ident: ident, level: level, message: nice_error });
      setTimeout( function() {
        context.commit("removeLogMsg",{ident:ident});
      }, timeout);
    },
    remove_log_msg: function (context, payload) {
      context.commit("removeLogMsg", payload);
    },
    lock: function (context, payload) {
      context.commit("lock", payload);
    },
    unlock: function (context) {
      context.commit("unlock");
    }
  }
});
