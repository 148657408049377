<template>
  <div class="btn-group">
    <button type="button" class="btn btn-info" @click="openPreview()">Vorschau</button>
    <button type="button" class="btn btn-info dropdown-toggle" @click="toggleDropdown()" aria-haspopup="true" :aria-expanded="dropdown">
      <span class="caret"></span>
    </button>
    <ul class="dropdown-menu" :style="{display: dropdown ? 'block':'none' }">
      <li><a href="#" @click="openPreview(12)">in 12h</a></li>
      <li><a href="#" @click="openPreview(24)">in 24h</a></li>
      <li><a href="#" @click="openPreview(168)">nächste Woche</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'oe1-previewlink',
  props: {
    link: {
      type: String,
      required: true
    }
  },
  methods: {
    openPreview: function(timemachine_offset) {
      var link = this.link;
      if (typeof(timemachine_offset) !== 'undefined') {
        var date = new Date();
        date.setHours(date.getHours()+timemachine_offset);

        if (link.indexOf('?')) {
          link = link + '%26';
        } else {
          link = link + '%3F';
        }
        link = link + 'timemachine=' + date
          .toISOString()
          .replace('T',' ')
          .replace(/-/g,'.')
          .replace(/\.\d+Z$/,'');
      }
      this.dropdown = false;
      window.open(link,'_blank');
    },
    toggleDropdown: function() {
      this.dropdown = ! this.dropdown;
    }
  },
  data: function () { return {
      dropdown: false,
    }
  },
};
</script>



