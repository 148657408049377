module.exports = [
    { value: 'draft',     label: 'Entwurf' },
    { value: 'ready',     label: 'Bereit' },
    { value: 'on_hold',   label: 'Warteschlange' }, //?
    { value: 'scheduled', label: 'Geplant' },
    { value: 'online',    label: 'Online' },
    { value: 'offline',   label: 'Offline' },
    { value: 'deleted',   label: 'Gelöscht' },
    { value: 'inactive',  label: 'Inaktiv' }
];
