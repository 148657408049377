<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.title }}</td>
          <td>{{ row.item.tag }}</td>
          <td>{{ row.item.koko_match }}</td>
          <td>{{ row.item.canonical_url }}</td>
          <td>{{ $root.getStatusLabel(row.item.status) }}</td>
          <td>{{ row.item.type }}<span v-if="row.item.is_programmschwerpunkt"><br>Programm-SP</span></td>
          <td>
            <button type="button" class="pull-right btn btn-default btn-xs" v-on:click="editItem(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
            <oe1-image-modal :image_id="row.item.image"></oe1-image-modal>
          </td>
        </template>
      </oe1-table>
    </div>

    <template slot="actions">
      <router-link to="/tag/create" class="btn btn-info">Tag anlegen</router-link>
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>

    <div slot="filter">
      <oe1-filter-content-object
        :params="params"
        v-on:oe1-update-params="updateParams"
        :show-page-id=false
        :show-tag=false
        :status-options="statusOptions"
      ></oe1-filter-content-object>

      <oe1-filter-element label="Tag intern" param="tag_name">
        <input type="text" v-model="params.tag_name" v-on:change="updateParams">
      </oe1-filter-element>
      <oe1-filter-element label="Koko Match" param="tag">
        <input type="text" v-model="params.koko_match" v-on:change="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Typ" param="type">
        <select class="form-control" v-model="params.type" v-on:change="updateParams">
          <option></option>
          <option v-for="o in  filterTypes" :value="o.value">{{o.label}}</option>
        </select>
      </oe1-filter-element>

    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'tag',
      columns: [
        { label: "Tag" },
        { label: "Interner Name" },
        { label: "Koko Match" },
        { label: "URL" },
        { label: "Status" },
        { label: "Type" },
        { label: "", width: "5%" }
      ],
      filterTypes: [
        { label: "internal", value:"internal" },
        { label: "Link", value:"link" },
        { label: "Display", value:"display" },
        { label: "Programm-SP", value:"programm-sp" },
      ],
    };
  },
  computed: {
    statusOptions: function() {
      return this.$store.state.config.simple_status_options;
    }
  },
};
</script>

