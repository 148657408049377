<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <h3 class="panel-title">Weiterführende Inhalte</h3>
    </div>
    <div class="panel-body">
      <div class="form-inline">
        <oe1-autocomplete :datasource="'/api/search/autocomplete?q='" v-on:selected="addRelatedContent" placeholder="Related Content hinzufügen"></oe1-autocomplete>
      </div>
    </div>

    <div v-if="items.length">
      <draggable
        class="list-group"
        :list="items"
        :options="{handle:'.draggable-handle'}"
        element="ul"
        v-on:update="newOrder"
      >
        <li class="list-group-item" v-for="row in items">
        <div class="row">
            <div class="col-md-7">{{ row.content_object.table }}:{{ row.content_object.id }} - {{ row.content_object.title }}
            </div>
            <div class="col-md-5 text-right">
              <button class="btn btn-default btn-xs" v-on:click="goTo(row)"><span class="glyphicon glyphicon-arrow-right" aria-hidden="true"></span></button>
              <oe1-confirm-modal :callback="removeRelatedContentBuilder(row)"></oe1-confirm-modal>
              <span class="draggable-handle btn btn-default btn-xs" v-if="!locked">
                <span class="glyphicon glyphicon-sort" aria-hidden="true"></span>
              </span>
            </div>
          </div>
        </li>
      </draggable>
    </div>
    <div v-else class="panel-footer">
      Kein weiterführenden Inhalte vorhanden.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "oe1-relatedcontent",
  data: function () { return {
    items: [],
  }; },
  props: ['content_class','content_id'],
  components: {
    'draggable': require('vuedraggable'),
  },
  computed: mapGetters(['locked']),
  created: function () {
    var vm = this;
    vm.axios.get('/api/related_content/src/' + vm.content_class + '/' + vm.content_id)
      .then(function (response) {
        vm.items = response.data;
      })
      .catch(function (error) {
        vm.$log(error);
      });
  },
  methods: {
    removeRelatedContentBuilder: function(item) {
        return this.removeRelatedContent.bind(this,item);
    },
    removeRelatedContent: function (rc) {
      var vm = this;
      vm.axios.delete('/api/related_content/src/' + vm.content_class + '/' + vm.content_id + '/to/' + rc.content_object.table +'/'+ rc.content_object.id)
        .then(function (response) {
          vm.items = response.data;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    addRelatedContent: function(hit) {
      var vm = this;
      vm.axios.post('/api/related_content/src/'+ vm.content_class + '/' + vm.content_id, { content_id: hit.id, content_class: hit.table  } )
        .then(function (response) {
          vm.items = response.data;
          vm.$log('Weiterführender Inhalt "' + hit.title + '" wurde hinzugefügt.');
          vm.searchResult = [];
          vm.searchQuery='';
        })
        .catch(function (error) {
          vm.$log(error);
          vm.searchResult = [];
          vm.searchQuery='';
        });
    },
    newOrder: function() {
      var vm = this;
      vm.axios.put('/api/related_content/src/'+ vm.content_class + '/' + vm.content_id, vm.items)
        .then(function (response) {
          vm.$log('Weiterführende Inhalte wurden umsortiert.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    goTo: function(item) {
      var vm = this;
      vm.$router.push('/'+ item.content_object.table + '/' +item.content_object.id + '?tab=main');
    },
  }
};
</script>

