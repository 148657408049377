<template>
  <div>
    <div v-if="image.src" class="panel-body">
      <div class="row">
        <div class="col-md-8">
          <img :src="image.src"/>
          <div>#{{image.id}} - {{image.filename}} ({{image.width}}/{{image.height}})</div>
        </div>
        <div class="col-md-4">
          Formate:
          <ul>
            <li v-for="format in image.formats">
              <a :href="format.url" target="_new">{{ format.name }}</a>
            </li>
          </ul>
        </div>
      </div>

      <form v-on:submit.prevent>
        <div class="form-group">
          <label for="alt">ALT-Text</label>
          <input v-model="image.alt" type="text" class="form-control" id="alt" placeholder="ALT-Text" required autofocus/>
        </div>
        <div class="form-group">
          <label for="copyright">Copyright</label>
          <input v-model="image.copyright" type="text" class="form-control" id="copyright" placeholder="Copyright" required/>
        </div>
        <div class="form-group">
          <label for="text">Schlagwörter</label>
          <textarea class="form-control" rows="2" id="keywords" v-model="image.keywords"></textarea>
        </div>
        <div class="form-group">
          <label for="text">Text</label>
          <textarea class="form-control" rows="5" id="Text" v-model="image.text"></textarea>
        </div>

        <div class="form-group">
          <button v-if="show_usage === false" class="btn btn-default" @click='showUsage(image)'>Verwendung anzeigen</button>
          <div v-else>
            <label for="text">Verwendung</label>
            <ul>
              <li v-for="(thing,index) in usage">
              {{ thing.type }}: {{ thing.title }} <code>ID: {{thing.id}}</code>
              <a :href="thing.editlink" type="button" class="btn btn-default btn-xs" target="_new" title="bearbeite Ziel in neuem Fenster."><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>
              <a :href="thing.permalink" type="button" class="btn btn-default btn-xs" target="_new" title="zeige Ziel in neuem Fenster."><span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a>
              </li>
              <li><button class="btn btn-default" @click='showUsage(image)'>Reload</button></li>
            </ul>
          </div>
        </div>

        <oe1-save-close :content_id="image.id" :submit_method="submit_image"></oe1-save-close>

        <oe1-confirm-modal body="Willst du dieses Bild wirklich unwiederbringlich löschen und aus allen Artiklen etc entfernen? Das Löschen kann ca 1 Minute dauern!" buttonText="Löschen" buttonStyle="btn btn-danger" :callback="deleteImageBuilder(image)"></oe1-confirm-modal>

      </form>
    </div>
    <div v-else>
      <div class="form-group">
        <label for="inputFile">Datei</label>
        <input type="file" id="inputFile" v-on:change="onFileSelected">
      </div>
      <button id="list-image-upload" class="btn btn-primary"  v-on:click="upload_image" :disabled="!file_selected">Bild speichern</button>
    </div>

  </div>
</template>

<script>
export default {
  data: function () { return {
      file_selected: false,
      file: '',
      filename: '',
      show_usage: false,
      usage: []
    };
  },
  props: ['image', 'content_class'],
  methods: {
    'submit_image': function () {
      var vm = this;
      var saveUrl = vm.image.id
                    ? '/api/image/' + vm.image.id
                    : '/api/image/';
      var promise = vm.axios.post(saveUrl, vm.image);
      promise
        .then(function (response) {
          vm.$log('Foto "'+vm.image.filename +'" wurde gespeichert.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    closeEvent: function() {
      var vm = this;
      vm.$router.push('/image/list' );
    },
    upload_image: function() {
        var vm = this;
        var data = {
            file: vm.file,
            filename: vm.filename
        };
        vm.axios.post('/api/image', data  )
        .then(function (response) {
            vm.$log('Bild wurde angelegt!');
            vm.$router.push('/image/' + response.data.id);
        })
        .catch(function (error) {
            vm.$log(error);
        });
    },
    onFileSelected: function(e) {
        var vm = this;
        vm.inputFile = '';
        var files = e.target.files;
        if (!files.length) { return; }
        var file = files[0];
        var image = new Image();
        var reader = new FileReader();
        reader.onload = function(event) {
            vm.file = event.target.result;
            vm.filename = file.name;
        };
        reader.readAsDataURL(file);
        vm.file_selected = true;
    },
    deleteImageBuilder: function(item) {
        return this.deleteImage.bind(this,item);
    },
    deleteImage: function(item) {
      var vm = this;
      vm.axios.delete('/api/image/' + item.id)
        .then(function (response) {
          vm.$router.replace({ name: 'image.list' });
          vm.$log('Das Bild wurde gelöscht: ' + response.data.deleted + " mal\n" + response.data.report.join(" \n "));
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    showUsage: function(item) {
      var vm = this;
      vm.show_usage = true;

      vm.axios.get('/api/image-usage/' + item.id)
        .then(function (response) {
          vm.usage = response.data.hits;
        })
        .catch(function (error) {
          vm.$log(error);
        });

    },
  }
};
</script>
