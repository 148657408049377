<template>
  <form v-on:submit.prevent>
    <div class="form-group">
      <label for="inputFirstname">Vorname</label>
      <input v-model="author.firstname" type="text" class="form-control" id="inputFirstname" placeholder="Vorname" required autofocus>
    </div>
    <div class="form-group">
      <label for="inputLastname">Nachname</label>
      <input v-model="author.lastname" type="text" class="form-control" id="inputLastname" placeholder="Nachname" required autofocus v-on:blur="fill_koko_name()">
    </div>
    <div class="form-group">
      <label for="inputEmail">e-Mail</label>
      <input v-model="author.email" type="email" class="form-control" id="inputEmail" placeholder="e-Mail">
    </div>
    <div class="form-group">
      <label for="inputUrl">Team-URL (ohne <code>/team</code>)</label>
      <input v-model="author.url" type="text" class="form-control" id="inputUrl" placeholder="URL">
    </div>
    <div class="form-group">
      <div class="checkbox">
        <label>
          <input type="checkbox" v-model="author.show_in_teamlist" /> in Team-Liste anzeigen</label>
      </div>
    </div>

    <div class="form-group">
      <label for="inputStatus">Status</label>
      <select class="form-control" id="inputStatus" required v-model="author.status">
        <option v-for="o in $store.state.config.content_status_options" :value="o.value">{{ o.label }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="inputTeaser">Teaser</label>
      <textarea v-model="author.teaser" rows="5" class="form-control" id="inputTeaser" placeholder="Teaser"></textarea>
    </div>
    <div class="form-group">
      <label for="inputKokoName">Koko-Name</label>
      <input v-model="author.koko_name" type="text" class="form-control" id="inputKokoName" placeholder="Koko-Name" required>
    </div>
    <oe1-save-close :content_id="author.id" :submit_method="submit_author"></oe1-save-close>
  </form>
</template>

<script>
export default {
  props: [ 'author' ],
  methods: {
    'submit_author': function () {
      var vm = this;
      var save_url = vm.author.id
          ? '/api/author/' + vm.author.id
          : '/api/author';

      var promise = vm.axios.post(save_url, vm.author);
      promise
        .then(function (response) {
          vm.$log('Autor wurde gespeichert.');
          vm.author = response.data;
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    'fill_koko_name': function() {
      var vm = this;
      if (!vm.author.koko_name) {
        vm.author.koko_name = vm.author.firstname + ' ' + vm.author.lastname;
      }
      if (!vm.author.url) {
        let url = vm.author.firstname + ' ' + vm.author.lastname;
        vm.author.url = url.replace(/ /g,"_").toLowerCase();
      }
    }
  }
};
</script>
