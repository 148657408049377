<template>
  <div>
    <div class="col-md-9" role="main">
      <ul class="nav nav-tabs" role="tablist">

        <li :class="{active: tab === 'tagdetails'}">
          <router-link :to="{path:'/tag/'+tag.id}">Tag</router-link>
        </li>
        <li v-if="tag.id" :class="{active: tab === 'listimage'}">
          <router-link :to="{path:'/tag/'+tag.id+'/listimage'}">Listenbild</router-link>
        </li>
        <li v-if="tag.id" :class="{active: tab === 'more'}">
          <router-link :to="{path:'/tag/'+tag.id+'/more'}">Tags, Events</router-link>
        </li>
      </ul>
      <component :is="currentView" :tag="tag" :content_class="content_class" :content_id="tag_id"></component>
    </div>
    <div v-if="tag.id" class="col-md-3 sidebar" role="complementary">
      <oe1-memo :content_class="content_class" :content_id="tag.id"></oe1-memo>
      <oe1-changelog :content_class="content_class" :content_id="tag.id"></oe1-changelog>
    </div>
  </div>
</template>

<script>

export default {
  data: function () { return {
      tag: {
        status:'draft',
        tag:'',
      },
      content_class: 'tag',
    };
  },
  computed: {
    tab: function() { return this.$route.params.tab || 'tagdetails' },
    currentView: function() { return 'oe1-'+this.tab },
    tag_id: function() { return parseInt(this.$route.params.tag_id) }
  },
  watch: {
    'tag_id': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  components: {
    'oe1-tagdetails': require('./TagDetails.vue').default,
    'oe1-more': {
        props: ['tag', 'content_class', 'content_id'],
        template: '<div>'
                + '  <oe1-tags :content_id="content_id" :content_class="content_class"></oe1-tags>'
                + '  <oe1-events :content_id="content_id" :content_class="content_class"></oe1-events>'
                + '</div>',
    }
  },
  methods: {
    fetchData: function() {
      var vm = this;
      if (vm.tag_id) {
        vm.axios.get('/api/tag/' + vm.tag_id)
          .then(function (response) {
            vm.tag = response.data;
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
    }
  }
};
</script>
