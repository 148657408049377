<template>
  <div>
    <h3 v-if="url.id">URL bearbeiten</h3>
    <h3 v-else>URL anlegen</h3>


    <form v-on:submit.prevent>
      <div class="form-group">
        <label for="url">URL</label>
        <input v-model="url.url" type="text" class="form-control" id="url" placeholder="/" required autofocus>
      </div>

      <b>verweist auf:</b><br/>
      <div v-if="url.content_object.id" class="form-group">
        <span>
          <router-link :to="{ path: '/' + url.content_class + '/' + url.content_id }">{{ url.content_object.title }} ({{ url.content_class }}) - {{ url.content_id }}</router-link>
        </span>
        <oe1-confirm-modal :callback="removeCO" body="Content Object entfernen?"></oe1-confirm-modal>
      </div>
      <div v-else>
        <div class="form-group">
          <oe1-autocomplete :datasource="'/api/search/autocomplete?q='" v-on:selected="addContentObject" placeholder="Ziel auswählen"></oe1-autocomplete>
        </div>
      </div>

      <b>Kanonischer Link für:</b></br>
      <div v-if="url.canonical_content_id">
        <span>
          <router-link :to="{ path: '/' + url.canonical_content_class + '/' + url.canonical_content_id }">{{ url.canonical_content_object.title }} ({{ url.canonical_content_class }}) - {{ url.canonical_content_id }}</router-link>
        </span>
        <oe1-confirm-modal :callback="removeKCO" body="Kanonisches Content Object entfernen?"></oe1-confirm-modal>
      </div>
      <div v-else>
        <div class="form-group">
          <oe1-autocomplete :datasource="'/api/search/autocomplete?q='" v-on:selected="addCanonicalObject" placeholder="Objekt auswählen"></oe1-autocomplete>
        </div>
      </div>

      <oe1-save-close :content_id="url.id" :submit_method="submit_url"></oe1-save-close>
    </form>

    <div v-if="url.id">
        <oe1-memo :content_class="content_class" :content_id="url.id"></oe1-memo>
    </div>
  </div>
</template>


<script>

export default {
  data: function () { return {
    url: {
      id: '',
      url: '/',
      is_canonical: '',
      content_class: '',
      content_id: '',
      content_object: {
        id: '',
        title:''
      },
      canonical_content_class: '',
      canonical_content_id: '',
      canonical_content_object: {
        id: '',
        title: ''
      },
    },
    content_class: 'url',
    };
  },
  watch: {
    '$route': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  methods: {
    fetchData: function () {
      var vm = this;
      if (vm.$route.params.url_id) {
        vm.axios.get('/api/url/' + vm.$route.params.url_id)
          .then(function (response) {
            vm.url = response.data;
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
    },
    addContentObject: function(hit) {
      var vm = this;
      vm.url.content_class = hit.table;
      vm.url.content_id = hit.id;
      vm.url.content_object.id = hit.id;
      vm.url.content_object.title = hit.title;
    },
    addCanonicalObject: function(hit) {
      var vm = this;
      vm.url.canonical_content_class = hit.table;
      vm.url.canonical_content_id = hit.id;
      vm.url.canonical_content_object.id = hit.id;
      vm.url.canonical_content_object.title = hit.title;
    },
    'submit_url': function () {
      var vm = this;
      var saveUrl = vm.url.id
                    ? '/api/url/' + vm.url.id
                    : '/api/url/';
      var promise = vm.axios.post(saveUrl, vm.url);
      promise
        .then(function (response) {
          vm.$log('URL wurde gespeichert');
        })
        .catch(function (error) {
          vm.$log(error);
        });
      return promise;
    },
    deleteBuilder: function() {
      return this.removeCO.bind(this);
    },
    removeCO: function() {
      var vm = this;
      vm.url.content_class=undefined;
      vm.url.content_id=undefined;
      vm.url.content_object={};
    },
    deleteBuilderKCO: function() {
      return this.removeKCO.bind(this);
    },
    removeKCO: function() {
      var vm = this;
      vm.url.canonical_content_class=undefined;
      vm.url.canonical_content_id=undefined;
      vm.url.canonical_content_object={};
    },
  },
};
</script>
