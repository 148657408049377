module.exports = [
    { value: 'ondemand',    label: 'On Demand abrufbar (heute, gestern, ..)' },
    { value: 'ondemand_reverse',    label: 'On Demand abrufbar, gestürzt (vor 30 Tagen, .., gestern, heute)' },
    { value: 'upcoming',    label: 'zukünftige (heute, morgen, ..)' },
    { value: 'upcoming_reverse',    label: 'zukünftige gestürzt (in 30 Tagen, .., morgen, heute)' },
    { value: 'past',    label: 'vergangen (heute, gestern, .., vor 30 Tagen)' },
    { value: 'past_reverse',    label: 'vergangen gestürzt (vor 30 Tagen, .., gestern, heute)' },
    { value: 'future',    label: 'alle [für broadcastpaged] (in 30 Tagen, .., morgen, heute, gestern, .., vor 30 Tagen)' },
    { value: 'future_reverse',    label: 'alle gestürzt [für broadcastpaged] (vor 30 Tagen, .., gestern, heute, morgen, .., in 30 Tagen)' },
];
