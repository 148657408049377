<template>
  <div>
    <label for="inputAudioUrl">Audio URL</label>
    <div class="input-group">
      <span class="input-group-btn"><button type="button" class="btn btn-primary" v-on:click="audioBrowserOpen()">Audiobrowser</button></span>

      <input v-if="!audio_url" type="text" class="form-control" id="inputAudioUrl" v-model="new_url" @change="audioBrowserUseManual()" required />
      <input v-else type="text" class="form-control" disabled v-model="audio_url" />
      <span  v-if="audio_url" class="input-group-btn">
        <button v-if="audio_url" type="button" class="btn btn-danger" @click="audioBrowserDeleteUrl()" >
            <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
        </button>
      </span>
    </div>
    <div v-if="audio_info" style="padding-top:5px">{{ audio_info }}</div>

    <div role="dialog" class="modal fade in" v-if="audiobrowser.display" style="display:block">
      <div role="document" class="modal-dialog">
        <div class="modal-content" style="max-height: calc(100vh - 50px); ;overflow:auto">
          <div class="modal-header">
            <button type="button" class="close" v-on:click="audioBrowserClose()">
              <span>×</span>
            </button>
            <h4 class="modal-title">Audiobrowser</h4>
          </div>
          <div class="modal-body">
            <slot name="body">
              <form v-on:submit.prevent v-on:keyup.enter="audioBrowserSearch()" class="form-inline" style="padding-bottom:1em">
                <div class="form-group">
                  <select class="form-control" id="abFolder" v-model="audiobrowser.folder">
                    <option v-for="o in audiobrowser_folder" :value="o.value">{{ o.label }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" id="abQuery" v-model="audiobrowser.query" />
                </div>
                <div class="form-group">
                  <button type="button" class="btn btn-primary" v-on:click="audioBrowserSearch()">suchen</button>
                  <button type="button" class="btn btn-default" v-on:click="audioBrowserSync()">
                    <span class="glyphicon glyphicon-refresh"></span>
                  </button>
                </div>
              </form>
              <div v-if="audiobrowser.message">{{ audiobrowser.message }}</div>
              <ul>
                <li v-for="a in audiobrowser.result" style="padding-bottom:1em">
                  {{ a.label }}<br>
                  <button type="button" class="btn btn-primary btn-xs " v-on:click="audioBrowserChoose(a)">übernehmen</button>
                </li>
              </ul>
            </slot>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" v-on:click="audioBrowserClose()">abbrechen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'oe1-audiobrowser',
  props: ['audio_url','audio_info'],
  data: function () { return {
    new_url: undefined,
    audiobrowser: {
      display: false,
      folder: '',
      query: '',
      result: [],
      message: '',
    },
    audiobrowser_folder: [
      {"label":"Folder","value":""},
      {"label":"clips","value":"clips"},
      {"label":"internat","value":"internat"},
      {"label":"7tage","value":"7tage"},
      {"label":"science","value":"science"},
    ]
  } },
  methods: {
    audioBrowserOpen() {
      this.audioBrowserSearch();
      this.audiobrowser.display = true;
    },
    audioBrowserClose() {
      this.audiobrowser.display = false;
      this.audiobrowser.query = '';
      this.audiobrowser.folder = '';
      this.audiobrowser.result = [];
    },
    audioBrowserSearch() {
      var vm = this;
      vm.audiobrowser.message="";
      vm.axios.get('/api/audiobrowser', { params: { query: vm.audiobrowser.query, folder: vm.audiobrowser.folder } } )
        .then(function (response) {
          vm.audiobrowser.result = response.data.audios;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    audioBrowserSync() {
      var vm = this;
      this.audiobrowser.result = [];
      this.audiobrowser.message  = "Neue Audios werden von apasfftp1.sf.apa.at geladen. Bitte warten...";
      vm.axios.get('/api/sync-audiobrowser' )
        .then(function (response) {
          vm.audiobrowser.message = "Audio-Sync erfolgreich, " + response.data.synced + " neue Audios";
        })
        .catch(function (error) {
          vm.audiobrowser.message = error;
        });
    },
    audioBrowserChoose(audio) {
      this.$emit('oe1-audio-selected', audio);
      this.audioBrowserClose();
    },
    audioBrowserUseManual() {
      this.$emit('oe1-audio-selected', { value: this.new_url, label: "Externe URL" });
    },
    audioBrowserDeleteUrl() {
      this.$emit('oe1-audio-selected', { value: null, label: null });
    }
  }
};
</script>
