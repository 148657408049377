<template>
  <div>
    <h3>{{ areas[area].name }}</h3>
    <draggable
        class="list-group"
        :list="widgets"
        :options="{handle:'.draggable-handle'}"
        element="ul"
        v-on:update="sortWidgets"
    >
      <li class="list-group-item" :class="widget.status" v-for="(widget,index) in widgets">
        <div class="row">
          <div class="col-md-7">
            <h4>{{ widget.render_as }} <span v-if="widget.title">"{{ widget.title }}"</span> -  {{ widget.status }}
              <span v-if="widget.show_more">+ Mehr anzeigen</span>
            </h4>
            <span v-if="widget.tracking_pixel">Tracking Pixel: <code>{{widget.tracking_pixel}}</code></span>
          </div>
          <div class="col-md-5 text-right">
            <button type="button" class="btn btn-default btn-xs" v-on:click="editWidget(widget)" :disabled="locked">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
            <oe1-confirm-modal :callback="deleteWidgetBuilder(index)"></oe1-confirm-modal>
            <span class="draggable-handle btn btn-default btn-xs" v-if="!locked">
              <span class="glyphicon glyphicon-sort" aria-hidden="true"></span>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <oe1-selections :selections="widget.selections" :only_one_selection="widget.only_one_selection" :available_selections="widget.available_selections"  :widget_id="widget.id" :collection_id="collection.id"/>
          </div>
        </div>
      </li>
    </draggable>

    <div v-if="area === 'left'">
      <label>
        <input type="checkbox" v-model="collection.show_more" v-on:change="toggleShowMore" :disabled="locked"/> Mehr laden
      </label>
    </div>

    <div v-if="allow_add_widgets">
      <div v-if="showAddWidgetForm">
        <form class="form-inline" v-on:submit.prevent="addWidget()">
          <div class="form-group">
            <label for="inputAction">Widget-Typ</label>
            <select class="form-control" id="newWidget.render_as" v-model="newWidget.render_as" required>
              <option v-for="o in areas[area].available_widgets" :value="o">{{ o }}</option>
            </select>
          </div>
          <button type="button" class="btn btn-default" v-on:click="addWidget()" :disabled="locked">Widget hinzufügen</button>
          <button type="button" class="btn btn-default" v-on:click="showAddWidgetForm=false">Abbrechen</button>
        </form>
      </div>
      <div v-else>
        <button type="button" class="btn btn-info" v-on:click="showAddWidgetForm = true" :disabled="locked">Widget hinzufügen</button>
      </div>
    </div>
  </div>
</template>

<script>
import Selections from './CollectionWidgetsSelections.vue';
import { mapGetters } from 'vuex';
export default {
  data: function() { return {
    showAddWidgetForm: false,
    newWidget: {
      render_as: '',
    },
    areas: { // TODO fetch from backend
      left: {
        name: 'Links',
        location: 'left',
        available_widgets: ['carousel','leadimage','livestream','tiles','imagelist','thema','imagelist_with_date','broadcastpaged','broadcastendless','broadcastpaged_classic','searchresultpaged','azlist','clubpartner','slimlist','maplist','ugctiles','ugcsubmissions'],
      },
      right: {
        name: 'Rechts',
        location: 'right',
        available_widgets: ['box_linklist','box_playerlinklist','box_text','box_image','box_text_on_image','box_journalelements','box_livestream','box_audiostream','box_az_nav','box_profile_info','box_kulturkalender','box_login','box_downloadhistory','box_searchbox','box_searchbox_tags','box_stoerer','box_raw_html'],
      },
    }
  } },
  computed: mapGetters(['locked']),
  components: {
    draggable: require('vuedraggable'),
    'oe1-selections': Selections,
  },
  props: ['area','widgets','collection','allow_add_widgets'],
  methods: {
    addWidget: function () {
      var vm = this;
      vm.newWidget.location=vm.areas[vm.area].location;
      vm.axios.post('/api/collection/' + vm.collection.id + '/widget', vm.newWidget)
        .then(function (response) {
          vm.widgets.push(response.data);
          vm.showAddWidgetForm = false;
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    editWidget: function (widget) {
      var vm = this;
      this.$router.push('/widget/'+widget.id);
    },
    deleteWidgetBuilder: function (index) {
      return this.deleteWidget.bind(this,index);
    },
    deleteWidget: function (index) {
      var vm = this;
      var widget = vm.widgets[index];

      vm.axios.delete('/api/widget/' + widget.id )
        .then(function (response) {
          vm.widgets.splice(index, 1);
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    sortWidgets: function () {
      var vm = this;
      var data = {
        location: vm.areas[vm.area].location,
        order: vm.widgets.map( function(el) { return el.id } )
      };
      vm.axios.put('/api/collection/' + vm.collection.id + '/widget', data)
        .then(function (response) {
          vm.$log('Widgets wurden neu geordnet.');
        })
        .catch(function (error) {
          vm.$log(error);
        });
    },
    toggleShowMore: function() {
      var vm = this;
      vm.axios.post('/api/collection/' + vm.collection.id + '/toggle-show-more')
        .then(function (response) {
          vm.collection.show_more = response.data.show_more;
          var show_more = vm.collection.show_more ? 'Ja' : 'Nein';
          vm.$log('Mehr laden: ' + show_more);
        })
        .catch(function (error) {
          vm.$log(error);
        });
    }
  }
};
</script>


<style scoped>

li.list-group-item {
border: 3px solid #999;
margin-bottom:5px;
}

li.draft {
  background-color:#d2b4b4;
}

li.offline {
  background-color:#ccc;
}

h3 {
    margin-top:0px;
}

h4 {
  margin-top:0px;
}

</style>
