<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.id }}<br/>
          <code style="white-space:nowrap">&ltGESTALTER&gt;{{ row.item.koko_name }}&lt/GESTALTER&gt;</code></td>
          <td>{{ $root.getStatusLabel(row.item.status) }}</td>
          <td>{{ row.item.firstname }}</td>
          <td>{{ row.item.lastname }}</td>
          <td>{{ row.item.email }}</td>
          <td>
            <button type="button" class="btn btn-default btn-xs" v-on:click="editAuthor(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
            <oe1-image-modal :image_id="row.item.image"></oe1-image-modal>
          </td>
        </template>
      </oe1-table>
    </div>

    <template slot="actions">
      <router-link to="/author/create" class="btn btn-info">Autor anlegen</router-link>
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>

    <div slot="filter">
      <oe1-filter-element label="Vorname" param="firstname">
        <input type="text" v-model="params.firstname" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Nachname" param="lastname">
        <input type="text" v-model="params.lastname" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="E-Mail" param="email">
        <input type="text" v-model="params.email" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Koko-Name" param="kokoname">
        <input type="text" v-model="params.kokoname" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="Team-URL" param="url">
        <input type="text" v-model="params.url" v-on:change="updateParams" v-on:keyup.enter="updateParams">
      </oe1-filter-element>

      <oe1-filter-element label="In Team-Liste anzeigen" param="url">
        <select v-model="params.show_in_teamlist" v-on:change="updateParams">
          <option value="yes">Ja</option>
          <option value="no">Nein</option>
        </select>
      </oe1-filter-element>

      <oe1-filter-content-object
        :show-status=true
        :show-title=false
        :show-page-id=true
        :show-tag=true
        :show-created-at=false
        :params="params"
        :status-options="statusOptions"
        v-on:oe1-update-params="updateParams"
      ></oe1-filter-content-object>

    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'author',
      columns: [
        { label: "ID",},
        { label: "Status",},
        { label: "Vorname",},
        { label: "Nachname",},
        { label: "E-Mail",},
        { label: "", width: "5%" }
      ],
    };
  },
  calculated: {
    filterContentClasses: function() {
      var vm = this;
      return vm.$store.state.config.content_classes;
    }
  },
  methods: {
    editAuthor: function(item) {
      var vm = this;
      vm.$router.push('/author/' + item.id);
    },
  }
};
</script>

