<template>
  <div>
    <div class="col-md-9" role="main">
      <ul class="nav nav-tabs" role="tablist">
        <li :class="{active: tab === 'authordetails'}">
          <router-link :to="{path:'/author/'+author.id}">Autor</router-link>
        </li>
        <li v-if="author.id" :class="{active: tab === 'listimage'}">
          <router-link :to="{path:'/author/'+author.id+'/listimage'}">Listenbild</router-link>
        </li>
        <li v-if="author.id"  :class="{active: tab === 'paragraphs'}">
          <router-link :to="{path:'/author/'+author.id+'/paragraphs'}">Absätze</router-link>
        </li>
        <li v-if="author.id" :class="{active: tab === 'more'}">
          <router-link :to="{path:'/author/'+author.id+'/more'}">Tags, Events</router-link>
        </li>
      </ul>
      <component :is="currentView" :author="author" :content_class="content_class" :content_id="author_id" :previewlink="author.previewlink"></component>
    </div>
    <div v-if="author.id" class="col-md-3 sidebar" role="complementary">
      <oe1-memo :content_class="content_class" :content_id="author_id"></oe1-memo>
      <oe1-changelog :content_class="content_class" :content_id="author_id"></oe1-changelog>
    </div>


  </div>
</template>

<script>

import ParagraphList from './ParagraphList.vue';

export default {
  data: function () { return {
      content_class: 'author',
      author: {
        id: '',
        firstname: '',
        lastname: '',
        email: '',
        status: 'draft',
        teaser: '',
        koko_name: '',
        keywords: '',
        url:'',
      }
    };
  },
  computed: {
    tab: function() { return this.$route.params.tab || 'authordetails' },
    currentView: function() { return 'oe1-'+this.tab },
    author_id: function() { return parseInt(this.$route.params.author_id) }
  },
  watch: {
    'author_id': 'fetchData'
  },
  created: function () {
    this.fetchData()
  },
  components: {
    'oe1-authordetails': require('./AuthorDetails.vue').default,
    'oe1-paragraphs': ParagraphList,
    'oe1-more': {
        props: ['author', 'content_class', 'content_id', 'previewlink'],
        template: '<div>'
                + '  <oe1-tags :content_id="content_id" :content_class="content_class"></oe1-tags>'
                + '  <oe1-events :content_id="content_id" :content_class="content_class" :previewlink="previewlink"></oe1-events>'
                + '</div>',
    }
  },
  methods: {
    fetchData: function () {
      var vm = this;
      if (vm.author_id) {
        vm.axios.get('/api/author/' + vm.author_id)
          .then(function (response) {
            vm.author = response.data;
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
    },
  }
};
</script>
