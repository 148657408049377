<template>
  <oe1-list-page>
    <div slot="main">
      <oe1-pager
        :current_pager="pager"
        v-on:oe1-update-params="updateParams"
      ></oe1-pager>
      <oe1-table
        :columns="columns"
        :items="items">
        <template slot-scope="row">
          <td>{{ row.item.id }}</td>
          <td class="cliptext">{{ row.item.title }} - {{ row.item.nicetitle }}</td>
          <td>{{ $root.getStatusLabel(row.item.status) }}</td>
          <td class="nowrap">{{ row.item.airdate }}</td>
          <td class="cliptext">{{ row.item.serial_title }}</td>
          <td>{{ $root.getListLabel('ressorts',row.item.ressort) }}</td>
          <td>
            <button type="button" class="btn btn-default btn-xs" v-on:click="editItem(row.item)">
              <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
            </button>
            <a :href="row.item.previewlink" type="button" class="btn btn-default btn-xs" target="_new" title="zeige Broadcast in neuem Fenster."><span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a>

            <oe1-image-modal :image_id="row.item.image"></oe1-image-modal>

          </td>
        </template>
      </oe1-table>
    </div>

    <template slot="actions">
      <button class="btn" @click="resetParams()"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>
    </template>


    <div slot="filter">
      <oe1-filter-content-object
        v-on:oe1-update-params="updateParams"
        :show-airdate=true
        :params="params"
        :status-options="statusOptions"
      ></oe1-filter-content-object>

      <oe1-filter-element label="Ressort" param="ressort">
        <select type="text" v-model="params.ressort" v-on:change="updateParams">
          <option value=""></option>
          <option v-for="o in $store.state.config.ressorts" :value="o.value">{{ o.label }}</option>
        </select>
      </oe1-filter-element>

    </div>
  </oe1-list-page>
</template>

<script>
import list from './mixins/List.js';

export default {
  mixins: [ list ],
  data: function () {
    return {
      apiName: 'broadcast',
      columns: [
        { label: "ID", width:"8%" },
        { label: "Titel" },
        { label: "Status", width:"10%" },
        { label: "Airdate", width:"15%" },
        { label: "Sendung", width:"13%" },
        { label: "Ressort", width:"10%" },
        { label: "", width: "5%" }
      ],
      params: { status: 'all' }
    };
  },
};
</script>

<style scoped>
td.cliptext {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}
</style>

