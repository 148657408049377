<template>
  <div>
    <div class="col-md-9" role="main">
      <ul class="nav nav-tabs" role="tablist">
        <li :class="{active: tab === 'serialdetails'}">
          <router-link :to="{path:'/serial/'+serial_id}">Sendereihe</router-link>
        </li>
        <li v-if="serial.id" :class="{active: tab === 'listimage'}">
          <router-link :to="{path:'/serial/'+serial_id+'/listimage'}">Listenbild</router-link>
        </li>
        <li v-if="serial.id" :class="{active: tab === 'more'}">
          <router-link :to="{path:'/serial/'+serial.id+'/more'}">Tags, Events</router-link>
        </li>
      </ul>
      <component :is="currentView" :serial="serial" content_class="serial" :content_id="serial_id"></component>
    </div>
    <div v-if="serial.id" class="col-md-3 sidebar" role="complementary">
      <oe1-previewlink :link="serial.previewlink"></oe1-previewlink>
      <oe1-memo :content_class="content_class" :content_id="serial_id"></oe1-memo>
      <oe1-changelog :content_class="content_class" :content_id="serial_id"></oe1-changelog>
    </div>
  </div>
</template>

<script>

export default {
  data: function () { return {
      content_class: 'serial',
      serial: {
        id: '',
        status: 'draft',
        title: '',
        teaser: '',
        sort_date: '',
        ressort:'',
        sendungsid:'',
        code:'',
        id3tag:'',
        is_downloadable: false,
        has_download_optin: false,
        has_playlist: false,
        is_on_demand: false,
        is_journal: false,
      }
    };
  },
  computed: {
    tab: function() { return this.$route.params.tab || 'serialdetails' },
    currentView: function() { return 'oe1-'+this.tab },
    serial_id: function() { return parseInt(this.$route.params.serial_id) }
  },
  watch: {
    'serial_id': 'fetchData'
  },
  created: function () {
    this.fetchData();
  },
  components: {
    'oe1-serialdetails': require('./SerialDetails.vue').default,
    'oe1-more': {
        props: ['serial', 'content_class', 'content_id', 'previewlink'],
        template: '<div>'
                + '  <oe1-tags :content_id="content_id" :content_class="content_class"></oe1-tags>'
                + '  <oe1-events :content_id="content_id" :content_class="content_class" :previewlink="previewlink"></oe1-events>'
                + '</div>',
    }
  },
  methods: {
    fetchData: function() {
      var vm = this;
      if (vm.serial_id) {
        vm.axios.get('/api/serial/' + vm.serial_id)
          .then(function (response) {
            vm.serial = response.data
          })
          .catch(function (error) {
            vm.$log(error);
          });
      }
    },
  },
};
</script>
